import * as Types from '../types/SubscriptionCustomer';

export const subscriptionCustomerListAction = (filters) => ({type: Types.SUBSCRIPTION_CUSTOMER_LIST_ACTION, filters});
export const subscriptionCustomerListSuccess = (subscriptionCustomers) => ({
    type: Types.SUBSCRIPTION_CUSTOMER_LIST_SUCCESS,
    subscriptionCustomers
});

export const subscriptionCustomerCreateAction = (params) => ({type: Types.SUBSCRIPTION_CUSTOMER_CREATE_ACTION, params});
export const subscriptionCustomerCreateSuccess = (createCustomer) => ({
    type: Types.SUBSCRIPTION_CUSTOMER_CREATE_SUCCESS,
    createCustomer
});

export const subscriptionCustomerMasterStateAction = (params) => ({type: Types.SUBSCRIPTION_CUSTOMER_MASTER_STATE_ACTION, params});
export const subscriptionCustomerMasterStateSuccess = (masterState) => ({
    type: Types.SUBSCRIPTION_CUSTOMER_MASTER_STATE_SUCCESS,
    masterState
});

export const subscriptionCustomerUpdateAction = (params) => ({type: Types.SUBSCRIPTION_CUSTOMER_UPDATE_ACTION, params});
export const subscriptionCustomerUpdateSuccess = (updateCustomer) => ({
    type: Types.SUBSCRIPTION_CUSTOMER_UPDATE_SUCCESS,
    updateCustomer
});

export const subscriptionCustomerDeleteAction = (params) => ({type: Types.SUBSCRIPTION_CUSTOMER_DELETE_ACTION, params});
export const subscriptionCustomerDeleteSuccess = (deleteCustomer) => ({
    type: Types.SUBSCRIPTION_CUSTOMER_DELETE_SUCCESS,
    deleteCustomer
});

export const subscriptionCustomerMasterDataAction = (params) => ({
    type: Types.SUBSCRIPTION_CUSTOMER_MASTER_ACTION,
    params
});
export const subscriptionCustomerMasterDataSuccess = (masterData) => ({
    type: Types.SUBSCRIPTION_CUSTOMER_MASTER_SUCCESS,
    masterData
});

export const subscriptionCustomerGetItemAction = (params) => ({
    type: Types.SUBSCRIPTION_CUSTOMER_GET_TEM_ACTION,
    params
});
export const subscriptionCustomerGetItemSuccess = (item) => ({type: Types.SUBSCRIPTION_CUSTOMER_GET_TEM_SUCCESS, item});

export const subscriptionCustomerInit = (refreshToken) => ({type: Types.SUBSCRIPTION_CUSTOMER_INIT, refreshToken});
export const subscriptionCustomerFail = (refreshToken) => ({type: Types.SUBSCRIPTION_CUSTOMER_FAILED, refreshToken});
