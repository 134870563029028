import * as Types from '../types/Auth';

export default (state = {logged: !!(localStorage.getItem('hn_utk')), user: {}} , action) => {
    switch (action.type) {
        case Types.UPDATE_PROFILE_ACTION:
            return {...state, isFetching: true};
        case Types.UPDATE_PROFILE_SUCCESS:
            return {...state, isFetching: false, data: action.updateProfile};
        case Types.LOGIN_ACTION:
            return {logged: false,logging: true,action: "LOGIN"};
            // return {logged: false,logging: true,action: "LOGIN"};
        case Types.LOGOUT_ACTION:
            return {logged: true};
        case Types.CHECK_USER_ACTION:
            return {logged: true,logging: true};
        case Types.CHECK_USER_SUCCESS:
            return {...state, logged: true, user: action.user, logging: false};
        case Types.LOGIN_SUCCESS:
            return {...state, logged: true, logging: false, action: "SUCCESS"};
        case Types.LOGIN_FAILED:
            return {logged: false,logging: false,action: "FAIL", isFetching: false};
        case Types.LOGOUT_SUCCESS:
            return {logged: false};
        default:
            return state;
    }
}
