import * as Types from '../types/SubscriptionGeoData';

export const subscriptionGeoDataListAction = (filters) => ({
    type: Types.SUBSCRIPTION_GEO_DATA_LIST_ACTION,
    filters
});
export const subscriptionGeoDataListSuccess = (subscriptionGeoData) => ({
    type: Types.SUBSCRIPTION_GEO_DATA_LIST_SUCCESS,
    subscriptionGeoData
});

// export const subscriptionGeoDataCreateAction = (params) => ({type: Types.SUBSCRIPTION_GEO_DATA_CREATE_ACTION, params});
// export const subscriptionGeoDataCreateSuccess = (createGeoData) => ({
//     type: Types.SUBSCRIPTION_GEO_DATA_CREATE_SUCCESS,
//     createGeoData
// });

export const subscriptionGeoDataUpdateAction = (params) => ({
    type: Types.SUBSCRIPTION_GEO_DATA_UPDATE_ACTION,
    params
});
export const subscriptionGeoDataUpdateSuccess = (updateGeoData) => ({
    type: Types.SUBSCRIPTION_GEO_DATA_UPDATE_SUCCESS,
    updateGeoData
});

export const subscriptionGeoDataMasterDataAction = (params) => ({
    type: Types.SUBSCRIPTION_GEO_DATA_MASTER_ACTION,
    params
});
export const subscriptionGeoDataMasterDataSuccess = (masterData) => ({
    type: Types.SUBSCRIPTION_GEO_DATA_MASTER_SUCCESS,
    masterData
});

export const subscriptionGeoDataGetItemAction = (params) => ({
    type: Types.SUBSCRIPTION_GEO_DATA_GET_TEM_ACTION,
    params
});
export const subscriptionGeoDataGetItemSuccess = (item) => ({
    type: Types.SUBSCRIPTION_GEO_DATA_GET_TEM_SUCCESS,
    item
});

export const subscriptionGeoDataInit = (refreshToken) => ({
    type: Types.SUBSCRIPTION_GEO_DATA_INIT,
    refreshToken
});
export const subscriptionGeoDataFail = (refreshToken) => ({
    type: Types.SUBSCRIPTION_GEO_DATA_FAILED,
    refreshToken
});
