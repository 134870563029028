import React, {useState, useEffect, useRef} from "react";
import {useSelector} from 'react-redux'

// ============== LAYOUT ==============
import {Form, Input, Button, Select, Spin, Descriptions, Badge, Comment, message} from 'antd';
import HeaderAction from "../../../common/layout/HeaderAction";

// ============== ACTION ==============
import {
    ActionMasterData,
    ActionCommentTicket,
    ActionInit,
    ActionGetItem,
    ActionUpdateTicket
} from "../../../../data/hooks/ticket";

// ============== SELECT DATA ==============
import {selectFetching, selectMasterData, selectTicketItem} from "../../../../data/reselects/ticketSelector";
import {common} from "../../../../data/mapping/Request/TicketRequest";
import {Icon} from "../../../common";
import {isEmail} from "../../../../utils/validation";
import PERMISSION from "../../../../constants/permission";
import Avatar from "antd/es/avatar/avatar";
import MessageComments from "./components/MessageComments";

const Index = (props) => {
    const [form] = Form.useForm(),
        [isFetching, setIsFetching] = useState(false),
        [comments, setComments] = useState(""),
        [masterUser, setMasterUser] = useState({}),
        [masterStatus, setMasterStatus] = useState({}),
        [masterType, setMasterType] = useState({}),
        [isUpdate, setIsUpdate] = useState(false),
        // ============== ACTION ==============
        getMasterData = ActionMasterData(),
        // getCreateUser = ActionCreateUser(),
        getUpdate = ActionUpdateTicket(),
        getInitAction = ActionInit(),
        getItem = ActionGetItem(),
        actionCommentTicket = ActionCommentTicket(),
        // ============== SELECT DATA ==============
        itemFetching = useSelector(selectFetching()),
        itemDetail = useSelector(selectTicketItem()),
        itemMasterData = useSelector(selectMasterData());

    useEffect(() => {
        const {match} = props;
        if (Object.keys(match.params).length > 0) {
            setIsUpdate(true);
            if (Object.keys(itemDetail).length < 1) {
                getItem({id: match.params?.id, props: props});
            }
        } else {
            setIsUpdate(false)
        }
    }, [props.match]);

    useEffect(() => {
        if (Object.keys(itemMasterData).length < 1) {
            getMasterData()
        }

        return () => {
            getInitAction();
            setComments("");
        }
    }, []);

    useEffect(() => {
        setIsFetching(itemFetching)
    }, [itemFetching]);

    useEffect(() => {
        if (Object.keys(itemDetail).length > 0) {
            form.setFieldsValue(itemDetail)
        }
    }, [itemDetail]);

    useEffect(() => {
        if (Object.keys(itemMasterData?.user || {}).length > 0) {
            setMasterUser(itemMasterData?.user)
        }
        if (Object.keys(itemMasterData?.status || {}).length > 0) {
            setMasterStatus(itemMasterData?.status)
        }
        if (Object.keys(itemMasterData?.type || {}).length > 0) {
            setMasterType(itemMasterData?.type)
        }
    }, [itemMasterData]);

    const onFinish = (val) => {
        if (isUpdate) {
            getUpdate({...val, props, id: itemDetail?.id})
        } else {
            // getCreateUser({...val, props})
        }
    }

    const handleChange = e => {
        setComments(e.target.value);
    };

    const handleSubmit = e => {
        if (comments) {
            actionCommentTicket({message: comments, id: itemDetail?.id})
            setComments("");
        } else {
            message.error("Message is not empty")
        }
    };

    return (
        <>
            <HeaderAction title="Ticket Detail"/>
            <Spin tip="Loading..." spinning={isFetching}>
                <div className="container bg-white mt-4 p-4">
                    <div className="row">
                        <div className="col-12">
                            <p className="font-weight-bold">Ticket Information</p>
                            <Form
                                form={form}
                                layout="vertical"
                                onFinish={onFinish}
                                // onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <div className="row">
                                    <div className="col-4">
                                        <Form.Item
                                            label="Title"
                                            name={common.ACTION_FIELD.title}
                                            rules={[{required: true}]}
                                        >
                                            <p>{itemDetail[common.ACTION_FIELD.title]}</p>
                                            {/*<Input disabled/>*/}
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Code"
                                            name={common.ACTION_FIELD.code}
                                            rules={[{required: true}]}
                                        >
                                            <Input disabled/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Owner"
                                            name={common.ACTION_FIELD.owner}
                                            rules={[{required: true}]}
                                        >
                                            <Input disabled/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Status"
                                            name={common.ACTION_FIELD.status}
                                            className="mb-0 pb-0"
                                            rules={[{required: true, message: 'Please select status!'}]}
                                        >
                                            <Select
                                                className="w-100"
                                                style={{width: 120}}>
                                                {
                                                    (Object.keys(masterStatus)).length > 0 && (Object.keys(masterStatus)).map((i, k) => {
                                                        return (
                                                            <Select.Option value={parseInt(i)}
                                                                           key={k}>{masterStatus[i]}</Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Assignee"
                                            className="mb-0 pb-0"
                                            name={common.ACTION_FIELD.assignee_id}
                                            rules={[{required: true, message: 'Please select assignee!'}]}
                                        >
                                            <Select
                                                showSearch
                                                showArrow
                                                allowClear
                                                className="w-100"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                style={{width: 120}}>
                                                {
                                                    (Object.keys(masterUser)).length > 0 && (Object.keys(masterUser)).map((i, k) => {
                                                        return (
                                                            <Select.Option value={i}
                                                                           key={k}>{masterUser[i]}</Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Type"
                                            className="mb-0 pb-0"
                                            name={common.ACTION_FIELD.type}
                                            rules={[{required: true, message: 'Please select type!'}]}
                                        >
                                            <Select
                                                showSearch
                                                showArrow
                                                allowClear
                                                className="w-100"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                style={{width: 120}}>
                                                {
                                                    (Object.keys(masterType)).length > 0 && (Object.keys(masterType)).map((i, k) => {
                                                        return (
                                                            <Select.Option value={i}
                                                                           key={k}>{masterType[i]}</Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className="mt-3">
                                        <Form.Item className="text-end">
                                            <Button type="default" htmlType="button" className="me-3"
                                                    onClick={() => props.history.push(PERMISSION.CUSTOMER.TICKET_LIST.route, {group: PERMISSION.CUSTOMER.TICKET_LIST.group})}>
                                                Back
                                            </Button>
                                            <Button type="primary" htmlType="submit">
                                                Save {isFetching && <Icon type="sync-outlined" spin/>}
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        <div className="col-12 mt-3">
                            <p className="font-weight-bold">Ticket Comments</p>
                            <div className="ticket__comments--content">
                                <MessageComments itemDetail={itemDetail}/>
                            </div>
                            <div className="mt-5">
                                <p>Leave Message <span
                                    className="color--description fs--15px">(Maximum 200 characters)</span></p>
                                <Editor
                                    onChange={handleChange}
                                    onSubmit={handleSubmit}
                                    submitting={isFetching}
                                    value={comments}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
        </>
    )
}

const Editor = ({onChange, onSubmit, submitting, value}) => {
    const [inputVal, setInputVal] = useState("");

    useEffect(() => {
        setInputVal(value)
    }, [value]);

    return (
        <>
            <Form.Item className="mb-2">
                <Input.TextArea rows={5} onChange={onChange} value={inputVal} maxLength={200}/>
            </Form.Item>
            <Form.Item className="text-end">
                <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
                    Send
                </Button>
            </Form.Item>
        </>
    )
};

export default Index
