import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";

export default class TicketResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.code = data?.code || "";
        this.title = data?.title || "";
        this.type = data?.type || "";
        this.user_id = data?.user_id || "";
        this.user_first_name = data?.user_first_name || "";
        this.user_last_name = data?.user_last_name || "";
        this.owner = `${data?.user_first_name} ${data?.user_last_name}`;
        this.assignee_id = data?.assignee_id || "";
        this.assignee_first_name = data?.assignee_first_name || "";
        this.assignee_last_name = data?.assignee_last_name || "";
        this.assignee = `${data?.assignee_first_name} ${data?.assignee_last_name}`;
        this.status = data?.status || 0;
        this.status_explain = data?.status_explain || "";
        this.comments = data?.comments || "";
        this.created_at = data?.created_at ? moment(data?.created_at).format(DATE_TIME_FORMAT.DATE_TIME) : "";
        this.updated_at = data?.updated_at ? moment(data?.updated_at).format(DATE_TIME_FORMAT.DATE_TIME) : "";
        this.messages = this.getMessage(data?.messages)
    }

    getMessage(message = []) {
        let newMessage = [];
        if (message.length > 0) {
            message.map(i => newMessage.push({
                user: {
                    id: i?.user?.id || "",
                    first_name: i?.user?.first_name || "",
                    last_name: i?.user?.last_name || "",
                    is_admin: i?.user?.is_admin || 0,
                },
                message: i?.message || "",
                created_at: i?.created_at ? moment(i?.created_at).format(DATE_TIME_FORMAT.DATE_TIME) : "",
            }))
        }
        return newMessage
    }

    exportList() {
        return {
            id: this.id,
            code: this.code,
            title: this.title,
            type: this.type,
            user_id: this.user_id,
            user_first_name: this.user_first_name,
            user_last_name: this.user_last_name,
            assignee_id: this.assignee_id,
            assignee_first_name: this.assignee_first_name,
            assignee_last_name: this.assignee_last_name,
            status: this.status,
            status_explain: this.status_explain,
            comments: this.comments,
            created_at: this.created_at,
            updated_at: this.updated_at,
        }
    }

    exportItem() {
        return {
            id: this.id,
            code: this.code,
            title: this.title,
            type: this.type,
            user_id: this.user_id,
            user_first_name: this.user_first_name,
            user_last_name: this.user_last_name,
            assignee_id: this.assignee_id,
            assignee_first_name: this.assignee_first_name,
            assignee_last_name: this.assignee_last_name,
            status: this.status,
            status_explain: this.status_explain,
            comments: this.comments,
            created_at: this.created_at,
            updated_at: this.updated_at,
            messages: this.messages,
            owner: this.owner,
            assignee: this.assignee,
        }
    }
}

export class MasterTicketResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.user = data?.user || {};
        this.type = data?.type || {};
        this.status = data?.status || {};
    }

    exportMaster() {
        return {
            user: this?.user,
            type: this?.type,
            status: this?.status,
        }
    }
}

export const listCols = ["code", "title", "type", "owner", "assignee", "status_explain", "comments", "created_at", "updated_at"];

export const TICKET = {
    STATUS: ["#1565c0", "#00838f", "#2e7d32", "#757575", "#c62828"]
}
