import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";

export default class SubscriptionGeneralPackageResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || ""
        this.type = data?.type || ""
        this.period_type = data?.period_type || ""
        this.package_type = data?.package_type || ""
        this.price = data?.price || 0
    }

    exportList() {
        return {
            id: this.id,
            type: this.type,
            period_type: this.period_type,
            package_type: this.package_type,
            price: this.price,
        }
    }
}
export const listCols = ["type", "period_type", "package_type", "price"];
export const constants = {
    status: ["Inactive", "Active"]
};
