import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";

export default class SubscriptionCustomerResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.code = data?.code || "";
        this.user_id = data?.user_id || "";
        this.email = data?.email || "";
        this.price = data?.price || 0;
        this.discount = data?.discount || 0;
        this.total_amount = data?.total_amount || 0;
        this.month = data?.month || "";
        this.type = data?.type || "";
        this.subscription_type = data?.subscription_type || "";
        this.status = data?.status || 0;
        this.status_explain = data?.status_explain || "";
        this.owner = data?.owner || "";
        this.subscribe_date = data?.subscribe_date ? moment(data?.subscribe_date).format(DATE_TIME_FORMAT.EN_DATE_TIME) : "";
        this.packages = this.getPackages(data?.packages || [])
    }

    getPackages(packages = []) {
        let newPackages = []
        if (packages.length > 0) {
            packages.map(i => newPackages.push({
                key: i?.id || "",
                id: i?.id || "",
                lead_type: i?.lead_type || "",
                state: i?.state || "",
                county: i?.county || "",
            }))
        }
        return newPackages
    }

    exportList() {
        return {
            id: this.id,
            code: this.code,
            user_id: this.user_id,
            email: this.email,
            price: this.price,
            discount: this.discount,
            total_amount: this.total_amount,
            month: this.month,
            type: this.type,
            subscription_type: this.subscription_type,
            status: this.status,
            status_explain: this.status_explain,
            subscribe_date: this.subscribe_date,
            packages: this.packages,
            owner: this.owner,
        }
    }
}

export class MasterTransactionResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.status = data?.status || {};
        this.type = data?.type || [];
        this.subscription_type = data?.subscription_type || []
    }

    exportListMaster() {
        return {
            status: this.status,
            type: this.type,
            subscription_type: this.subscription_type,
        }
    }
}

export const listCols = ["id", "code", "email", "type", "status_explain", "discount_code", "price", "discount", "total_amount", "owner", "subscribe_date"];
export const listColDetail = ["key", "lead_type", "state", "county"];
export const constants = {
    status: ["Inactive", "Active"]
};
