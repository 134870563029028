import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {removeObjectNullFull} from "../../../utils/functions";
import {checkNumber} from "../../../utils/commonUtils";

export default class UploadResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.file_url = data?.file_url || "";
        this.file_type = data?.file_type || "";
    }

    exportUpload() {
        return {
            file_url: this.file_url,
            file_type: this.file_type,
        }
    }

}
