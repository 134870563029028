import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/Dashboard';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import {getAllDashboard, getItemCityFilter, getItemUserActivity} from '../services/dashboardService';
import {
    dashboardUserActivitySuccess,
    dashboardCityFilterSuccess, dashboardAllSuccess
} from '../actions/dashboardAction';
import {filtersParams} from "../mapping/Request/DashboardRequest";

function* actionUserActivity() {
    yield takeLatest(Types.DASHBOARD_USER_ACTIVITY_ACTION, Func.sagaWrapper(function* (action) {
        const params = filtersParams(action.params || {});
        const result = yield call(getItemUserActivity, params);
        yield put(dashboardUserActivitySuccess(result));
    }, errorHandle(Types.DASHBOARD_FAILED)))
}

function* actionCityFilter() {
    yield takeLatest(Types.DASHBOARD_CITY_FILTER_ACTION, Func.sagaWrapper(function* (action) {
        const params = filtersParams(action.params || {});
        const result = yield call(getItemCityFilter, params);
        yield put(dashboardCityFilterSuccess(result));
    }, errorHandle(Types.DASHBOARD_FAILED)))
}


function* actionGetAll() {
    yield takeLatest(Types.DASHBOARD_ALL_ACTION, Func.sagaWrapper(function* (action) {
        const params = filtersParams(action.params || {});
        const result = yield call(getAllDashboard, params);
        yield put(dashboardAllSuccess(result));
    }, errorHandle(Types.DASHBOARD_FAILED)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* dashboardSaga() {
    yield all([
        fork(actionGetAll),
        fork(actionUserActivity),
        fork(actionCityFilter),
    ])
}
