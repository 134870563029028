import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {formatInt, removeObjectNullFull} from "../../../utils/functions";

export default class SubscriptionGeoDataRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || ""
        this.status = data?.status || 0
    }

    exportUpdate() {
        return {
            status: this.status,
            id: this.id
        }
    }

}

export const common = {
    ACTION_FIELD: {},
}

export const filtersParams = (filters) => {
    return Object.keys(filters).length > 0 ? removeObjectNullFull({
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        order_field: filters?.order_field || "",
        order_value: filters?.order_value || "",
    }) : {}
}

