import {find, clone} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {formatInt, removeObjectNullFull} from "../../../utils/functions";

export default class ExclusiveContentRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.thumbnail = data?.thumbnail || "";
        this.video_url = data?.video_url || "";
        this.title = data?.title || "";
        this.description = data?.description || "";
        this.footer_icon = data?.footer_icon || false;
        this.footer_hot = data?.footer_hot || false;
        this.footer_description = data?.footer_description || "";
        this.status = data?.status || 0;
        this.status_explain = data?.status_explain || "";
        this.created_at = data?.created_at || "";
        this.updated_at = data?.updated_at || "";
    }

    exportCreate() {
        return {
            thumbnail: this.thumbnail,
            video_url: this.video_url,
            title: this.title,
            description: this.description,
            footer_icon: this.footer_icon,
            footer_hot: this.footer_hot,
            footer_description: this.footer_description,
            status: this.status,
        }
    }

    exportUpdate() {
        return {
            id: this.id,
            thumbnail: this.thumbnail,
            video_url: this.video_url,
            title: this.title,
            description: this.description,
            footer_icon: this.footer_icon,
            footer_hot: this.footer_hot,
            footer_description: this.footer_description,
            status: this.status,
        }
    }

}

export class ExclusiveContentLayoutRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.layout = this.detectedItems(data)
    }

    detectedItems(items = {}) {
        let newItem = {},
            layoutItems = removeObjectNullFull(items),
            listTitleGroups = [];
        Object.keys(layoutItems).map((i, key) => {
            let splitKey = i.split("__"),
                constantKeyGroups = `item_${key}__groups__g0`;
            if (!newItem[splitKey[0]]) {
                newItem = {
                    ...newItem,
                    [splitKey[0]]: {
                        id: splitKey[0],
                        title: items[splitKey[0]],
                        items: items[`${splitKey[0]}__items`],
                        groups: []
                    }
                }
            }
            listTitleGroups.push(constantKeyGroups)
        })
        if (listTitleGroups.length > 0) {
            listTitleGroups.map(i => {
                let splitKey = i.split("__");
                if (newItem[splitKey[0]]) {
                    newItem[splitKey[0]].groups = layoutItems[i]
                }
            })
        }
        let generateItemsLayout = []
        if (Object.keys(newItem).length > 0) {
            Object.keys(newItem).map(i => {
                generateItemsLayout.push(newItem[i])
            })
        }

        return generateItemsLayout;
    }

    // detectedItems(items = {}) {
    //     let newItem = [];
    //     console.log(555555555, items)
    //     Object.keys(items).map((i, key) => {
    //         let splitKey = i.split("__"),
    //             constantKeyGroups = `item_${key}__groups__g0`;
    //         if (!find(newItem, {id: splitKey[0]})) {
    //             newItem.push({
    //                 id: splitKey[0],
    //                 title: items[splitKey[0]],
    //                 items: items[`${splitKey[0]}__items`],
    //                 groups: []
    //             })
    //         }
    //         console.log("%c====>>>>>>", "color: red", newItem)
    //         if (items[constantKeyGroups] && items[constantKeyGroups].length > 0) {
    //             let checkItemGroups = find(newItem, {id: splitKey[0]}),
    //                 newItemsGroups = [];
    //             (items[constantKeyGroups] || []).map(itemGroups => {
    //                 if (itemGroups?.title || itemGroups?.items) {
    //                     newItemsGroups.push({
    //                         title: itemGroups?.title,
    //                         items: itemGroups?.items
    //                     })
    //                 }
    //             })
    //             checkItemGroups.groups = newItemsGroups
    //             console.log(232333333333, checkItemGroups)
    //             newItem.push(checkItemGroups)
    //         }
    //     })
    //
    //     newItem = [...new Set(newItem)]
    //
    //     console.log(77777777, newItem)
    //
    //     return newItem;
    // }

    generateItems(items = []) {
        let newItem = [];
        if (items.length > 0) {
            items.map(i => newItem.push({
                title: i?.title || "",
                items: i?.items || [],
                groups: i?.groups ? this.generateItems(i?.groups || []) : []
            }))
        }
        return newItem
    }

    exportUpdate() {
        return {
            data: this.layout
        }
    }
}

export const CONSTANT = {
    ACTION_FIELD_POST: {
        thumbnail: "thumbnail",
        video_url: "video_url",
        title: "title",
        description: "description",
        footer_icon: "footer_icon",
        footer_hot: "footer_hot",
        footer_description: "footer_description",
        status: "status",
    },
}
