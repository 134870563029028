import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";

export function verifyRoute(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.VERIFY_ROUTE,
        // endpointParams: { city_id },
        params: params,
        parser: data => data,
    }).get();
}

function dataParser(data = {}) {
    return {
        ...data.data,
        // result: (data.data.result || []).map(item => new HotelResponse(item).getListItem()),
        total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
    }
}
