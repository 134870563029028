import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import UserResponse, {MasterUserResponse} from "../mapping/Response/UserResponse";

export function getListUsers(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.USER_LIST,
        params: params,
        parser: dataParser,
    }).get();
}

export function masterData() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.USER_MASTER_DATA,
        // params: removeObjectNull({ city_id }),
        parser: data => new MasterUserResponse(data.data.result).exportRoles(),
    }).get();
}

export function create(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.USER_CREATE,
    }).post(data);
}

export function update(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.USER_UPDATE,
        endpointParams: {id: data?.id},
    }).put(data);
}

export function getItem(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.USER_GET_ITEM,
        endpointParams: {id},
        parser: data => new UserResponse(data.data.result).exportItem(),
    }).get();
}

function dataParser(data = {}) {
    return {
        ...data.data,
        result: (data.data.result || []).map(item => new UserResponse(item).exportList()),
        total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
    }
}
