import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";

export default class SubscriptionGeoDataResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || ""
        this.state = data?.state || ""
        this.county = data?.county ? data?.county.join(", ") : []
        this.city = data?.city ? data?.city.join(", ") : []
        this.metro = data?.metro ? data?.metro.join(", ") : []
        this.status = data?.status || 0
    }

    exportList() {
        return {
            id: this.id,
            state: this.state,
            county: this.county,
            city: this.city,
            metro: this.metro,
            status: this.status,
        }
    }
}
export const listCols = ["state", "county", "city", "metro", "status"];
export const constants = {
    status: ["Inactive", "Active"]
};
