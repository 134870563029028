export default class User {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || undefined;
        this.user = {
            id: data?.user?.id || "",
            email: data?.user?.email || "",
            first_name: data?.user?.first_name || "",
            last_name: data?.user?.last_name || "",
            is_super_admin: data?.user?.is_super_admin || false,
        };
        this.menu = this.setItemMenu(data?.menu)
        // this.avatar = data.avatar || undefined;
        // this.username = data.username || undefined;
        // this.email = data.email || undefined;
        // this.username = data.username || undefined;
        // this.status = data.status || 1;
    }

    setItemMenu(items = []) {
        let newMenu = [];
        if ((items || []).length > 0) {
            items.map(i => newMenu.push({
                name: i?.name || "",
                items: this.setItemRoute(i?.items || [])
            }))
        }
        return newMenu
    }

    setItemRoute(items = []) {
        let newRoute = [];
        if ((items || []).length > 0) {
            items.map(i => newRoute.push({
                name: i?.name || "",
                route: i?.route || "",
            }))
        }
        return newRoute
    }

    exportProfile() {
        return {
            user: this.user,
            menu: this.menu,
        }
    }

    setPermission(data = {}) {
        this.permissions = data.permissions || [];
    }

    setProperties(data = {}) {
        this.propertyIds = data.propertyIds || [];
    }

    export() {
        return {
            id: this.id,
            avatarUrl: this.avatarUrl,
            password: this.password,
            deactivated: this.deactivated,
            propertyIds: this.propertyIds,
            permissions: this.permissions,
            roleId: this.roleId,
            roleName: this.roleName,
            email: this.email,
            firstname: this.firstname,
            lastname: this.lastname,
            username: this.username,
            phoneNumber: this.phoneNumber,
            avatar: this.avatar,
        }
    }
}
