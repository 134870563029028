import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import * as HookAction from '../actions/userAction'

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        () => dispatch(HookAction.userInit()),
        [dispatch]
    )
}

export const ActionGetListUser = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.userListAction(filters)),
        [dispatch]
    )
}

export const ActionGetItem = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.userGetItemAction(params)),
        [dispatch]
    )
}

export const ActionMasterData = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.userMasterDataAction(params)),
        [dispatch]
    )
}

export const ActionCreateUser = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.userCreateAction(params)),
        [dispatch]
    )
}
export const ActionUpdateUser = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.userUpdateAction(params)),
        [dispatch]
    )
}
