import * as Types from '../types/upload';
import {PAGINATION} from '../../constants/define';
// import HotelRequest from "../mapping/Request/HotelRequest";

const initialState = {
    isFetching: false,
    statusAction: {
        isSubmit: false,
        isUnlock: false,
        unlockField: "",
        isExport: false
    },
    errors: [],
    // item: new HotelRequest().update(),
    files: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case Types.UPLOAD_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction, files: initialState.files};
        case Types.UPLOAD_SUCCESS:
            return {...state, isFetching: false, files: action.files, statusAction: initialState.statusAction};
        case Types.UPLOAD_FAIL:
            return {...state, errors: action.errors, isFetching: false, statusAction: initialState.statusAction};
        case Types.UPLOAD_INIT:
            return initialState;
        default:
            return state;
    }
}
