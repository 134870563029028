import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {
    columnsTable,
    genaratePaginateFilterSort,
    getDataSource,
    getTitleTable,
    removeObjectNull, removeObjectNullFull
} from "../../../../utils/functions";
import {omit, truncate} from "lodash";
import TAG_DEFINE from "../../../../constants/common";
import {PATHS} from "../../../../constants/define";
import PERMISSION from "../../../../constants/permission";

// ============== LAYOUT ==============
import HeaderAction from "../../../common/layout/HeaderAction";
import {Icon} from "../../../common";
import {ListComponent} from "../../../common/ListComponent";
import {ListSearchFreeTextComponent} from "../../../../utils/commonUtils";

// ============== ACTION ==============
// import {ActionGetListUser, ActionInit, ActionMasterData} from '../../../../data/hooks/user';
import {ActionGetListSubscriptionGeoData, ActionUpdateGeoData} from '../../../../data/hooks/subscriptionGeoData';
import {Tooltip, Button, Tag, Form, Input, Select, DatePicker, Popover, Popconfirm} from 'antd'

// ============== SELECT DATA ==============
// import {selectListUser, selectMasterData} from '../../../../data/reselects/userSelector';
import {selectListCustomer} from '../../../../data/reselects/customerSelector';
import {
    selectFetching,
    selectErrors,
    selectListSubscriptionGeoData,
    selectMasterData
} from '../../../../data/reselects/subscriptionGeoDataSelector';
import SubscriptionGeoDataResponse, {listCols} from "../../../../data/mapping/Response/SubscriptionGeoDataResponse";
import {common} from "../../../../data/mapping/Request/UserRequest";
import {constants} from "../../../../data/mapping/Response/SubscriptionCustomerResponse";
import {InfoCircleOutlined} from "@ant-design/icons";

let STATUS_RQ = [1, 0]
const ModelFields = Object.getOwnPropertyNames(new SubscriptionGeoDataResponse());
const Index = props => {
    const namePage = "subscription_geo_data",
        [initData, setInitData] = useState({
            titleItemTable: getTitleTable(listCols, [], []),
            modelItem: ModelFields,
        }),
        [paramsFilters, setPramsFilters] = useState({}),
        [form] = Form.useForm(),
        componentTable = {
            state: {width: 180},
            county: {
                width: 180,
                render: (val, record) => (
                    <Popover placement="top" title="County" content={val} trigger="click" className="cursorPointer">
                        {
                            truncate(val, {
                                'length': 50,
                                'omission': '...'
                            })
                        }
                    </Popover>
                ),
            },
            metro: {
                width: 180,
                render: (val, record) => (
                    <Popover placement="top" title="Metro" content={val} trigger="click" className="cursorPointer">
                        {
                            truncate(val, {
                                'length': 50,
                                'omission': '...'
                            })
                        }
                    </Popover>
                ),
            },
            city: {
                width: 180,
                render: (val, record) => (
                    <Popover placement="top" title="City" content={val} trigger="click" className="cursorPointer">
                        {
                            truncate(val, {
                                'length': 50,
                                'omission': '...'
                            })
                        }
                    </Popover>
                ),
            },
            status: {
                title: <Tooltip title="Click to change status">
                    <div className="clearfix">
                        <p className="float-start mb-0" style={{justifyContent: "center"}}>Status</p>
                        <InfoCircleOutlined className="float-start mt-1 ms-2 cursorPointer"
                                            style={{justifyContent: "center"}}/>
                    </div>
                </Tooltip>,
                width: 150,
                render: (val, record) => (
                    <Popconfirm placement="top" title="Are you sure?" onConfirm={() => actionUpdateGeoData({
                        id: record.id,
                        status: STATUS_RQ[record.status]
                    })} okText="Yes" cancelText="No" className="">
                        <Tag className="cursorPointer" color={val ? "#2db7f5" : "red"}>{constants.status[val]}</Tag>
                    </Popconfirm>
                ),
            },
            // action: {
            //     width: 100,
            //     align: 'center',
            //     key: 'action',
            //     fixed: 'right',
            //     render: (text, record) => <Icon type="edit-outlined" className="cursorPointer"
            //                                     onClick={() => props.history.push((PERMISSION.SUBSCRIPTION.PACKAGE_UPDATE.route).replace(":id", record.id), {
            //                                         group: PERMISSION.SUBSCRIPTION.PACKAGE_UPDATE.group,
            //                                         withParams: true
            //                                     })}/>,
            // }
        };
    const [isFetching, setIsFetching] = useState(false),
        [resultList, setResultList] = useState([]),
        [pagination, setPagination] = useState({}),
        // ============== ACTION ==============
        getListSubscriptionGeoData = ActionGetListSubscriptionGeoData(),
        actionUpdateGeoData = ActionUpdateGeoData(),
        // ============== SELECT DATA ==============
        itemListSubscriptionGeoData = useSelector(selectListSubscriptionGeoData()),
        itemErrors = useSelector(selectErrors()),
        itemIsFetching = useSelector(selectFetching());

    useEffect(() => {
        if ((itemListSubscriptionGeoData?.result || []).length < 1) {
            getListSubscriptionGeoData();
        }
    }, []);

    useEffect(() => {
        setIsFetching(itemIsFetching)
    }, [itemIsFetching]);

    useEffect(() => {
        setResultList(itemListSubscriptionGeoData?.result || [])
        setPagination({
            total: itemListSubscriptionGeoData?.total || 0,
            totalPage: itemListSubscriptionGeoData?.total_page || 0,
            defaultCurrent: itemListSubscriptionGeoData?.page_index || 1,
            pageSize: itemListSubscriptionGeoData?.page_size || 10
        })
    }, [itemListSubscriptionGeoData]);

    const onTableChange = (pagination, filters, sorter) => {
        // let currentFilters = getCurrentFilters();
        filters = omit({...paramsFilters, ...filters}, ['page_index', 'page_size']);
        let newFilters = genaratePaginateFilterSort(pagination, filters, sorter);
        setPramsFilters(newFilters)
        // setInitData({
        //     ...initData,
        //     filters: newFilters
        // });
        getListSubscriptionGeoData(newFilters);
    };

    const onCreate = (e, data) => {
        if (e === TAG_DEFINE.ACTION_OPTIONS.UPDATE) {
            props.history.push((PERMISSION.PACKAGE.UPDATE.route).replace(":id", data.id))
        } else {
            // props.history.push(PERMISSION.PACKAGE.CREATE.route, {group: PERMISSION.PACKAGE.CREATE.group})
            // props.history.push(PERMISSION.PACKAGE.CREATE.route)
        }
    };

    const onSearch = (val) => {
        // let getFilters = {...paramsFilters, ...val, page_index: 1};
        // setPramsFilters(getFilters)
        // getListSubscriptionGeoData(removeObjectNullFull(getFilters));
    };

    return <>
        <HeaderAction title="Subscription Geo Data List" disabledFormatUpperCaseTitle/>
        <ListComponent
            scroll={{x: 1000}}
            classParent="m-0 p-0 col-md-12 mt-4"
            marginTop={'0'} marginBottom={'0'}
            loading={{spinning: isFetching, size: 'large'}}
            rowKey="id"
            dataSource={getDataSource(resultList || [], initData.modelItem)}
            columns={columnsTable({
                titleTable: initData.titleItemTable,
                component: componentTable,
                itemName: namePage
            })}
            pagination={false}
            onChange={onTableChange}
            componentTableList={{}}
        />
    </>
}

export default Index
