import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/exclusiveContentAction'

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        () => dispatch(HookAction.exclusiveContentInit()),
        [dispatch]
    )
}

export const ActionGetListPost = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.exclusiveContentPostListAction(filters)),
        [dispatch]
    )
}

export const ActionGetItemPost = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.exclusiveContentPostGetItemAction(params)),
        [dispatch]
    )
}

export const ActionCreatePost = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.exclusiveContentPostCreateAction(params)),
        [dispatch]
    )
}
export const ActionUpdatePost = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.exclusiveContentPostUpdateAction(params)),
        [dispatch]
    )
}
export const ActionUpdateLayout = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.exclusiveContentLayoutUpdateAction(params)),
        [dispatch]
    )
}

export const ActionGetListLayout = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.exclusiveContentLayoutListAction(filters)),
        [dispatch]
    )
}

