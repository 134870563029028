import React from 'react';
import { Form as AntForm} from 'antd';
const Form = (props) => {
    return (
        <AntForm {...props} className={props.className}>{props.children}</AntForm>
    )
}
export default Form;
export const RealForm = AntForm;
export const FormItem = AntForm.Item;
