import {createSelector} from 'reselect'

const stateReducer = state => state.ticketReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        ticketReducer => ticketReducer.isFetching
    )

export const selectErrors = (initItems) =>
    createSelector(
        stateReducer,
        ticketReducer => ticketReducer.errors
    )

export const selectListTicket = (initItems) =>
    createSelector(
        stateReducer,
        ticketReducer => ticketReducer.data
    )

export const selectMasterData = (initItems) =>
    createSelector(
        stateReducer,
        ticketReducer => ticketReducer.masterData
    )
export const selectTicketItem = (initItems) =>
    createSelector(
        stateReducer,
        ticketReducer => ticketReducer.item
    )
