import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {
    columnsTable,
    genaratePaginateFilterSort,
    getDataSource,
    getTitleTable,
    removeObjectNull, removeObjectNullFull
} from "../../../utils/functions";
import {omit} from "lodash";
import TAG_DEFINE from "../../../constants/common";
import {PATHS} from "../../../constants/define";
import PERMISSION from "../../../constants/permission";
import moment from "moment";

// ============== LAYOUT ==============
import HeaderAction from "../../common/layout/HeaderAction";
import {Icon} from "../../common";
import {ListComponent} from "../../common/ListComponent";
import {ListSearchFreeTextComponent} from "../../../utils/commonUtils";

// ============== ACTION ==============
import {ActionGetListCustomer, ActionMasterData, ActionCustomerTeamPointList} from '../../../data/hooks/customer';
import {Tooltip, Button, Tag, Form, Input, Select, DatePicker} from 'antd'

// ============== SELECT DATA ==============
import {
    selectFetching,
    selectErrors,
    selectListCustomer,
    selectMasterData,
    selectCustomerTeamPointList
} from '../../../data/reselects/customerSelector';
import CustomerResponse, {temPointListCols, TeamPointResponse} from "../../../data/mapping/Response/CustomerResponse";
import {common} from "../../../data/mapping/Request/UserRequest";

const {RangePicker} = DatePicker;

const ModelFields = Object.getOwnPropertyNames(new TeamPointResponse());
const Index = props => {
    const namePage = "customer_team_point",
        [initData, setInitData] = useState({
            titleItemTable: getTitleTable(temPointListCols, [], []),
            modelItem: ModelFields,
        }),
        [paramsFilters, setPramsFilters] = useState({}),
        [form] = Form.useForm(),
        componentTable = {};
    const [isFetching, setIsFetching] = useState(false),
        [resultList, setResultList] = useState([]),
        [pagination, setPagination] = useState({}),
        // ============== ACTION ==============
        getListCustomers = ActionCustomerTeamPointList(),
        getMasterData = ActionMasterData(),
        // ============== SELECT DATA ==============
        itemListCustomers = useSelector(selectCustomerTeamPointList()),
        itemErrors = useSelector(selectErrors()),
        itemMasterData = useSelector(selectMasterData()),
        itemIsFetching = useSelector(selectFetching());

    useEffect(() => {
        if ((itemListCustomers?.result || []).length < 1) {
            getListCustomers();
        }
        if (Object.keys(itemMasterData).length < 1) {
            getMasterData()
        }
    }, []);

    useEffect(() => {
        setIsFetching(itemIsFetching)
    }, [itemIsFetching]);

    useEffect(() => {
        setResultList(itemListCustomers?.result || [])
        setPagination({
            total: itemListCustomers?.total || 0,
            totalPage: itemListCustomers?.total_page || 0,
            defaultCurrent: itemListCustomers?.page_index || 1,
            pageSize: itemListCustomers?.page_size || 10
        })
    }, [itemListCustomers]);

    const onTableChange = (pagination, filters, sorter) => {
        filters = omit({...paramsFilters, ...filters}, ['page_index', 'page_size']);
        let newFilters = genaratePaginateFilterSort(pagination, filters, sorter);
        setPramsFilters(newFilters)
        getListCustomers(newFilters);
    };

    const onSearch = (val) => {
        let getFilters = {...paramsFilters, ...val};
        setPramsFilters(getFilters)
        getListCustomers(removeObjectNullFull(getFilters));
    };

    return <>
        <HeaderAction title="Team Point"/>
        <div className="container-fluid px-4">
            <Form
                form={form}
                layout="vertical"
                onFinish={onSearch}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className="row bg-white py-3 align-items-center">
                    <div className="col-4">
                        <Form.Item
                            label="Search"
                            name="search"
                        >
                            <Input allowClear/>
                        </Form.Item>
                    </div>
                    <div className="col-4">
                        <Form.Item
                            label="Create Date"
                            name="date"
                        >
                            <RangePicker style={{width: "100%"}}/>
                        </Form.Item>
                    </div>
                    <div className="col-1">
                        <Form.Item className="text-end" label=" ">
                            <Button type="primary" htmlType="submit">
                                Search {isFetching && <Icon type="sync-outlined" spin/>}
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </div>
        <ListComponent
            scroll={{x: 1000}}
            classParent="m-0 p-0 col-md-12 mt-4"
            marginTop={'0'} marginBottom={'0'}
            loading={{spinning: isFetching, size: 'large'}}
            rowKey="id"
            dataSource={getDataSource(resultList || [], initData.modelItem)}
            columns={columnsTable({
                titleTable: initData.titleItemTable,
                component: componentTable,
                itemName: namePage
            })}
            pagination={{
                total: pagination.total,
                totalPage: pagination.totalPage,
                current: pagination.defaultCurrent,
                pageSize: pagination.pageSize
            }}
            onChange={onTableChange}
            componentTableList={{}}
        />
    </>
}

export default Index
