import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {
    columnsTable,
    genaratePaginateFilterSort,
    getDataSource,
    getTitleTable,
    removeObjectNull, removeObjectNullFull
} from "../../../../utils/functions";
import {omit, truncate} from "lodash";
import TAG_DEFINE from "../../../../constants/common";
import {PATHS} from "../../../../constants/define";
import PERMISSION from "../../../../constants/permission";

// ============== LAYOUT ==============
import HeaderAction from "../../../common/layout/HeaderAction";
import {Icon} from "../../../common";
import {ListComponent} from "../../../common/ListComponent";
import {ListSearchFreeTextComponent} from "../../../../utils/commonUtils";

// ============== ACTION ==============
// import {ActionGetListUser, ActionInit, ActionMasterData} from '../../../../data/hooks/user';
import {ActionGetListCustomer} from '../../../../data/hooks/customer';
import {ActionGetListSubscriptionCustomer} from '../../../../data/hooks/subscriptionCustomer';
import {
    ActionGetListSubscriptionPackage,
    ActionMasterData,
    ActionUpdatePackage
} from '../../../../data/hooks/subscriptionPackage';
import {Tooltip, Button, Tag, Form, Input, Select, DatePicker, Popover, Modal, Spin, InputNumber, message} from 'antd'

// ============== SELECT DATA ==============
// import {selectListUser, selectMasterData} from '../../../../data/reselects/userSelector';
import {selectListCustomer} from '../../../../data/reselects/customerSelector';
import {
    selectFetching,
    selectErrors,
    selectListSubscriptionPackage,
    selectMasterData,
    selectStatusAction
} from '../../../../data/reselects/subscriptionPackageSelector';
import SubscriptionPackageResponse, {listCols} from "../../../../data/mapping/Response/SubscriptionPackageResponse";
import {common} from "../../../../data/mapping/Request/UserRequest";
import {constants} from "../../../../data/mapping/Response/SubscriptionCustomerResponse";
import {EyeOutlined} from "@ant-design/icons";


const ModelFields = Object.getOwnPropertyNames(new SubscriptionPackageResponse());
const Index = props => {
    const namePage = "subscription_packages",
        [initData, setInitData] = useState({
            titleItemTable: getTitleTable(listCols, [], []),
            modelItem: ModelFields,
        }),
        [paramsFilters, setPramsFilters] = useState({}),
        [typeUpdate, setTypeUpdate] = useState(""),
        [form] = Form.useForm(),
        componentTable = {
            discount: {
                render: (val, record) => val ? <a style={{color: "#0d6efd"}} onClick={e => {
                        setTypeUpdate("D")
                        setIdUpdate(record?.id);
                        setValueDiscountUpdate(val)
                        setIsOpenDiscount(true)
                    }}>{val}</a> :
                    <div style={{color: "#0d6efd", cursor: "pointer"}} onClick={e => {
                        setTypeUpdate("D")
                        setIdUpdate(record?.id);
                        setValueDiscountUpdate(val)
                        setIsOpenDiscount(true)
                    }}>NOT SET</div>,
            },
            price: {
                render: (val, record) => <a style={{color: "#0d6efd"}} onClick={e => {
                    setTypeUpdate("P")
                    setIdUpdate(record?.id);
                    setValueDiscountUpdate(val)
                    setIsOpenDiscount(true)
                }}>{val}</a>,
            }
        };
    const [isFetching, setIsFetching] = useState(false),
        [isOpenDiscount, setIsOpenDiscount] = useState(false),
        [valueDiscountUpdate, setValueDiscountUpdate] = useState(0),
        [idUpdate, setIdUpdate] = useState(""),
        [resultList, setResultList] = useState([]),
        [pagination, setPagination] = useState({}),
        // ============== ACTION ==============
        getListSubscriptionPackages = ActionGetListSubscriptionPackage(),
        actionUpdatePackage = ActionUpdatePackage(),
        getMasterData = ActionMasterData(),
        // ============== SELECT DATA ==============
        itemListSubscriptionPackages = useSelector(selectListSubscriptionPackage()),
        itemStatusAction = useSelector(selectStatusAction()),
        itemErrors = useSelector(selectErrors()),
        itemMasterData = useSelector(selectMasterData()),
        itemIsFetching = useSelector(selectFetching());

    useEffect(() => {
        getListSubscriptionPackages();
        // if (Object.keys(itemMasterData).length < 1) {
        //     getMasterData()
        // }
    }, []);

    useEffect(() => {
        setIsFetching(itemIsFetching)
    }, [itemIsFetching]);

    useEffect(() => {
        setResultList(itemListSubscriptionPackages?.result || [])
        setPagination({
            total: itemListSubscriptionPackages?.total || 0,
            totalPage: itemListSubscriptionPackages?.total_page || 0,
            defaultCurrent: itemListSubscriptionPackages?.page_index || 1,
            pageSize: itemListSubscriptionPackages?.page_size || 10
        })
    }, [itemListSubscriptionPackages]);

    const onTableChange = (pagination, filters, sorter) => {
        // let currentFilters = getCurrentFilters();
        filters = omit({...paramsFilters, ...filters}, ['page_index', 'page_size']);
        let newFilters = genaratePaginateFilterSort(pagination, filters, sorter);
        setPramsFilters(newFilters)
        // setInitData({
        //     ...initData,
        //     filters: newFilters
        // });
        getListSubscriptionPackages(newFilters);
    };

    useEffect(() => {
        // if (itemStatusAction?.updateDiscountSuccess) {
        setIsOpenDiscount(itemStatusAction?.isUpdateDiscount)
        // }
    }, [itemStatusAction]);

    const onCreate = (e, data) => {
        if (e === TAG_DEFINE.ACTION_OPTIONS.UPDATE) {
            props.history.push((PERMISSION.PACKAGE.UPDATE.route).replace(":id", data.id))
        } else {
            // props.history.push(PERMISSION.PACKAGE.CREATE.route, {group: PERMISSION.PACKAGE.CREATE.group})
            // props.history.push(PERMISSION.PACKAGE.CREATE.route)
        }
    };

    const onSearch = (val) => {
        let getFilters = {...paramsFilters, ...val, page_index: 1};
        setPramsFilters(getFilters)
        getListSubscriptionPackages(removeObjectNullFull(getFilters));
    };

    const onUpdateDiscount = val => {
        if (idUpdate) {
            if (typeUpdate === "D") {
                if (/^[0-9]+%?$/i.test(valueDiscountUpdate)) {
                    // console.log(11111, {idUpdate, valueDiscountUpdate})
                    actionUpdatePackage({
                        query: {
                            id: idUpdate,
                            discount: valueDiscountUpdate
                        },
                        filters: paramsFilters
                    })
                } else {
                    message.error("Discount is not valid")
                }
            } else if (typeUpdate === "P") {
                actionUpdatePackage({
                    query: {
                        id: idUpdate,
                        price: valueDiscountUpdate
                    },
                    filters: paramsFilters
                })
            }
        } else {
            message.error("Item is empty")
        }
    }

    return <>
        <HeaderAction title="Subscription Package List" disabledFormatUpperCaseTitle/>
        <Modal
            // width={1000}
            title={typeUpdate === "D" ? "Update Discount" : "Update Price"}
            destroyOnClose
            visible={isOpenDiscount}
            onOk={() => onUpdateDiscount()}
            onCancel={() => {
                setTypeUpdate("");
                setValueDiscountUpdate("");
                setIsOpenDiscount(false);
            }}
            maskClosable={false}
        >
            <Spin spinning={isFetching}>
                {/*<InputNumber addonBefore="+"  defaultValue={valueDiscountUpdate} onChange={e => setValueDiscountUpdate(e.target.value)} />*/}
                <Input value={valueDiscountUpdate} disabled={isFetching} allowClear
                       onChange={e => setValueDiscountUpdate(e.target.value)}/>
            </Spin>
        </Modal>
        <ListComponent
            scroll={{x: 1000}}
            classParent="m-0 p-0 col-md-12 mt-4"
            marginTop={'0'} marginBottom={'0'}
            loading={{spinning: isFetching, size: 'large'}}
            rowKey="id"
            dataSource={getDataSource(resultList || [], initData.modelItem)}
            columns={columnsTable({
                titleTable: initData.titleItemTable,
                component: componentTable,
                itemName: namePage
            })}
            pagination={{
                total: pagination.total,
                totalPage: pagination.totalPage,
                current: pagination.defaultCurrent,
                pageSize: pagination.pageSize
            }}
            onChange={onTableChange}
            componentTableList={{}}
        />
    </>
}

export default Index
