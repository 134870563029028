import React from 'react';
import PERMISSION from './constants/permission'

const routes = [
    {
        is_title: true,
        is_redirect: false,
        title: "Trang Chủ",
        group: "home",
        path: '',
        exact: false,
        icon: 'dashboard',
        show: false,
        sub_menu: [
            {
                is_redirect: false,
                title: 'Home',
                name: 'Home',
                path: PERMISSION.HOME.VIEW.route,
                icon: 'icon-handbag',
                group: "data",
                permission_key: PERMISSION.HOME.VIEW.permission,
                exact: false,
                show: true,
            },
        ]
    }, {
        is_title: true,
        is_redirect: false,
        title: "Customer Management",
        name: 'Customer Management',
        group: "customer",
        path: '',
        exact: true,
        icon: 'mdi-ticket-percent',
        show: false,
        permission: "data",
        sub_menu: [
            {
                is_redirect: false,
                title: 'Listing',
                name: 'Listing',
                path: PERMISSION.CUSTOMER.VIEW.route,
                icon: 'icon-handbag',
                group: "customer",
                permission_key: PERMISSION.CUSTOMER.VIEW.permission,
                exact: false,
                show: true,
            },
            {
                is_redirect: false,
                title: 'Listing',
                name: 'Listing',
                path: PERMISSION.CUSTOMER.TEAM_POINT.route,
                icon: 'icon-handbag',
                group: "customer",
                permission_key: PERMISSION.CUSTOMER.TEAM_POINT.permission,
                exact: false,
                show: true,
            }
        ]
    }, {
        is_title: true,
        is_redirect: false,
        title: "Company Management",
        name: 'Company Management',
        group: "company",
        path: '',
        exact: true,
        icon: 'mdi-ticket-percent',
        show: false,
        permission: "data",
        sub_menu: [
            {
                is_redirect: false,
                title: 'Listing',
                name: 'Listing',
                path: PERMISSION.COMPANY.VIEW.route,
                icon: 'icon-handbag',
                group: "company",
                permission_key: PERMISSION.COMPANY.VIEW.permission,
                exact: false,
                show: true,
            }
        ]
    }, {
        is_title: true,
        is_redirect: false,
        title: "Subscription",
        name: 'Subscription',
        group: "subscription",
        path: '',
        exact: true,
        icon: 'mdi-ticket-percent',
        show: false,
        permission: "data",
        sub_menu: [
            {
                is_redirect: false,
                title: 'Customer View',
                name: 'Customer View',
                path: PERMISSION.SUBSCRIPTION.CUSTOMER_VIEW.route,
                icon: 'icon-handbag',
                group: "subscription",
                permission_key: PERMISSION.SUBSCRIPTION.CUSTOMER_VIEW.permission,
                exact: false,
                show: true,
            }, {
                is_redirect: false,
                title: 'Package View',
                name: 'Package View',
                path: PERMISSION.SUBSCRIPTION.PACKAGE_VIEW.route,
                icon: 'icon-handbag',
                group: "subscription",
                permission_key: PERMISSION.SUBSCRIPTION.PACKAGE_VIEW.permission,
                exact: false,
                show: true,
            }, {
                is_redirect: false,
                title: 'Transaction View',
                name: 'Transaction View',
                path: PERMISSION.SUBSCRIPTION.TRANSACTION_VIEW.route,
                icon: 'icon-handbag',
                group: "subscription",
                permission_key: PERMISSION.SUBSCRIPTION.TRANSACTION_VIEW.permission,
                exact: false,
                show: true,
            }, {
                is_redirect: false,
                title: 'Customer Recurring',
                name: 'Customer Recurring',
                path: PERMISSION.SUBSCRIPTION.CUSTOMER_RECURRING.route,
                icon: 'icon-handbag',
                group: "subscription",
                permission_key: PERMISSION.SUBSCRIPTION.CUSTOMER_RECURRING.permission,
                exact: false,
                show: true,
            },
        ]
    }, {
        is_title: true,
        is_redirect: false,
        title: "User Management",
        name: 'User Management',
        group: "user",
        path: '',
        exact: true,
        icon: 'mdi-ticket-percent',
        show: false,
        permission: "data",
        sub_menu: [
            {
                is_redirect: false,
                title: 'Listing',
                name: 'Listing',
                path: PERMISSION.USER.VIEW.route,
                icon: 'icon-handbag',
                group: "user",
                permission_key: PERMISSION.USER.VIEW.permission,
                exact: false,
                show: true,
            }
        ]
    }, {
        is_title: true,
        is_redirect: false,
        title: "CONFIGURATION",
        name: 'CONFIGURATION',
        group: "configuration",
        path: '',
        exact: true,
        icon: 'mdi-ticket-percent',
        show: false,
        permission: "data",
        sub_menu: [
            {
                is_redirect: false,
                title: 'Listing',
                name: 'Listing',
                path: PERMISSION.CONFIGURATION.VIEW.route,
                icon: 'icon-handbag',
                group: "configuration",
                permission_key: PERMISSION.CONFIGURATION.VIEW.permission,
                exact: false,
                show: true,
            }
        ]
    }, {
        is_title: true,
        is_redirect: false,
        title: "",
        name: '',
        group: "errorPages",
        path: '/403',
        exact: false,
        icon: 'mdi-ticket-percent',
        show: false,
        permission: "data",
        sub_menu: []
    }, {
        is_title: true,
        is_redirect: false,
        title: "",
        name: '',
        group: "errorPages",
        path: '/404',
        exact: false,
        icon: 'mdi-ticket-percent',
        show: false,
        permission: "data",
        sub_menu: []
    }
];

export default routes;
