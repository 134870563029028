import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";

export function dataUpload(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: (Urls.DATA_UPLOAD).replace(":leadType", data?.leadType),
    }).post(data.formData);
}

export function dataTruncate(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.DATA_TRUNCATE,
        // endpointParams: {id},
    }).delete();
}

export function masterDataLeadType() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.DATA_MASTER_LEAD_TYPE,
        // params: removeObjectNull({ city_id }),
        parser: data => data.data.result,
    }).get();
}
