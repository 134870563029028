import {createSelector} from 'reselect'

const stateReducer = state => state.uploadReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        uploadReducer => uploadReducer.isFetching
    )

export const selectStatusAction = (initData) =>
    createSelector(
        stateReducer,
        uploadReducer => uploadReducer.statusAction
    )

export const selectFilesUpload = (initItems) =>
    createSelector(
        stateReducer,
        uploadReducer => uploadReducer.files
    )
