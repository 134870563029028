import {combineReducers} from "redux";
import authReducer from './authReducer';
import hotelReducer from './hotelReducer';
import verifyReducer from './verifyReducer';
import dataReducer from './dataReducer';
import userReducer from './userReducer';
import customerReducer from './customerReducer';
import dashboardReducer from './dashboardReducer';
import ticketReducer from './ticketReducer';
import subscriptionCustomerReducer from './subscriptionCustomerReducer';
import subscriptionPackageReducer from './subscriptionPackageReducer';
import subscriptionTransactionReducer from './subscriptionTransactionReducer';
import subscriptionCancelationFeedbackReducer from './subscriptionCancelationFeedbackReducer';
import subscriptionGeneralPackageReducer from './subscriptionGeneralPackageReducer';
import subscriptionGeoDataReducer from './subscriptionGeoDataReducer';
import subscriptionCustomPackageReducer from './subscriptionCustomPackageReducer';
import exclusiveContentReducer from './exclusiveContentReducer';
import subscriptionCustomerRecurringReducer from './subscriptionCustomerRecurringReducer';
import uploadReducer from './uploadReducer';
import companyReducer from './companyReducer';
import configurationReducer from './configurationReducer';

export default combineReducers({
    authReducer,
    hotelReducer,
    verifyReducer,
    dataReducer,
    userReducer,
    customerReducer,
    dashboardReducer,
    ticketReducer,
    subscriptionCustomerReducer,
    subscriptionPackageReducer,
    subscriptionCancelationFeedbackReducer,
    subscriptionTransactionReducer,
    exclusiveContentReducer,
    uploadReducer,
    subscriptionGeneralPackageReducer,
    subscriptionGeoDataReducer,
    subscriptionCustomPackageReducer,
    subscriptionCustomerRecurringReducer,
    companyReducer,
    configurationReducer,
});
