import React, {Component} from 'react';
import {Menu, Dropdown, Icon, Avatar, Badge, Input, Button} from 'antd';
import {Link, withRouter} from "react-router-dom";
import TAG_DEFINE from '../../../constants/common';
import {capitalizeFirstLetter} from '../../../utils/functions';

const path = document.location.origin;

const HeaderAction = (props) => {
    return (
        <div className="row hdr-nav-bar">
            <div className="col-md-12">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <a className="navbar-brand hidden-lg-up">Horizontal Menu</a>
                    <a className="navbar-toggler">
                        <span className="ti-menu" data-toggle="collapse" data-target="#navbarText"/>
                    </a>
                    <div className="collapse navbar-collapse" id="navbarText">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item active">
                                <a className="nav-link"><p
                                    className="headerTitle">{!props?.disabledFormatUpperCaseTitle ? capitalizeFirstLetter(props.title.toLowerCase()) : props.title}</p>
                                </a>
                            </li>
                        </ul>
                        <form className="form-inline my-2 my-lg-0">
                            {
                                props.isCreate &&
                                <button type="button" className="btn btn-success btn-rounded"
                                        onClick={(e) => props.onClick(e)}>
                                    <i className="fa fa-plus-circle m-r-5"/>
                                    {TAG_DEFINE.ACTION.create}
                                </button>
                            }
                            {
                                props.isFilterButton &&
                                <button type="button" className="btn btn-info mr-3" data-toggle="collapse"
                                        data-target="#listing-filter">
                                    <i className="fas fa-filter"/> {TAG_DEFINE.ACTION.filter}
                                </button>
                            }
                            {
                                props.isCustomize && props.children
                            }
                        </form>
                    </div>
                </nav>
            </div>
            {/*<div>*/}
            {/*    <button*/}
            {/*        className="right-side-toggle waves-effect waves-light btn-inverse btn btn-circle btn-sm pull-right m-l-10">*/}
            {/*        <i className="ti-settings text-white"/></button>*/}
            {/*</div>*/}
        </div>
    );

}

export default HeaderAction;
