import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";

export default class SubscriptionCancelationFeedbackResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.user_id = data?.user_id || "";
        this.email = data?.email || "";
        this.reason = data?.reason || [];
        this.point = data?.point || 0;
        this.experience = data?.experience || "";
        this.feedback = data?.feedback || " ";
        this.created_date = data?.created_date ? moment(data?.created_date).format(DATE_TIME_FORMAT.EN_DATE_TIME) : "";
    }

    generateReason(items = []) {
        let newItems = [];
        if (items.length > 0) {
            items.map((i, k) => newItems.push(<p className="mb-0" key={k}>{i}</p>))
        }
        return newItems
    }

    exportList() {
        return {
            id: this.id,
            email: this.email,
            reason: this.generateReason(this.reason),
            point: this.point,
            experience: this.experience,
            feedback: this.feedback,
            created_date: this.created_date,
        }
    }
}

export const listCols = ['id', 'email', "point", "experience", "feedback", "created_date"];
