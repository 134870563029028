import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/uploadAction'

export const ActionUpload = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.uploadAction(params)),
        [dispatch]
    )
}

export const ActionUploadInitStore = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.uploadInitStore(params)),
        [dispatch]
    )
}
