import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import SubscriptionGeoDataResponse from "../mapping/Response/SubscriptionGeoDataResponse";

export function getListSubscriptionGeoData(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.SUBSCRIPTION_GEO_DATA_LIST,
        params: params,
        parser: dataParser,
    }).get();
}

// export function masterData() {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.SUBSCRIPTION_PACKAGE_MASTER_DATA,
//         // params: removeObjectNull({ city_id }),
//         parser: data => new MasterPackageResponse(data.data.result).exportListMaster(),
//     }).get();
// }

// export function create(data) {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.CUSTOMER_CREATE,
//     }).post(data);
// }

export function update(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.SUBSCRIPTION_GEO_DATA_UPDATE,
        endpointParams: {id: encodeURIComponent(data?.id)},
    }).put(data);
}

// export function getItem(id) {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.SUBSCRIPTION_PACKAGE_DETAIL,
//         endpointParams: {id: encodeURIComponent(id)},
//         parser: data => new SubscriptionPackageResponse(data.data.result).exportItem(),
//     }).get();
// }

function dataParser(data = {}) {
    return {
        ...data.data,
        result: (data.data.result || []).map(item => new SubscriptionGeoDataResponse(item).exportList()),
        total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
    }
}
