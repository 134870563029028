import Index from "./pages";
import DataIndex from "./pages/data";
import UserIndex from "./pages/users";
import CustomerIndex from "./pages/customers";
import UserAction from "./pages/users/actions";
import CustomerAction from "./pages/customers/actions";
import TicketIndex from "./pages/tickets";
import TicketAction from "./pages/tickets/actions/index";
import SubscriptionPackageList from "./pages/subscription/packages";
import SubscriptionCustomerList from "./pages/subscription/customers";
import SubscriptionTransactionList from "./pages/subscription/transaction";
import SubscriptionCustomerAction from "./pages/subscription/customers/actions";
import SubscriptionPackageAction from "./pages/subscription/packages/actions";
import SubscriptionCancelationFeedback from "./pages/cancelationFeedback";
import ExclusiveContentPost from "./pages/exclusiveContent/post";
import ExclusiveContentPostAction from "./pages/exclusiveContent/post/actions";
import ExclusiveContentLayout from "./pages/exclusiveContent/layout";
import SubscriptionGeneralPackage from "./pages/subscription/generalPackage";
import SubscriptionGeoData from "./pages/subscription/geoData";
import SubscriptionCustomPackage from "./pages/subscription/customPackage";
import SubscriptionCustomerRecurring from "./pages/subscription/recurring";
import CustomerTeamPoint from "./pages/customers/teamPoint";
import CompanyList from "./pages/company";
import CompanyAction from "./pages/company/actions";
import SubscriptionCustomerRecurringAction from "./pages/subscription/recurring/actions";
import ConfigurationList from "./pages/configuration";
import Login from "./Login";
import Error403 from "./403";
import Error from "./Error";
// import Login from "../Login";

export default {
    Index,
    UserAction,
    UserIndex,
    CustomerIndex,
    CustomerAction,
    TicketIndex,
    TicketAction,
    DataIndex,
    SubscriptionPackageList,
    SubscriptionCustomerList,
    SubscriptionCustomerAction,
    SubscriptionPackageAction,
    SubscriptionTransactionList,
    SubscriptionCancelationFeedback,
    ExclusiveContentPost,
    ExclusiveContentPostAction,
    ExclusiveContentLayout,
    SubscriptionGeneralPackage,
    SubscriptionGeoData,
    SubscriptionCustomPackage,
    SubscriptionCustomerRecurring,
    CustomerTeamPoint,
    CompanyList,
    CompanyAction,
    SubscriptionCustomerRecurringAction,
    ConfigurationList,
    Login,
    Error403,
    Error,
    // Login,
};
