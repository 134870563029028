import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import SubscriptionCustomerResponse, {MasterCustomerResponse} from "../mapping/Response/SubscriptionCustomerResponse";

export function getListSubscriptionCustomers(params, options) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.SUBSCRIPTION_CUSTOMER_LIST,
        params: params,
        ...options,
        parser: data => params?.is_export ? data : {
            ...data.data,
            result: (data.data.result || []).map(item => new SubscriptionCustomerResponse(item).exportList()),
            total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
        },
    }).get();
}

export function masterData() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.SUBSCRIPTION_CUSTOMER_MASTER_DATA,
        // params: removeObjectNull({ city_id }),
        parser: data => new MasterCustomerResponse(data.data.result).exportListMaster(),
    }).get();
}

export function masterState() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.SUBSCRIPTION_CUSTOMER_MASTER_STATE,
        // params: removeObjectNull({ city_id }),
        parser: data => new MasterCustomerResponse(data.data.result).exportListMasterState(),
    }).get();
}

export function create(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.SUBSCRIPTION_CUSTOMER_CREATE,
    }).post(data);
}

export function update(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.SUBSCRIPTION_CUSTOMER_UPDATE,
        endpointParams: {id: encodeURIComponent(data?.id)},
    }).put(data);
}

export function getDelete(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.SUBSCRIPTION_CUSTOMER_DELETE,
        endpointParams: {id: encodeURIComponent(id)},
        parser: data => data?.data?.result
    }).delete();
}

export function getItem(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.SUBSCRIPTION_CUSTOMER_DETAIL,
        endpointParams: {id: encodeURIComponent(id)},
        parser: data => new SubscriptionCustomerResponse(data.data.result).exportItem(),
    }).get();
}

function dataParser(data = {}) {
    return {
        ...data.data,
        result: (data.data.result || []).map(item => new SubscriptionCustomerResponse(item).exportList()),
        total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
    }
}
