import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';

export function getConfigurationCma(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CONFIGURATION_CMA,
        params: params,
        parser: data => data?.data?.result,
    }).get();
}

export function masterData() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CONFIGURATION_MASTER_DATA,
        // params: removeObjectNull({ city_id }),
        // parser: data => new MasterConfigurationResponse(data.data.result).exportMaster(),
    }).get();
}

export function updateCma(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CONFIGURATION_CMA_UPDATE,
    }).post(data);
}

export function update(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CONFIGURATION_UPDATE,
        endpointParams: {id: data?.id},
    }).put(data);
}

export function getItem(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CONFIGURATION_GET_ITEM,
        endpointParams: {id},
        // parser: data => new ConfigurationResponse(data.data.result).exportItem(),
    }).get();
}

// function dataParser(data = {}) {
//     return {
//         ...data.data,
//         result: (data.data.result || []).map(item => new ConfigurationResponse(item).exportList()),
//         total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
//     }
// }
