import React, {useState, useEffect} from "react";
import {useSelector} from 'react-redux'

// ============== LAYOUT ==============
import {Form, Input, Button, Select, Spin, Checkbox, Upload} from 'antd';
import HeaderAction from "../../../../common/layout/HeaderAction";

// ============== ACTION ==============
import {
    ActionMasterData,
    ActionCreateCustomer,
    // ActionInit,
    ActionGetItem,
    ActionUpdateCustomer
} from "../../../../../data/hooks/customer";
import {
    ActionCreatePost,
    ActionInit,
    ActionGetItemPost,
    ActionUpdatePost
} from "../../../../../data/hooks/exclusiveContent";

// ============== SELECT DATA ==============
import {
    selectFetching as selectFetchingUpload,
} from "../../../../../data/reselects/uploadSelector";
import {
    selectFetching,
    selectPostItem,
} from "../../../../../data/reselects/exclusiveContentSelector";
import {common} from "../../../../../data/mapping/Request/CustomerRequest";
import {CONSTANT} from "../../../../../data/mapping/Request/ExclusiveContentRequest";
import {CONSTANT as CONSTANT_RESPONSE} from "../../../../../data/mapping/Response/ExclusiveContentResponse";
import {Icon} from "../../../../common";
import {isEmail} from "../../../../../utils/validation";
import PERMISSION from "../../../../../constants/permission";
import TAG_DEFINE from "../../../../../constants/common";
import {ActionUpload, ActionUploadInitStore} from "../../../../../data/hooks/upload";
import {LoadingOutlined, PlusOutlined, UploadOutlined} from "@ant-design/icons";
import {selectFilesUpload} from "../../../../../data/reselects/uploadSelector";

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

const Index = (props) => {
    const [form] = Form.useForm(),
        [isFetching, setIsFetching] = useState(false),
        [loading, setLoading] = useState(false),
        [typePage, setTypePage] = useState(TAG_DEFINE.ACTION.create),
        [masterRoles, setMasterRoles] = useState({}),
        [masterStatus, setMasterStatus] = useState({}),
        [masterAdmin, setMasterAdmin] = useState({}),
        [isUpdate, setIsUpdate] = useState(false),
        [imageUrl, setImageUrl] = useState(),
        [files, setFiles] = useState({}),
        // ============== ACTION ==============
        actionUpload = ActionUpload(),
        actionCreatePost = ActionCreatePost(),
        actionGetItemPost = ActionGetItemPost(),
        actionUpdatePost = ActionUpdatePost(),
        getInitAction = ActionInit(),
        actionUploadInitStore = ActionUploadInitStore(),
        // ============== SELECT DATA ==============
        itemFetching = useSelector(selectFetching()),
        itemDetail = useSelector(selectPostItem()),
        itemIsUpload = useSelector(selectFetchingUpload()),
        itemFileUpload = useSelector(selectFilesUpload());

    useEffect(() => {
        const {match} = props;
        if (Object.keys(match.params).length > 0) {
            setIsUpdate(true);
            if (Object.keys(itemDetail).length < 1) {
                setTypePage(TAG_DEFINE.ACTION.update)
                actionGetItemPost({id: match.params?.id, props: props});
            }
        } else {
            setIsUpdate(false)
        }
    }, [props.match]);

    useEffect(() => {
        setLoading(itemIsUpload)
    }, [itemIsUpload]);

    useEffect(() => {
        setImageUrl(itemFileUpload?.file_url)
        form.setFieldsValue({
            [CONSTANT.ACTION_FIELD_POST.thumbnail]: itemFileUpload?.file_url
        })
    }, [itemFileUpload]);

    useEffect(() => {
        return () => {
            getInitAction();
            actionUploadInitStore();
        }
    }, []);

    useEffect(() => {
        setIsFetching(itemFetching)
    }, [itemFetching]);

    useEffect(() => {
        if (Object.keys(itemDetail).length > 0) {
            form.setFieldsValue(itemDetail)
            setImageUrl(itemDetail[CONSTANT.ACTION_FIELD_POST.thumbnail])
        }
    }, [itemDetail]);

    const onFinish = (val) => {
        if (isUpdate) {
            actionUpdatePost({...val, props, id: itemDetail?.id})
        } else {
            actionCreatePost({...val, props})
        }
    }

    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setFiles({})
            setLoading(true)
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl => {
                    setLoading(false);
                    setImageUrl(imageUrl)
                }
            );
        }
    };

    return (
        <>
            <HeaderAction title={`Exclusive Content ${TAG_DEFINE.ACTION[typePage.toLowerCase()]}`}/>
            <Spin tip="Loading..." spinning={isFetching}>
                <div className="container bg-white mt-4 p-4">
                    <div className="row">
                        <div className="col-12">
                            <Form
                                form={form}
                                layout="vertical"
                                onFinish={onFinish}
                                // onFinishFailed={onFinishFailed}
                                autoComplete="off"
                                initialValues={{
                                    status: 0
                                }}
                            >
                                <div className="row">
                                    <div className="col-10">
                                        <div className="row">
                                            <div className="col-4">
                                                <Form.Item
                                                    label="Title"
                                                    name={CONSTANT.ACTION_FIELD_POST.title}
                                                    rules={[{required: true}]}
                                                >
                                                    <Input/>
                                                </Form.Item>
                                            </div>
                                            <div className="col-4">
                                                <Form.Item
                                                    label="Video Url"
                                                    name={CONSTANT.ACTION_FIELD_POST.video_url}
                                                    rules={[{required: true}]}
                                                >
                                                    <Input/>
                                                </Form.Item>
                                            </div>
                                            <div className="col-4">
                                                <Form.Item
                                                    label="Status"
                                                    name={CONSTANT.ACTION_FIELD_POST.status}
                                                    rules={[{required: true}]}
                                                >
                                                    <Select
                                                        className="w-100"
                                                        allowClear
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        <Select.Option
                                                            value={1}>{CONSTANT_RESPONSE.STATUS[1]}</Select.Option>
                                                        <Select.Option
                                                            value={0}>{CONSTANT_RESPONSE.STATUS[0]}</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className="col-6">
                                                <Form.Item
                                                    label="Description"
                                                    name={CONSTANT.ACTION_FIELD_POST.description}
                                                >
                                                    <Input.TextArea/>
                                                </Form.Item>
                                            </div>
                                            <div className="col-6">
                                                <Form.Item
                                                    label="Footer Description"
                                                    name={CONSTANT.ACTION_FIELD_POST.footer_description}
                                                >
                                                    <Input.TextArea/>
                                                </Form.Item>
                                            </div>
                                            <div className="col-4 clearfix">
                                                <Form.Item
                                                    className="mb-0 float-start me-2"
                                                    label=""
                                                    name={CONSTANT.ACTION_FIELD_POST.footer_hot}
                                                    valuePropName="checked"
                                                >
                                                    <Checkbox>Footer Hot</Checkbox>
                                                </Form.Item>
                                                <Form.Item
                                                    className="mb-0 float-start"
                                                    label=""
                                                    name={CONSTANT.ACTION_FIELD_POST.footer_icon}
                                                    valuePropName="checked"
                                                >
                                                    <Checkbox>Footer Icon</Checkbox>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-2 ps-5">
                                        <Form.Item
                                            className="d-none"
                                            name={CONSTANT.ACTION_FIELD_POST.thumbnail}
                                        >
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item
                                            label="Thumbnail"
                                            className="mb-0"
                                            rules={[{required: true}]}
                                            name="uploadFile"
                                        >
                                            <Upload
                                                name="file"
                                                listType="picture-card"
                                                className="avatar-uploader h-100 profile--upload mt-3"
                                                showUploadList={false}
                                                action={(files) => actionUpload({files})}
                                                customRequest={() => {
                                                }}
                                                // beforeUpload={beforeUpload}
                                                onChange={handleChange}
                                            >
                                                {imageUrl ?
                                                    <img src={imageUrl} alt="avatar" style={{width: '100%'}}/> : (
                                                        <div className="text-center">
                                                            {loading ? <>
                                                                <LoadingOutlined/>
                                                                <div style={{marginTop: 8}}>Uploading</div>
                                                            </> : (
                                                                <div>
                                                                    <PlusOutlined/>
                                                                    <div
                                                                        style={{
                                                                            marginTop: 8,
                                                                        }}
                                                                    >
                                                                        Upload
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                            </Upload>
                                        </Form.Item>
                                    </div>
                                </div>
                                <Form.Item className="text-end">
                                    <Button type="default" htmlType="button" className="me-3"
                                            onClick={() => props.history.push(PERMISSION.EXCLUSIVE_CONTENT.VIEW.route, {group: PERMISSION.EXCLUSIVE_CONTENT.VIEW.group})}>
                                        Back
                                    </Button>
                                    <Button type="primary" htmlType="submit">
                                        Save {isFetching && <Icon type="sync-outlined" spin/>}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            </Spin>
        </>
    )
}

export default Index
