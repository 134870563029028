import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/SubscriptionGeoData';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/subscriptionGeoDataService';
import * as FunctionAction from '../actions/subscriptionGeoDataAction';
import {message} from "antd";
import CustomerRequest from "../mapping/Request/CustomerRequest";
import TAG_DEFINE from "../../constants/common";
import PERMISSION from "../../constants/permission";
import SubscriptionGeoDataRequest, {filtersParams} from "../mapping/Request/SubscriptionGeoDataRequest";

function* actionGetListPackages() {
    yield takeLatest(Types.SUBSCRIPTION_GEO_DATA_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = parseListRequestParams(filtersParams(action.filters || {}));
        const result = yield call(FunctionService.getListSubscriptionGeoData, params);
        yield put(FunctionAction.subscriptionGeoDataListSuccess(result));
    }, errorHandle(Types.SUBSCRIPTION_GEO_DATA_FAILED)))
}

// function* actionGetItem() {
//     yield takeLatest(Types.SUBSCRIPTION_GEO_DATA_GET_TEM_ACTION, Func.sagaWrapper(function* (action) {
//         const result = yield call(FunctionService.getItem, action.params.id);
//         yield put(FunctionAction.subscriptionPackageGetItemSuccess(result));
//     }, errorHandle(Types.SUBSCRIPTION_GEO_DATA_FAILED)))
// }
//
// function* actionGetMasterPackages() {
//     yield takeLatest(Types.SUBSCRIPTION_GEO_DATA_MASTER_ACTION, Func.sagaWrapper(function* (action) {
//         const result = yield call(FunctionService.masterData, action.params);
//         yield put(FunctionAction.subscriptionPackageMasterDataSuccess(result));
//     }, errorHandle(Types.SUBSCRIPTION_GEO_DATA_FAILED)))
// }

// function* createAction() {
//     yield takeLatest(Types.SUBSCRIPTION_GEO_DATA_CREATE_ACTION, Func.sagaWrapper(function* (action) {
//         const data = new CustomerRequest(action.params).exportCreate();
//         const result = yield call(FunctionService.create, data);
//         message.success(TAG_DEFINE.VALIDATION.statusCode.code["201"]);
//         action.params.props.history.push(PERMISSION.SUBSCRIPTION_GEO_DATA.VIEW.route, {group: PERMISSION.SUBSCRIPTION_GEO_DATA.VIEW.group});
//         // yield put({ type: Types.SUBSCRIPTION_GEO_DATA_LIST_ACTION });
//     }, errorHandle(Types.SUBSCRIPTION_GEO_DATA_FAILED)))
// }

function* updateAction() {
    yield takeLatest(Types.SUBSCRIPTION_GEO_DATA_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new SubscriptionGeoDataRequest(action.params).exportUpdate();
        yield call(FunctionService.update, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
        // yield put({ type: Types.HOTEL_GET_ITEM_ACTION });
        // yield put({ type: Types.VOUCHER_FAIL });
        yield put(FunctionAction.subscriptionGeoDataListAction());
        // yield put(VoucherActions.listVoucherAction(action.params.filters || {}));
    }, errorHandle(Types.SUBSCRIPTION_GEO_DATA_FAILED)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* subscriptionGeoDataSaga() {
    yield all([
        fork(actionGetListPackages),
        // fork(actionGetMasterPackages),
        // fork(createAction),
        // fork(actionGetItem),
        fork(updateAction),
    ])
}
