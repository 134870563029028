import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/Configuration';
import * as Func from "../../utils/functions";
// import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/configurationService';
import * as FunctionAction from '../actions/configurationAction';
import {message} from "antd";
// import ConfigurationRequest from "../mapping/Request/ConfigurationRequest";
import TAG_DEFINE from "../../constants/common";

function* actionConfigurationCma() {
    yield takeLatest(Types.CONFIGURATION_CMA_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.getConfigurationCma, action?.params);
        yield put(FunctionAction.configurationCmaSuccess(result));
    }, errorHandle(Types.CONFIGURATION_FAILED)))
}

function* actionGetItem() {
    yield takeLatest(Types.CONFIGURATION_GET_TEM_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.getItem, action.params.id);
        yield put(FunctionAction.configurationGetItemSuccess(result));
    }, errorHandle(Types.CONFIGURATION_FAILED)))
}

function* actionGetMasterConfiguration() {
    yield takeLatest(Types.CONFIGURATION_MASTER_DATA_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.masterData, action.params);
        yield put(FunctionAction.configurationMasterDataSuccess(result));
    }, errorHandle(Types.CONFIGURATION_FAILED)))
}

function* updateCmaAction() {
    yield takeLatest(Types.CONFIGURATION_UPDATE_CMA_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.updateCma, action?.params?.query);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["201"]);
        // action.params.props.history.push(PERMISSION.CONFIGURATION.VIEW.route, {group: PERMISSION.CONFIGURATION.VIEW.group});
        yield put({ type: Types.CONFIGURATION_CMA_ACTION });
    }, errorHandle(Types.CONFIGURATION_FAILED)))
}

function* updateAction() {
    yield takeLatest(Types.CONFIGURATION_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        // const data = new ConfigurationRequest(action.params).exportUpdate();
        // yield call(FunctionService.update, data);
        // message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
        // yield put(FunctionAction.configurationGetItemAction({id: action.params.id}));
    }, errorHandle(Types.CONFIGURATION_FAILED)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* configurationSaga() {
    yield all([
        fork(actionConfigurationCma),
        fork(updateCmaAction),
        fork(actionGetMasterConfiguration),
        fork(actionGetItem),
        fork(updateAction),
    ])
}
