import * as Types from '../types/SubscriptionCustomPackage';
import {PAGINATION} from '../../constants/define';
// import HotelRequest from "../mapping/Request/HotelRequest";

const initialState = {
    isFetching: false,
    statusAction: {
        isCreate: false,
    },
    all: [],
    errors: [],
    masterData: {},
    // item: new HotelRequest().update(),
    item: {},
    data: {
        result: [],
        page_size: PAGINATION.page_size,
        page_index: PAGINATION.page_index,
        total: PAGINATION.total,
        total_page: PAGINATION.total_page,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case Types.SUBSCRIPTION_CUSTOM_PACKAGE_LIST_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.SUBSCRIPTION_CUSTOM_PACKAGE_LIST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: action.subscriptionCustomPackages,
                statusAction: initialState.statusAction
            };
        case Types.SUBSCRIPTION_CUSTOM_PACKAGE_MASTER_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.SUBSCRIPTION_CUSTOM_PACKAGE_MASTER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                masterData: action.masterData,
                statusAction: initialState.statusAction
            };
        case Types.SUBSCRIPTION_CUSTOM_PACKAGE_GET_TEM_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.SUBSCRIPTION_CUSTOM_PACKAGE_GET_TEM_SUCCESS:
            return {...state, isFetching: false, item: action.item, statusAction: initialState.statusAction};
        case Types.SUBSCRIPTION_CUSTOM_PACKAGE_UPDATE_ACTION:
            return {...state, isFetching: true, statusAction: initialState.statusAction};
        case Types.SUBSCRIPTION_CUSTOM_PACKAGE_UPDATE_SUCCESS:
            return {...state, isFetching: false, statusAction: initialState.statusAction};
        case Types.SUBSCRIPTION_CUSTOM_PACKAGE_CREATE_ACTION:
            return {
                ...state, isFetching: true, statusAction: {
                    ...initialState.statusAction,
                    isCreate: true
                }
            };
        case Types.SUBSCRIPTION_CUSTOM_PACKAGE_CREATE_SUCCESS:
            return {
                ...state, isFetching: false, statusAction: {
                    ...initialState.statusAction,
                    isCreate: false
                }
            };
        case Types.SUBSCRIPTION_CUSTOM_PACKAGE_FAILED:
            return {...state, isFetching: false, errors: action.errors, statusAction: initialState.statusAction};
        case Types.SUBSCRIPTION_CUSTOM_PACKAGE_INIT:
            return initialState;
        default:
            return state;
    }
}
