import React, {Component} from 'react';
import {connect} from "react-redux";
import {Switch, Route} from 'react-router-dom';
import routes from '../routers';
import {loginAction} from "../data/actions/authAction";
import {
    Icon
} from './common';

// import "../assets/pages/login.scss"

// import '../assets/layout/css/pages/login-register-lock.css'

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingLogin: true,
            email: "",
            password: "",
            checkAuth: false,
        }
    }

    componentDidMount() {
        // const signUpButton = document.getElementById('signUp');
        // const signInButton = document.getElementById('signIn');
        // const container = document.getElementById('container');
        //
        // signUpButton.addEventListener('click', () => {
        //     container.classList.add("right-panel-active");
        // });
        //
        // signInButton.addEventListener('click', () => {
        //     container.classList.remove("right-panel-active");
        // });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps) {
            this.setState({checkAuth: nextProps.checkAuth.logging})
        }
    }

    onChange = (e) => {
        var target = e.target;
        var name = target.name;
        var value = target.value;
        this.setState({
            [name]: value
        });
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.login();
    };

    login() {
        this.props.onLogin(this.state);
        this.setState({fetchingLogin: false})
    }

    render() {
        const {email, password, fetchingLogin, checkAuth} = this.state;
        return (
            <section className="m-auto" style={{
                width: "400px"
            }}>
                <div className="login-register mt-5"
                     style={{backgroundImage: 'url(./layout/assets/images/background/login-register.jpg)'}}>
                    <div className="login-box card ">
                        <div className="card-body">
                            <form className="form-horizontal form-material" id="loginform" method="POST"
                                  onSubmit={this.onSubmit}>
                                <h3 className="box-title m-b-20">Sign In</h3>
                                <div className="form-group ">
                                    <div className="col-xs-12">
                                        <input className="form-control" type="email" value={email} name="email" required
                                               placeholder="Email" onChange={this.onChange}/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="col-xs-12">
                                        <input className="form-control" type="password" required name="password"
                                               value={password} onChange={this.onChange}
                                               placeholder="Password"/></div>
                                </div>
                                <div className="form-group text-center">
                                    <button className="btn btn-primary bg--primary-blur border-color--primary-blur"
                                             style={{
                                                 width: "200px"
                                             }}
                                            disabled={checkAuth} type="submit">Log
                                        In {checkAuth && <Icon type="sync-outlined" spin/>}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        // categories: state.reducerCategory,
        checkAuth: state.authReducer,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogin: (params) => {
            dispatch(loginAction(params))
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

