import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {formatInt} from "../../../utils/functions";

export default class UserRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.first_name = data?.first_name || "";
        this.last_name = data?.last_name || "";
        this.email = data?.email || "";
        this.roles = data?.roles || [];
        this.password = data?.password || "";
        this.status = formatInt(data?.status) || 0;
    }

    exportCreate(){
        return {
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            roles: this.roles,
            password: this.password,
        }
    }

    exportUpdate(){
        return {
            id: this.id,
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            roles: this.roles,
            password: this.password,
            status: this.status,
        }
    }

}

export const common = {
    ACTION_FIELD: {
        first_name: "first_name",
        last_name: "last_name",
        email: "email",
        roles: "roles",
        status: "status",
        password: "password",
    },
}
