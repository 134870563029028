import React, {Component} from 'react';
import {Redirect, Route} from "react-router-dom";
import connect from "react-redux/es/connect/connect";

import Login from "./Login";

const LoginRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => {
        const {authentication} = rest;
        return !authentication.logged
            ?
            <Login>
                <Component {...props} />
            </Login>
            :
            <Redirect to={{pathname: '/', state: {from: rest.location}}}/>
    }}/>
);
const mapStateToProps = state => ({
    authentication: state.authReducer,
});

export default connect(mapStateToProps)(LoginRoute)
