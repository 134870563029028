import React, {Component} from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';
import {PATHS} from '../constants/define';
import Layout from './Main';
import LoginRoute from './LoginRoute';
import PrivateRoute from './PrivateRoute';
import PERMISSION from '../constants/permission';
import {verifyRouteAction} from "../data/actions/verifyAction";
import {connect} from "react-redux";

import '../assets/main.scss';

class MainCheck extends Component {

    componentDidMount() {
        // this.props.onVerifyRoute();
    }

    render() {
        return (
            <Route>
                <Switch>
                    <LoginRoute path="/login" component={Layout.Login}/>
                    <PrivateRoute path={PERMISSION.HOME.VIEW.route} group={PERMISSION.HOME.VIEW.group}
                                  exact component={Layout.Index}/>
                    <PrivateRoute path={PERMISSION.DATA.VIEW.route} group={PERMISSION.DATA.VIEW.group} exact
                                  component={Layout.DataIndex}/>
                    <PrivateRoute path={PERMISSION.USER.VIEW.route} group={PERMISSION.USER.VIEW.group} exact
                                  component={Layout.UserIndex}/>
                    <PrivateRoute path={PERMISSION.CUSTOMER.VIEW.route} group={PERMISSION.CUSTOMER.VIEW.group} exact
                                  component={Layout.CustomerIndex}/>
                    <PrivateRoute path={PERMISSION.USER.CREATE.route} group={PERMISSION.USER.CREATE.group} exact
                                  component={Layout.UserAction}/>
                    <PrivateRoute path={PERMISSION.USER.UPDATE.route} withParams group={PERMISSION.USER.UPDATE.group}
                                  exact
                                  component={Layout.UserAction}/>
                    <PrivateRoute path={PERMISSION.CUSTOMER.UPDATE.route} withParams
                                  group={PERMISSION.CUSTOMER.UPDATE.group} exact
                                  component={Layout.CustomerAction}/>
                    <PrivateRoute path={PERMISSION.CUSTOMER.CREATE.route} withParams
                                  group={PERMISSION.CUSTOMER.CREATE.group} exact
                                  component={Layout.CustomerAction}/>
                    <PrivateRoute
                        path={PERMISSION.CUSTOMER.TICKET_LIST.route}
                        group={PERMISSION.CUSTOMER.TICKET_LIST.group}
                        exact
                        component={Layout.TicketIndex}/>
                    <PrivateRoute
                        path={PERMISSION.CUSTOMER.TICKET_UPDATE.route}
                        group={PERMISSION.CUSTOMER.TICKET_UPDATE.group}
                        exact
                        component={Layout.TicketAction}/>
                    <PrivateRoute path={PERMISSION.CUSTOMER.TEAM_POINT.route}
                                  group={PERMISSION.CUSTOMER.TEAM_POINT.group}
                                  exact
                                  component={Layout.CustomerTeamPoint}/>
                    <PrivateRoute path={PERMISSION.COMPANY.VIEW.route}
                                  group={PERMISSION.COMPANY.VIEW.group}
                                  exact
                                  component={Layout.CompanyList}/>
                    <PrivateRoute path={PERMISSION.COMPANY.CREATE.route}
                                  group={PERMISSION.COMPANY.CREATE.group}
                                  exact
                                  component={Layout.CompanyAction}/>
                    <PrivateRoute path={PERMISSION.COMPANY.UPDATE.route} withParams
                                  group={PERMISSION.COMPANY.UPDATE.group} exact
                                  component={Layout.CompanyAction}/>

                    <PrivateRoute path={PERMISSION.SUBSCRIPTION.CUSTOMER_VIEW.route}
                                  group={PERMISSION.SUBSCRIPTION.CUSTOMER_VIEW.group}
                                  exact
                                  component={Layout.SubscriptionCustomerList}/>

                    <PrivateRoute path={PERMISSION.SUBSCRIPTION.PACKAGE_VIEW.route}
                                  group={PERMISSION.SUBSCRIPTION.PACKAGE_VIEW.group}
                                  exact
                                  component={Layout.SubscriptionPackageList}/>
                    <PrivateRoute path={PERMISSION.SUBSCRIPTION.PACKAGE_UPDATE.route}
                                  withParams
                                  group={PERMISSION.SUBSCRIPTION.PACKAGE_UPDATE.group}
                                  exact
                                  component={Layout.SubscriptionPackageAction}/>
                    <PrivateRoute path={PERMISSION.SUBSCRIPTION.TRANSACTION_VIEW.route}
                                  group={PERMISSION.SUBSCRIPTION.TRANSACTION_VIEW.group}
                                  exact
                                  component={Layout.SubscriptionTransactionList}/>
                    <PrivateRoute path={PERMISSION.USER.VIEW.route} group={PERMISSION.USER.VIEW.group} exact
                                  component={Layout.UserIndex}/>
                    <PrivateRoute path={PERMISSION.USER.CREATE.route} group={PERMISSION.USER.CREATE.group} exact
                                  component={Layout.UserAction}/>
                    <PrivateRoute path={PERMISSION.USER.UPDATE.route} withParams group={PERMISSION.USER.UPDATE.group}
                                  exact
                                  component={Layout.UserAction}/>
                    <PrivateRoute path={PERMISSION.SUBSCRIPTION.CUSTOMER_RECURRING.route}
                                  group={PERMISSION.SUBSCRIPTION.CUSTOMER_RECURRING.group}
                                  exact
                                  component={Layout.SubscriptionCustomerRecurring}/>
                    <PrivateRoute path={PERMISSION.SUBSCRIPTION.CUSTOMER_RECURRING_ACTION.route} withParams
                                  group={PERMISSION.SUBSCRIPTION.CUSTOMER_RECURRING_ACTION.group} exact
                                  component={Layout.SubscriptionCustomerRecurringAction}/>
                    <PrivateRoute path={PERMISSION.SUBSCRIPTION.CUSTOMER_RECURRING_UPDATE.route}
                                  withParams
                                  group={PERMISSION.SUBSCRIPTION.CUSTOMER_RECURRING_UPDATE.group}
                                  exact
                                  component={Layout.SubscriptionCustomerRecurringAction}/>
                    <PrivateRoute path={PERMISSION.CONFIGURATION.VIEW.route}
                                  group={PERMISSION.CONFIGURATION.VIEW.group}
                                  exact
                                  component={Layout.ConfigurationList}/>
                    <PrivateRoute path="/403" group="errorPages" exact component={Layout.Error403}/>
                    <Route path='' group="errorPages" exact={false} component={Layout.Error}/>
                </Switch>
            </Route>

        );
    }
}

const mapStateToProps = state => ({
    authentication: state.authReducer,
    verifyAction: state.verifyReducer,
});

const mapDispatchToProps = (dispatch) => {
    return {
        // onVerifyRoute: (params) => {
        //     dispatch(verifyRouteAction(params));
        // },
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainCheck));

