import React, {useState} from "react";
import {Link, withRouter} from "react-router-dom";
import routers from "../../../routers";
import {find} from "lodash";
import clsx from "clsx";
import PERMISSION from "../../../constants/permission";

const MenuItem = (props) => {
    const {data, setNumberMenu, path} = props;
    let group = ((data?.items || []).length > 0) ? ((data?.items[0]?.route || "").split(".")[0]) : "";
    let getMenu = find(routers, {group: group});
    // const collectRouters = collectChildrenParentToArray(routers);
    return (
        <li
            className="nav-item"
        >
            <h3 className="menu-title color--primary">{data.name}</h3>
            <ul className="nav sub-menu">
                {(getMenu?.sub_menu || []).length > 0 &&
                getMenu.sub_menu
                    .map((item, key) => {
                        let customItem = find(data.items, {route: `${group}.${item?.permission_key}`});
                        if (item?.show && customItem) {
                            return <li className={clsx({
                                'nav-item': true,
                                'active': path === item.path
                            })} key={key}>
                                {item.is_redirect ? (
                                    <a href="#" onClick={() => window.location.replace(item.url)}>
                                        {customItem?.name}{" "}
                                    </a>
                                ) : (
                                    <Link to={{ pathname: item.path, state: {group: group} }}>{customItem?.name}</Link>
                                )}
                            </li>
                        } else {
                            return null
                        }
                    })}
            </ul>
        </li>
    );
};

const Navigation = (props) => {
    const [numberMenu, setNumberMenu] = useState(-1);
    const {infoUser, match, menu} = props;
    // const {path, infoUser, match} = this.props;
    const path = match.path;
    return (
        <div className="ydl--main-panel">
            <ul className="nav flex-column">
                {/*<li*/}
                {/*    className="nav-item"*/}
                {/*>*/}
                {/*    <h3 className="menu-title">Dashboard</h3>*/}
                {/*    <ul className="nav sub-menu">*/}
                {/*        <li className={clsx({*/}
                {/*            'nav-item': true,*/}
                {/*            'active': path === "/"*/}
                {/*        })}>*/}
                {/*            <Link to={{pathname: PERMISSION.DATA.VIEW.route, state: PERMISSION.DATA.VIEW.group}}>Home</Link>*/}
                {/*        </li>*/}
                {/*    </ul>*/}
                {/*</li>*/}
                {!!menu.length &&
                // {!!routers.length &&
                menu
                    // .filter((item, key) => {
                    //
                    //     !!item.sub_menu && (item.sub_menu = item.sub_menu.filter((sub_menu) => {
                    //         if(Object.keys(infoUser).length > 0){
                    //             if( typeof infoUser.rights[sub_menu.permission_route] === 'undefined' )  {
                    //                 return false;
                    //             }
                    //             const permissions = infoUser.rights[sub_menu.permission_route] || [];
                    //             const hasPermission = permissions.some((perm) => sub_menu.permission_value.includes(perm));
                    //             return  !!sub_menu.show && !!permissions.length && hasPermission;
                    //         }
                    //     }));
                    //
                    //     return (
                    //         !!item.exact &&
                    //         !!item.is_title &&
                    //         !!item.permission_value &&
                    //         !!item.sub_menu.length &&
                    //         _.difference(
                    //             Object.keys(infoUser.rights, item.permission_value)
                    //         )
                    //     );
                    // })
                    .map((item, key) => (
                        <MenuItem
                            key={key}
                            data={item}
                            numberMene={numberMenu}
                            setNumberMenu={setNumberMenu}
                            path={path}
                        />
                    ))}
            </ul>
        </div>
    );
};

export default withRouter(Navigation);
