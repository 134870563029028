import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/subscriptionGeneralPackageAction'

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        () => dispatch(HookAction.subscriptionGeneralPackageInit()),
        [dispatch]
    )
}

export const ActionGetListSubscriptionGeneralPackage = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.subscriptionGeneralPackageListAction(filters)),
        [dispatch]
    )
}

export const ActionGetItem = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.subscriptionGeneralPackageGetItemAction(params)),
        [dispatch]
    )
}

// export const ActionMasterData = () => {
//     const dispatch = useDispatch()
//     return useCallback(
//         (params) => dispatch(HookAction.subscriptionPackageMasterDataAction(params)),
//         [dispatch]
//     )
// }

// export const ActionCreatePackage = () => {
//     const dispatch = useDispatch()
//     return useCallback(
//         (params) => dispatch(HookAction.subscriptionPackageCreateAction(params)),
//         [dispatch]
//     )
// }

export const ActionUpdateGeneralPackage = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.subscriptionGeneralPackageUpdateAction(params)),
        [dispatch]
    )
}
