import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";

export default class SubscriptionPackageResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.type = data?.type || "";
        this.period_type = data?.period_type || "";
        this.package_type = data?.package_type || "";
        this.discount = data?.discount || "";
        this.price = data?.price || 0;
        this.product_type = data?.product_type || "";
        this.created_date = data?.created_date || "";
    }

    exportList() {
        return {
            id: this.id,
            type: this.type,
            period_type: this.period_type,
            package_type: this.package_type,
            discount: this.discount,
            price: this.price,
            product_type: this.product_type,
            created_date: this?.created_date ? moment(this?.created_date).format(DATE_TIME_FORMAT.EN_DATE_TIME) : "",
        }
    }

    exportItem() {
        return {
            id: this.id,
            type: this.type,
            period_type: this.period_type,
            package_type: this.package_type,
            discount: this.discount,
            created_date: this?.created_date ? moment(this?.created_date).format(DATE_TIME_FORMAT.EN_DATE_TIME) : "",
        }
    }
}

export class MasterPackageResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.status = data?.status || {};
        this.lead_type = data?.lead_type || [];
        this.state = data?.state || []
    }

    exportListMaster() {
        return {
            status: this.status,
            lead_type: this.lead_type,
            state: this.state,
        }
    }
}

export const listCols = ["product_type", "type", "period_type", "package_type", "discount", "price", "created_date",];
export const constants = {
    status: ["Inactive", "Active"]
};
