import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import SubscriptionTransactionResponse, {MasterTransactionResponse} from "../mapping/Response/SubscriptionTransactionResponse";

export function getListSubscriptionTransactions(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.SUBSCRIPTION_TRANSACTION_LIST,
        params: params,
        parser: dataParser,
    }).get();
}

export function masterData() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.SUBSCRIPTION_TRANSACTION_MASTER_DATA,
        // params: removeObjectNull({ city_id }),
        parser: data => new MasterTransactionResponse(data.data.result).exportListMaster(),
    }).get();
}

function dataParser(data = {}) {
    return {
        ...data.data,
        result: (data.data.result || []).map(item => new SubscriptionTransactionResponse(item).exportList()),
        total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
    }
}
