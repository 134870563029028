import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/configurationAction'

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        () => dispatch(HookAction.configurationInit()),
        [dispatch]
    )
}

export const ActionConfigurationCma = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.configurationCmaAction(filters)),
        [dispatch]
    )
}

export const ActionGetItem = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.configurationGetItemAction(params)),
        [dispatch]
    )
}

export const ActionMasterData = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.configurationMasterDataAction(params)),
        [dispatch]
    )
}

export const ActionUpdateCmaConfiguration = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.configurationUpdateCmaAction(params)),
        [dispatch]
    )
}

export const ActionUpdateCompany = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.configurationUpdateAction(params)),
        [dispatch]
    )
}
