export const SUBSCRIPTION_CUSTOM_PACKAGE_LIST_ACTION = "SUBSCRIPTION_CUSTOM_PACKAGE_LIST_ACTION";
export const SUBSCRIPTION_CUSTOM_PACKAGE_LIST_SUCCESS = "SUBSCRIPTION_CUSTOM_PACKAGE_LIST_SUCCESS";

export const SUBSCRIPTION_CUSTOM_PACKAGE_MASTER_ACTION = "SUBSCRIPTION_CUSTOM_PACKAGE_MASTER_ACTION";
export const SUBSCRIPTION_CUSTOM_PACKAGE_MASTER_SUCCESS = "SUBSCRIPTION_CUSTOM_PACKAGE_MASTER_SUCCESS";

export const SUBSCRIPTION_CUSTOM_PACKAGE_GET_TEM_ACTION = "SUBSCRIPTION_CUSTOM_PACKAGE_GET_TEM_ACTION";
export const SUBSCRIPTION_CUSTOM_PACKAGE_GET_TEM_SUCCESS = "SUBSCRIPTION_CUSTOM_PACKAGE_GET_TEM_SUCCESS";

export const SUBSCRIPTION_CUSTOM_PACKAGE_UPDATE_ACTION = "SUBSCRIPTION_CUSTOM_PACKAGE_UPDATE_ACTION";
export const SUBSCRIPTION_CUSTOM_PACKAGE_UPDATE_SUCCESS = "SUBSCRIPTION_CUSTOM_PACKAGE_UPDATE_SUCCESS";

export const SUBSCRIPTION_CUSTOM_PACKAGE_CREATE_ACTION = "SUBSCRIPTION_CUSTOM_PACKAGE_CREATE_ACTION";
export const SUBSCRIPTION_CUSTOM_PACKAGE_CREATE_SUCCESS = "SUBSCRIPTION_CUSTOM_PACKAGE_CREATE_SUCCESS";

export const SUBSCRIPTION_CUSTOM_PACKAGE_INIT = "SUBSCRIPTION_CUSTOM_PACKAGE_INIT";
export const SUBSCRIPTION_CUSTOM_PACKAGE_FAILED = "SUBSCRIPTION_CUSTOM_PACKAGE_FAILED";
