import * as Types from '../types/Data';

export const dataImportAction = (params) => ({type: Types.DATA_IMPORT_ACTION, params});
export const dataImportSuccess = (dataImport) => ({type: Types.DATA_IMPORT_SUCCESS, dataImport});

export const dataTruncateAction = (params) => ({type: Types.DATA_TRUNCATE_ACTION, params});
export const dataTruncateSuccess = (truncate) => ({type: Types.DATA_TRUNCATE_SUCCESS, truncate});

export const dataMasterLeadTypeAction = (params) => ({type: Types.DATA_MASTER_LEAD_TYPE_ACTION, params});
export const dataMasterLeadTypeSuccess = (leadTypes) => ({type: Types.DATA_MASTER_LEAD_TYPE_SUCCESS, leadTypes});

export const dataInit = (params) => ({type: Types.DATA_INIT, params});
export const dataFail = (params) => ({type: Types.DATA_FAILED, params});
