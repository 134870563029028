import {createSelector} from 'reselect'

const stateReducer = state => state.dashboardReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        dashboardReducer => dashboardReducer.isFetching
    )

export const selectUserActivity = (initItems) =>
    createSelector(
        stateReducer,
        dashboardReducer => dashboardReducer.userActivity
    )

export const selectCityFilter = (initItems) =>
    createSelector(
        stateReducer,
        dashboardReducer => dashboardReducer.cityFilter
    )
