import React from 'react'
import {connect} from 'react-redux'
import {Redirect, Route, withRouter} from 'react-router-dom'
import ContainerIndex from "./ContainerIndex";
import MessageCode from "../constants/messageCode";
import Component403 from "./403";
import {checkUserAction, logoutAction, refreshTokenAction} from "../data/actions/authAction";
import {verifyRouteAction} from "../data/actions/verifyAction";

const PrivateRoute = ({component: Component, ...rest}) => (
        <Route {...rest} render={props => {
            const {authentication, verifyAction} = rest;
            if (rest.redirect) {
                return <Redirect to={{pathname: rest.redirect}}/>
            }
            if (authentication.logged) {
                let errors = verifyAction.errors;
                if (errors.length > 0) {
                    if (errors[0].code === MessageCode.VERIFY_ROUTE.ACCESS_DENIED) {
                        return (
                            <ContainerIndex group="errorPages">
                                <Component403 {...props}/>
                            </ContainerIndex>
                        )
                    }
                } else {
                    return (
                        <ContainerIndex group={rest.group} withParams={rest.withParams}>
                            <Component {...props} />
                        </ContainerIndex>
                    )
                }
            } else {
                return <Redirect to={{pathname: '/login', state: {from: rest.location}}}/>
            }
        }}
        />
    )
;

const mapStateToProps = state => ({
    authentication: state.authReducer,
    verifyAction: state.verifyReducer,
});

const mapDispatchToProps = (dispatch) => {
    return {
        // onVerifyRoute: (params) => {
        //     dispatch(verifyRouteAction(params));
        // },
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateRoute));
