import * as Types from '../types/Ticket';

export const ticketListAction = (filters) => ({type: Types.TICKET_LIST_ACTION, filters});
export const ticketListSuccess = (tickets) => ({type: Types.TICKET_LIST_SUCCESS, tickets});

export const ticketCommentAction = (params) => ({type: Types.TICKET_COMMENT_ACTION, params});
export const ticketCommentSuccess = (commentTicket) => ({type: Types.TICKET_COMMENT_SUCCESS, commentTicket});

export const ticketUpdateAction = (params) => ({type: Types.TICKET_UPDATE_ACTION, params});
export const ticketUpdateSuccess = (updateTicket) => ({type: Types.TICKET_UPDATE_SUCCESS, updateTicket});

export const ticketMasterDataAction = (params) => ({type: Types.TICKET_MASTER_DATA_ACTION, params});
export const ticketMasterDataSuccess = (masterData) => ({type: Types.TICKET_MASTER_DATA_SUCCESS, masterData});

export const ticketGetItemAction = (params) => ({type: Types.TICKET_GET_TEM_ACTION, params});
export const ticketGetItemSuccess = (item) => ({type: Types.TICKET_GET_TEM_SUCCESS, item});

export const ticketInit = (refreshToken) => ({type: Types.TICKET_INIT, refreshToken});
export const ticketFail = (refreshToken) => ({type: Types.TICKET_FAILED, refreshToken});
