import {createSelector} from 'reselect'

const stateReducer = state => state.userReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        userReducer => userReducer.isFetching
    )

export const selectErrors = (initItems) =>
    createSelector(
        stateReducer,
        userReducer => userReducer.errors
    )

export const selectListUser = (initItems) =>
    createSelector(
        stateReducer,
        userReducer => userReducer.data
    )

export const selectMasterData = (initItems) =>
    createSelector(
        stateReducer,
        userReducer => userReducer.masterData
    )
export const selectUserItem = (initItems) =>
    createSelector(
        stateReducer,
        userReducer => userReducer.item
    )
