import * as Types from '../types/Company';

export const companyListAction = (filters) => ({type: Types.COMPANY_LIST_ACTION, filters});
export const companyListSuccess = (company) => ({type: Types.COMPANY_LIST_SUCCESS, company});
export const companyCreateAction = (params) => ({type: Types.COMPANY_CREATE_ACTION, params});
export const companyCreateSuccess = (createCompany) => ({type: Types.COMPANY_CREATE_SUCCESS, createCompany});
export const companyUpdateAction = (params) => ({type: Types.COMPANY_UPDATE_ACTION, params});
export const companyUpdateSuccess = (updateCompany) => ({type: Types.COMPANY_UPDATE_SUCCESS, updateCompany});
export const companyMasterDataAction = (params) => ({type: Types.COMPANY_MASTER_DATA_ACTION, params});
export const companyMasterDataSuccess = (masterData) => ({type: Types.COMPANY_MASTER_DATA_SUCCESS, masterData});
export const companyGetItemAction = (params) => ({type: Types.COMPANY_GET_TEM_ACTION, params});
export const companyGetItemSuccess = (item) => ({type: Types.COMPANY_GET_TEM_SUCCESS, item});
export const companyInit = (params) => ({type: Types.COMPANY_INIT, params});
export const companyFail = (params) => ({type: Types.COMPANY_FAILED, params});
