import ApiService from './../../utils/ApiService';
import Urls from './../../utils/Urls';
// import Token from './../models/Token';
import User from './../../data/mapping/User';


export function login(email, password, captcha){
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.LOGIN,
        // parser: (data) => new Token(data),
    }).post({email, password});
}

export function logout(){
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.LOGOUT,
    }).post({});
}

export function getUserProfile(){
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.PROFILE,
        parser: data => new User(data.data.result),
    }).get();
}

export function update(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.UPDATE_PROFILE,
        endpointParams: {id: data?.id},
    }).put(data);
}

export function refreshToken(refreshToken){
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.REFRESH,
        withoutAuth: true,
        // parser: (data) => new Token(data),
    }).post({refreshToken});
}
