import React, {useState, useEffect} from "react";
import {useSelector} from 'react-redux'

// ============== LAYOUT ==============
import {Form, Input, Button, Select, Spin, Tooltip} from 'antd';
import HeaderAction from "../../../../common/layout/HeaderAction";

// ============== ACTION ==============
import {
    ActionMasterData,
    ActionInit,
    ActionUpdatePackage,
    ActionGetItem
} from "../../../../../data/hooks/subscriptionPackage";

// ============== SELECT DATA ==============
// import {selectMasterData, selectUserItem} from "../../../../../data/reselects/userSelector";
// import {selectFetching, selectCustomerItem} from "../../../../../data/reselects/customerSelector";
import {
    selectFetching,
    selectSubscriptionPackageItem,
    selectMasterData
} from "../../../../../data/reselects/subscriptionPackageSelector";
import {common} from "../../../../../data/mapping/Request/SubscriptionPackageRequest";
import {Icon} from "../../../../common";
import {isEmail} from "../../../../../utils/validation";
import PERMISSION from "../../../../../constants/permission";
import {CopyOutlined} from "@ant-design/icons";
import {formatInt} from "../../../../../utils/functions";
import TAG_DEFINE from "../../../../../constants/common";

const Index = (props) => {
    const [form] = Form.useForm(),
        [isFetching, setIsFetching] = useState(false),
        [masterRoles, setMasterRoles] = useState({}),
        [masterStatus, setMasterStatus] = useState({}),
        [isUpdate, setIsUpdate] = useState(false),
        // ============== ACTION ==============
        getMasterData = ActionMasterData(),
        // getCreateUser = ActionCreateUser(),
        getUpdateSubscriptionPackage = ActionUpdatePackage(),
        getInitAction = ActionInit(),
        getItem = ActionGetItem(),
        // ============== SELECT DATA ==============
        itemFetching = useSelector(selectFetching()),
        itemDetail = useSelector(selectSubscriptionPackageItem()),
        itemMasterData = useSelector(selectMasterData());

    useEffect(() => {
        const {match} = props;
        if (Object.keys(match.params).length > 0) {
            setIsUpdate(true);
            if (Object.keys(itemDetail).length < 1) {
                getItem({id: match.params?.id, props: props});
            }
        } else {
            setIsUpdate(false)
        }
    }, [props.match]);

    useEffect(() => {
        if (Object.keys(itemMasterData).length < 1) {
            getMasterData()
        }

        return () => {
            getInitAction();
        }
    }, []);

    useEffect(() => {
        setIsFetching(itemFetching)
    }, [itemFetching]);

    useEffect(() => {
        if (Object.keys(itemDetail).length > 0) {
            form.setFieldsValue(itemDetail)
        }
    }, [itemDetail]);

    useEffect(() => {
        if (Object.keys(itemMasterData?.roles || {}).length > 0) {
            setMasterRoles(itemMasterData?.roles)
        }
        if (Object.keys(itemMasterData?.status || {}).length > 0) {
            setMasterStatus(itemMasterData?.status)
        }
    }, [itemMasterData]);

    const onFinish = (val) => {
        if (isUpdate) {
            getUpdateSubscriptionPackage({...val, props, id: itemDetail?.id})
        } else {
            // getCreateUser({...val, props})
        }
    }

    return (
        <>
            <HeaderAction title="Subscription Package Update" disabledFormatUpperCaseTitle/>
            <Spin tip="Loading..." spinning={isFetching}>
                <div className="container bg-white mt-4 p-4">
                    <div className="row">
                        <div className="col-12">
                            <Form
                                form={form}
                                layout="vertical"
                                onFinish={onFinish}
                                // onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <div className="row">
                                    <div className="col-4">
                                        <Form.Item
                                            label="Lead Type"
                                            name={common.ACTION_FIELD.lead_type}
                                        >
                                            <Input disabled/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="State"
                                            name={common.ACTION_FIELD.state}
                                        >
                                            <Input disabled/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="County"
                                            name={common.ACTION_FIELD.county}
                                        >
                                            <Input disabled/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Standard price"
                                            name={common.ACTION_FIELD.standard_price}
                                            rules={[{required: true}, {
                                                type: "number",
                                                min: 0,
                                                transform: value => formatInt(value, -1),
                                                message: TAG_DEFINE.VALIDATION.statusCode.code["15"].replace('%s', 0),
                                            }]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Standard sale price"
                                            name={common.ACTION_FIELD.standard_sale_price}
                                            rules={[{required: true}, {
                                                type: "number",
                                                min: 0,
                                                transform: value => formatInt(value, -1),
                                                message: TAG_DEFINE.VALIDATION.statusCode.code["15"].replace('%s', 0),
                                            }]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Premium price"
                                            name={common.ACTION_FIELD.premium_price}
                                            rules={[{required: true}, {
                                                type: "number",
                                                min: 0,
                                                transform: value => formatInt(value, -1),
                                                message: TAG_DEFINE.VALIDATION.statusCode.code["15"].replace('%s', 0),
                                            }]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Premium sale price"
                                            name={common.ACTION_FIELD.premium_sale_price}
                                            rules={[{required: true}, {
                                                type: "number",
                                                min: 0,
                                                transform: value => formatInt(value, -1),
                                                message: TAG_DEFINE.VALIDATION.statusCode.code["15"].replace('%s', 0),
                                            }]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Status"
                                            name={common.ACTION_FIELD.status}
                                            rules={[{required: true, message: 'Please select your status!'}]}
                                        >
                                            <Select
                                                className="w-100"
                                                style={{width: 120}}>
                                                {
                                                    (Object.keys(masterStatus)).length > 0 && (Object.keys(masterStatus)).map((i, k) => {
                                                        return (
                                                            <Select.Option value={masterStatus[i]}
                                                                           key={k}>{i}</Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                                <Form.Item className="text-end">
                                    <Button type="default" htmlType="button" className="me-3"
                                            onClick={() => props.history.push(PERMISSION.SUBSCRIPTION.PACKAGE_VIEW.route, {group: PERMISSION.SUBSCRIPTION.PACKAGE_VIEW.group})}>
                                        Back
                                    </Button>
                                    <Button type="primary" htmlType="submit">
                                        Save {isFetching && <Icon type="sync-outlined" spin/>}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            </Spin>
        </>
    )
}

export default Index
