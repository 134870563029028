import {createSelector} from 'reselect'

const stateReducer = state => state.subscriptionGeoDataReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        subscriptionGeoDataReducer => subscriptionGeoDataReducer.isFetching
    )

export const selectErrors = (initItems) =>
    createSelector(
        stateReducer,
        subscriptionGeoDataReducer => subscriptionGeoDataReducer.errors
    )

export const selectListSubscriptionGeoData = (initItems) =>
    createSelector(
        stateReducer,
        subscriptionGeoDataReducer => subscriptionGeoDataReducer.data
    )

export const selectMasterData = (initItems) =>
    createSelector(
        stateReducer,
        subscriptionGeoDataReducer => subscriptionGeoDataReducer.masterData
    )
export const selectSubscriptionPackageItem = (initItems) =>
    createSelector(
        stateReducer,
        subscriptionGeoDataReducer => subscriptionGeoDataReducer.item
    )
