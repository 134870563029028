import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/SubscriptionCancelationFeedback';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/subscriptionCancelationFeedbackService';
import * as FunctionAction from '../actions/subscriptionCancelationFeedbackAction';
import {message} from "antd";
// import CancelationFeedbackRequest from "../mapping/Request/CancelationFeedbackRequest";
import TAG_DEFINE from "../../constants/common";
import PERMISSION from "../../constants/permission";

// import SubscriptionCancelationFeedbackRequest, {filtersParams} from "../mapping/Request/SubscriptionCancelationFeedbackRequest";

function* actionGetListCancelationFeedbacks() {
    yield takeLatest(Types.SUBSCRIPTION_CANCELATION_FEEDBACK_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = parseListRequestParams(action.filters);
        const result = yield call(FunctionService.getListSubscriptionCancelationFeedback, params);
        yield put(FunctionAction.subscriptionCancelationFeedbackListSuccess(result));
    }, errorHandle(Types.SUBSCRIPTION_CANCELATION_FEEDBACK_FAILED)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* subscriptionCancelationFeedbackSaga() {
    yield all([
        fork(actionGetListCancelationFeedbacks),
    ])
}
