import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {checkNumber, formatInt, removeObjectNullFull} from "../../../utils/functions";

export default class TicketRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.status = data?.status || "";
        this.type = data?.type || "";
        this.assignee = data?.assignee_id || "";
        this.message = data?.message || "";
    }

    exportUpdate() {
        return {
            id: this.id,
            status: this.status,
            type: this.type,
            assignee: this.assignee,
        }
    }

    exportComment() {
        return {
            id: this.id,
            message: this.message,
        }
    }
}

export const filtersParams = (filters) => {
    return removeObjectNullFull({
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        search: filters?.search || "",
        status: checkNumber(filters?.status) ? filters?.status : "",
        order_field: filters?.order_field || "",
        order_value: filters?.order_value || "",
    })
}

export const common = {
    ACTION_FIELD: {
        code: "code",
        title: "title",
        type: "type",
        user_id: "user_id",
        user_first_name: "user_first_name",
        user_last_name: "user_last_name",
        assignee_id: "assignee_id",
        assignee_first_name: "assignee_first_name",
        assignee_last_name: "assignee_last_name",
        status: "status",
        status_explain: "status_explain",
        comments: "comments",
        created_at: "created_at",
        updated_at: "updated_at",
        messages: "messages",
        owner: "owner",
        assignee: "assignee",
    },
}

