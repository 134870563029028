import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/companyAction'

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        () => dispatch(HookAction.companyInit()),
        [dispatch]
    )
}

export const ActionGetListCompany = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.companyListAction(filters)),
        [dispatch]
    )
}

export const ActionGetItem = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.companyGetItemAction(params)),
        [dispatch]
    )
}

export const ActionMasterData = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.companyMasterDataAction(params)),
        [dispatch]
    )
}

export const ActionCreateCompany = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.companyCreateAction(params)),
        [dispatch]
    )
}
export const ActionUpdateCompany = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.companyUpdateAction(params)),
        [dispatch]
    )
}
