import {Button, DatePicker, Form, Input, message, Modal, Select, Spin, Tag} from "antd";
import React, {useEffect, useState} from "react";
import {Icon} from "../../../../common";
import moment from "moment"
import {common} from "../../../../../data/mapping/Request/UserRequest";
import {isEmail} from "../../../../../utils/validation";
import {constants} from "../../../../../data/mapping/Response/SubscriptionCustomPackageResponse";

const AddSubscription = props => {
    const [form] = Form.useForm();
    const [listMonths, setListMonths] = useState([]),
        [masterCity, setMasterCity] = useState([]),
        [masterMetro, setMasterMetro] = useState([]),
        [masterCounty, setMasterCounty] = useState([]);

    useEffect(() => {
        if (props.isOpenCreate) {
            form.resetFields();
        }
    }, [props.isOpenCreate]);


    const onCreate = val => {
        props.actionCreate(val)
    }

    const onValuesChange = e => {
        if (e?.state) {
            setMasterCity(props.masterData?.data[e?.state]?.city)
            setMasterMetro(props.masterData?.data[e?.state]?.metro)
            setMasterCounty(props.masterData?.data[e?.state]?.county)
            form.setFieldsValue({
                metro: "",
                county: "",
                city: ""
            })
        } else if (e?.city) {
            form.setFieldsValue({
                metro: "",
                county: ""
            })
        } else if (e?.metro) {
            form.setFieldsValue({
                city: "",
                county: ""
            })
        } else if (e?.county) {
            form.setFieldsValue({
                metro: "",
                city: ""
            })
        }
    }

    return (
        <Modal
            width={1000}
            title="Add Custom Package"
            destroyOnClose
            visible={props.isOpenCreate}
            onOk={() => form.submit()}
            onCancel={() => props.setIsOpenCreate(false)}
            maskClosable={false}
        >
            <Spin spinning={props.itemIsAction}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onCreate}
                    onValuesChange={onValuesChange}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <div className="row bg-white align-items-center">
                        <div className="col-6">
                            <Form.Item
                                label="State"
                                name="state"
                                // rules={[{required: true, message: "Customer is required"}]}
                            >
                                <Select
                                    className="w-100"
                                    allowClear
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        ((props.masterData?.state || [])).length > 0 && ((props.masterData?.state || [])).map((i, k) => {
                                            return (
                                                <Select.Option value={i}
                                                               key={k}>{i}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-6">
                            <Form.Item
                                label="City"
                                name="city"
                                // rules={[{required: true, message: "Customer is required"}]}
                            >
                                <Select
                                    disabled={masterCity.length < 1}
                                    className="w-100"
                                    allowClear
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        ((masterCity || [])).length > 0 && ((masterCity || [])).map((i, k) => {
                                            return (
                                                <Select.Option value={i}
                                                               key={k}>{i}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-6">
                            <Form.Item
                                label="Metro"
                                name="metro"
                                // rules={[{required: true, message: "Customer is required"}]}
                            >
                                <Select
                                    disabled={masterMetro.length < 1}
                                    className="w-100"
                                    allowClear
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        ((masterMetro || [])).length > 0 && ((masterMetro || [])).map((i, k) => {
                                            return (
                                                <Select.Option value={i}
                                                               key={k}>{i}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-6">
                            <Form.Item
                                label="County"
                                name="county"
                                // rules={[{required: true, message: "Customer is required"}]}
                            >
                                <Select
                                    disabled={masterCounty.length < 1}
                                    className="w-100"
                                    allowClear
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        ((masterCounty || [])).length > 0 && ((masterCounty || [])).map((i, k) => {
                                            return (
                                                <Select.Option value={i}
                                                               key={k}>{i}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-6">
                            <Form.Item
                                label="Period Type"
                                name="period_type"
                                // rules={[{required: true, message: "Customer is required"}]}
                            >
                                <Select
                                    className="w-100"
                                    allowClear
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        (Object?.keys(props?.masterData?.period_type || {})).length > 0 && (Object?.keys(props?.masterData?.period_type || {})).map((i, k) => {
                                            return (
                                                <Select.Option value={props?.masterData?.period_type[i]}
                                                               key={k}>{i}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-6">
                            <Form.Item
                                label="Package Type"
                                name="package_type"
                                // rules={[{required: true, message: "Customer is required"}]}
                            >
                                <Select
                                    className="w-100"
                                    allowClear
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        (Object?.keys(props?.masterData?.package_type || {})).length > 0 && (Object?.keys(props?.masterData?.package_type || {})).map((i, k) => {
                                            return (
                                                <Select.Option value={props?.masterData?.package_type[i]}
                                                               key={k}>{i}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-6">
                            <Form.Item
                                name="price"
                                label="Price"
                            >
                                <Input/>
                            </Form.Item>
                        </div>
                        <div className="col-6">
                            <Form.Item
                                label="Status"
                                name="status"
                                rules={[{required: true, message: "Status is required"}]}
                            >
                                <Select
                                    className="w-100"
                                    allowClear
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        ((constants?.status || [])).length > 0 && ((constants?.status || [])).map((i, k) => {
                                            return (
                                                <Select.Option value={k}
                                                               key={k}>{i}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </Spin>
        </Modal>
    )
}

export default AddSubscription
