import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {
    columnsTable,
    genaratePaginateFilterSort,
    getDataSource,
    getTitleTable,
    removeObjectNull, removeObjectNullFull
} from "../../../utils/functions";
import {omit, truncate} from "lodash";
import TAG_DEFINE from "../../../constants/common";
import {PATHS} from "../../../constants/define";
import PERMISSION from "../../../constants/permission";

// ============== LAYOUT ==============
import HeaderAction from "../../common/layout/HeaderAction";
import {Icon} from "../../common";
import {ListComponent} from "../../common/ListComponent";
import {ListSearchFreeTextComponent} from "../../../utils/commonUtils";

// ============== ACTION ==============
import {ActionGetListSubscriptionCancelationFeedback} from '../../../data/hooks/subscriptionCancelationFeedback';
import {Tooltip, Button, Tag, Form, Input, Select, Modal} from 'antd'

// ============== SELECT DATA ==============
import {
    selectFetching,
    selectErrors,
    selectListSubscriptionCancelationFeedback,
} from '../../../data/reselects/subscriptionCancelationFeedbackSelector';
import SubscriptionCancelationFeedbackResponse, {listCols} from "../../../data/mapping/Response/SubscriptionCancelationFeedbackResponse";
import {common} from "../../../data/mapping/Request/UserRequest";


const ModelFields = Object.getOwnPropertyNames(new SubscriptionCancelationFeedbackResponse());
const Index = props => {
    const namePage = "subscription_cancelation_feedback",
        [initData, setInitData] = useState({
            titleItemTable: getTitleTable(listCols, ['action'], []),
            modelItem: ModelFields,
        }),
        [paramsFilters, setPramsFilters] = useState({}),
        [form] = Form.useForm(),
        componentTable = {
            feedback: {
                render: (text, record) => truncate(text, {
                    'length': 100,
                    'separator': "..."
                })
            },
            action: {
                width: 100,
                align: 'center',
                key: 'action',
                fixed: 'right',
                render: (text, record) =>
                    <Icon
                        type="eye-outlined"
                        className="cursorPointer"
                        onClick={
                            () => {
                                setItemDetail(removeObjectNullFull(record));
                                setIsDetail(true)
                            }
                        }
                    />,
            }
        };
    const [isFetching, setIsFetching] = useState(false),
        [isDetail, setIsDetail] = useState(false),
        [itemDetail, setItemDetail] = useState({}),
        [resultList, setResultList] = useState([]),
        [pagination, setPagination] = useState({}),
        // ============== ACTION ==============
        getListCancelationFeedbacks = ActionGetListSubscriptionCancelationFeedback(),
        // ============== SELECT DATA ==============
        itemListCancelationFeedbacks = useSelector(selectListSubscriptionCancelationFeedback()),
        itemErrors = useSelector(selectErrors()),
        itemIsFetching = useSelector(selectFetching());
    useEffect(() => {
        if ((itemListCancelationFeedbacks?.result || []).length < 1) {
            getListCancelationFeedbacks();
        }
    }, []);

    useEffect(() => {
        setIsFetching(itemIsFetching)
    }, [itemIsFetching]);

    useEffect(() => {
        setResultList(itemListCancelationFeedbacks?.result || [])
        setPagination({
            total: itemListCancelationFeedbacks?.total || 0,
            totalPage: itemListCancelationFeedbacks?.total_page || 0,
            defaultCurrent: itemListCancelationFeedbacks?.page_index || 1,
            pageSize: itemListCancelationFeedbacks?.page_size || 10
        })
    }, [itemListCancelationFeedbacks]);

    const onTableChange = (pagination, filters, sorter) => {
        filters = omit({...paramsFilters, ...filters}, ['page_index', 'page_size']);
        let newFilters = genaratePaginateFilterSort(pagination, filters, sorter);
        setPramsFilters(newFilters)
        getListCancelationFeedbacks(newFilters);
    };

    const onSearch = (val) => {
        let getFilters = {...paramsFilters, ...val};
        setPramsFilters(getFilters)
        getListCancelationFeedbacks(removeObjectNullFull(getFilters));
    };

    return <>
        <HeaderAction title="Cancelation View"/>
        <Modal
            width={800}
            title="Cancelation View Detail"
            destroyOnClose
            visible={isDetail}
            onOk={() => {
                setItemDetail({})
                setIsDetail(false)
            }}
            onCancel={() => {
                setItemDetail({})
                setIsDetail(false)
            }}
            footer={null}>
            {
                (Object.keys(itemDetail).length > 0) && (
                    <div className="container">
                        {
                            Object.keys(itemDetail).map((i, k) => {
                                return (
                                    <div className="row mb-2" key={k}>
                                        <div className="col-2 fw-bold">
                                            {TAG_DEFINE.GENERAL.ITEM.SUBSCRIPTION_CANCELATION_FEEDBACK[i]}
                                        </div>
                                        <div className="col-9">
                                            {itemDetail[i]}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            }
        </Modal>
        <div className="container-fluid px-4">
            <Form
                form={form}
                layout="vertical"
                onFinish={onSearch}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className="row bg-white py-3 align-items-center">
                    <div className="col-5">
                        <Form.Item
                            label="Search"
                            name="search"
                        >
                            <Input allowClear/>
                        </Form.Item>
                    </div>
                    <div className="col-1">
                        <Form.Item className="text-end" label=" ">
                            <Button type="primary" htmlType="submit">
                                Search {isFetching && <Icon type="sync-outlined" spin/>}
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </div>
        <ListComponent
            scroll={{x: 1000}}
            classParent="m-0 p-0 col-md-12 mt-4"
            marginTop={'0'} marginBottom={'0'}
            loading={{spinning: isFetching, size: 'large'}}
            rowKey="id"
            dataSource={getDataSource(resultList || [], initData.modelItem)}
            columns={columnsTable({
                titleTable: initData.titleItemTable,
                component: componentTable,
                itemName: namePage
            })}
            pagination={{
                total: pagination.total,
                totalPage: pagination.totalPage,
                current: pagination.defaultCurrent,
                pageSize: pagination.pageSize
            }}
            onChange={onTableChange}
            componentTableList={{}}
        />
    </>
}

export default Index
