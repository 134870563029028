import React, {Component, useState} from "react";
import {Link, withRouter} from "react-router-dom";
import {Menu, Dropdown, Avatar, Modal, Form, Input, Button, message} from 'antd';
import {ActionUpdateProfile} from "../../../data/hooks/auth"
import {Icon} from "../index";
import {common} from "../../../data/mapping/Request/UserRequest";

const togglePageWrapper = (event) => {
    const pageWrapper = document.querySelector('.page-wrapper');
    !!pageWrapper && pageWrapper.classList.toggle('expanded');
}

const Header = (props) => {

    const [showProfile, setShowProfile] = useState(false),
        getUpdateProfile = ActionUpdateProfile();

    const menu = () => (
        <Menu>
            <Menu.Item key={0} onClick={() => setShowProfile(true)}>
                {(props.profile?.first_name || "") + " " + (props.profile?.last_name || "")}
            </Menu.Item>
            <Menu.Item key={1} onClick={() => props.onLogoutRequest()}>Logout</Menu.Item>
        </Menu>
    );

    const updateProfile = (val) => {
        if (val.password || val.confirm_password) {
            if (val.password === val.confirm_password) {
                getUpdateProfile({...val, id: props?.profile?.id})
            } else {
                message.error("Password does not match")
            }
        } else {
            getUpdateProfile({...val, id: props?.profile?.id})
        }
    }

    return (
        <header className="ydl--topbar topbar">
            <Modal title="My Profile" destroyOnClose visible={showProfile} onOk={() => setShowProfile(false)}
                   onCancel={() => setShowProfile(false)} footer={null}>
                <Form
                    layout="vertical"
                    initialValues={{
                        email: props.profile?.email,
                        first_name: props.profile?.first_name,
                        last_name: props.profile?.last_name,
                    }}
                    onFinish={updateProfile}
                    // onValuesChange={onRequiredTypeChange}
                    // requiredMark={requiredMark}
                >
                    <div className="row">
                        <div className="col-12">
                            <Form.Item label="Email" name="email">
                                <Input disabled/>
                            </Form.Item>
                        </div>
                        <div className="col-6">
                            <Form.Item
                                rules={[{required: true, message: 'Please input your first name'}]}
                                label="First Name"
                                name="first_name"
                            >
                                <Input disabled={props.authFetching}/>
                            </Form.Item>
                        </div>
                        <div className="col-6">
                            <Form.Item
                                rules={[{required: true, message: 'Please input your last name'}]}
                                label="Last Name"
                                name="last_name"
                            >
                                <Input disabled={props.authFetching}/>
                            </Form.Item>
                        </div>
                        <div className="col-6">
                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {type: "string", min: 6}, ({getFieldValue}) => ({
                                        validator(rule, value) {
                                            if (value) {
                                                if (!/^\s+|\s+$/g.test(value[0]) && !/^\s+|\s+$/g.test(value[value.length - 1])) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject("'password' cannot be empty");
                                            }
                                            return Promise.resolve();
                                        },
                                    })
                                ]}
                            >
                                <Input.Password disabled={props.authFetching}/>
                            </Form.Item>
                        </div>
                        <div className="col-6">
                            <Form.Item
                                label="Confirm Password"
                                name="confirm_password"
                                rules={[{type: "string", min: 6}, ({getFieldValue}) => ({
                                    validator(rule, value) {
                                        if (value) {
                                            if (!/^\s+|\s+$/g.test(value[0]) && !/^\s+|\s+$/g.test(value[value.length - 1])) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject("'password' cannot be empty");
                                        }
                                        return Promise.resolve();
                                    },
                                })]}
                            >
                                <Input.Password disabled={props.authFetching}/>
                            </Form.Item>
                        </div>
                        <div className="col-12">
                            <Form.Item className="text-end mb-0">
                                <Button type="primary" htmlType="submit" className="me-3 mb-0">
                                    Submit {props.authFetching && <Icon type="sync-outlined" spin/>}
                                </Button>
                                <Button htmlType="button"
                                        className="mb-0"
                                        onClick={() => setShowProfile(false)}>
                                    Cancel
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </Modal>
            <nav className="navbar navbar-expand-lg navbar-light justify-content-between">
                <a className="navbar--brand pt-3" href="#">
                    <img
                        src="/layout/logo.svg"
                        // width="177"
                        // height="20"
                        alt=""
                        className="navbar-brand-full d-none d-sm-block"
                        width="150px"
                    />
                    <img
                        src="/layout/logo.svg"
                        width="30"
                        height="30"
                        alt=""
                        className="navbar-brand-minimized d-block d-sm-none"
                    />
                </a>
                <button type="button" className="navbar--button-collapse" onClick={togglePageWrapper}>
                    <i className="fas fa-bars"></i>
                </button>
                <div className="navbar--nav ml-auto">
                    <Dropdown overlay={menu}>
                        <Avatar size={50} icon={<img style={{cursor: "pointer"}} width={50} height={50}
                                                     src={(props.profile?.first_name && props.profile?.last_name) ? `https://ui-avatars.com/api/?bold=true&background=ffffff&name=${props.profile?.first_name} ${props.profile?.last_name}` : "https://ui-avatars.com/api/?bold=true&background=ffffff&name=NF"}/>}/>
                    </Dropdown>
                    {/*<ul className="nav">*/}
                    {/*<li className="nav-item">*/}
                    {/*	<Notification />*/}
                    {/*</li>*/}
                    {/*<li className="nav-item">*/}
                    {/*	<MessageNotification />*/}
                    {/*</li>*/}
                    {/*<li className="nav-item">*/}
                    {/*    <a*/}
                    {/*        className="nav-link dropdown-toggle waves-effect waves-dark"*/}
                    {/*        href="#"*/}
                    {/*        data-toggle="dropdown"*/}
                    {/*        aria-haspopup="true"*/}
                    {/*        aria-expanded="false"*/}
                    {/*    >*/}
                    {/*        <img*/}
                    {/*            src="./layout/assets/images/users/1.jpg"*/}
                    {/*            alt="user"*/}
                    {/*            className="profile-pic"*/}
                    {/*        />*/}
                    {/*    </a>*/}
                    {/*<div className="dropdown-menu dropdown-menu-right animated profile-menu">*/}
                    {/*    <ul className="nav dropdown-user">*/}
                    {/*        <li className="nav-item">*/}
                    {/*            <a href="/" title="Username"*/}
                    {/*               className="user-name">{props.profile?.user?.username}</a>*/}
                    {/*        </li>*/}
                    {/*        <li className="nav-item">*/}
                    {/*            <a onClick={() => props.onLogoutRequest()} title="Logout"><i*/}
                    {/*                className="fa fa-power-off"/> Logout</a>*/}
                    {/*        </li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}
                    {/*    </li>*/}
                    {/*</ul>*/}
                </div>
            </nav>
        </header>
    );
}

export default withRouter(Header);
