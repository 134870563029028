import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/SubscriptionCustomPackage';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/subscriptionCustomPackageService';
import * as FunctionAction from '../actions/subscriptionCustomPackageAction';
import {message} from "antd";
import CustomerRequest from "../mapping/Request/CustomerRequest";
import TAG_DEFINE from "../../constants/common";
import PERMISSION from "../../constants/permission";
import SubscriptionCustomPackageRequest, {filtersParams} from "../mapping/Request/SubscriptionCustomPackageRequest";

function* actionGetListCustomPackages() {
    yield takeLatest(Types.SUBSCRIPTION_CUSTOM_PACKAGE_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = parseListRequestParams(filtersParams(action.filters || {}));
        const result = yield call(FunctionService.getListSubscriptionCustomPackage, params);
        yield put(FunctionAction.subscriptionCustomPackageListSuccess(result));
    }, errorHandle(Types.SUBSCRIPTION_CUSTOM_PACKAGE_FAILED)))
}

// function* actionGetItem() {
//     yield takeLatest(Types.SUBSCRIPTION_CUSTOM_PACKAGE_GET_TEM_ACTION, Func.sagaWrapper(function* (action) {
//         const result = yield call(FunctionService.getItem, action.params.id);
//         yield put(FunctionAction.subscriptionPackageGetItemSuccess(result));
//     }, errorHandle(Types.SUBSCRIPTION_CUSTOM_PACKAGE_FAILED)))
// }

function* actionGetMasterCustomPackages() {
    yield takeLatest(Types.SUBSCRIPTION_CUSTOM_PACKAGE_MASTER_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.masterData, action.params);
        yield put(FunctionAction.subscriptionCustomPackageMasterDataSuccess(result));
    }, errorHandle(Types.SUBSCRIPTION_CUSTOM_PACKAGE_FAILED)))
}

function* createAction() {
    yield takeLatest(Types.SUBSCRIPTION_CUSTOM_PACKAGE_CREATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new SubscriptionCustomPackageRequest(action.params).exportCreate();
        const result = yield call(FunctionService.create, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["201"]);
        // action.params.props.history.push(PERMISSION.SUBSCRIPTION.CUSTOM_PACKAGE.VIEW.route, {group: PERMISSION.SUBSCRIPTION.CUSTOM_PACKAGE.VIEW.group});
        yield put({type: Types.SUBSCRIPTION_CUSTOM_PACKAGE_LIST_ACTION});
    }, errorHandle(Types.SUBSCRIPTION_CUSTOM_PACKAGE_FAILED)))
}

function* updateAction() {
    yield takeLatest(Types.SUBSCRIPTION_CUSTOM_PACKAGE_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new SubscriptionCustomPackageRequest(action.params).exportUpdate();
        yield call(FunctionService.update, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
        // yield put({ type: Types.HOTEL_GET_ITEM_ACTION });
        // yield put({ type: Types.VOUCHER_FAIL });
        yield put(FunctionAction.subscriptionCustomPackageListAction({id: action.params.id}));
        // yield put(VoucherActions.listVoucherAction(action.params.filters || {}));
    }, errorHandle(Types.SUBSCRIPTION_CUSTOM_PACKAGE_FAILED)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* subscriptionCustomPackageSaga() {
    yield all([
        fork(actionGetListCustomPackages),
        fork(actionGetMasterCustomPackages),
        fork(createAction),
        // fork(actionGetItem),
        fork(updateAction),
    ])
}
