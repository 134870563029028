import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {
    columnsTable,
    genaratePaginateFilterSort,
    getDataSource,
    getTitleTable,
    removeObjectNull, removeObjectNullFull, setParamsUrlFilter
} from "../../../../utils/functions";
import {omit} from "lodash";
import TAG_DEFINE from "../../../../constants/common";
import {DATE_TIME_FORMAT, PATHS} from "../../../../constants/define";
import PERMISSION from "../../../../constants/permission";

// ============== LAYOUT ==============
import HeaderAction from "../../../common/layout/HeaderAction";
import {Icon} from "../../../common";
import {ListComponent} from "../../../common/ListComponent";
import {ListSearchFreeTextComponent} from "../../../../utils/commonUtils";

// ============== ACTION ==============
// import {ActionGetListUser, ActionInit, ActionMasterData} from '../../../../data/hooks/user';
import {ActionGetListCustomer} from '../../../../data/hooks/customer';
import {
    ActionGetListSubscriptionCustomer,
    ActionMasterData,
    ActionMasterState,
    ActionDelete,
    ActionCreate
} from '../../../../data/hooks/subscriptionCustomer';
import {Tooltip, Button, Tag, Form, Input, Select, DatePicker, Popconfirm} from 'antd'

// ============== SELECT DATA ==============
// import {selectListUser, selectMasterData} from '../../../../data/reselects/userSelector';
import {selectListCustomer} from '../../../../data/reselects/customerSelector';
import {
    selectFetching,
    selectErrors,
    selectListSubscriptionCustomer,
    selectMasterData,
    selectMasterState, selectIsAction,
} from '../../../../data/reselects/subscriptionCustomerSelector';
import SubscriptionCustomerResponse, {
    constants,
    listCols
} from "../../../../data/mapping/Response/SubscriptionCustomerResponse";
import {common} from "../../../../data/mapping/Request/UserRequest";
import AddSubscription from "./actions/AddSubscription";
import {filtersParams} from "../../../../data/mapping/Request/SubscriptionCustomerRequest";
import queryString from "query-string";
import moment from "moment";


const ModelFields = Object.getOwnPropertyNames(new SubscriptionCustomerResponse());
const Index = props => {
    const namePage = "subscription_customers",
        [initData, setInitData] = useState({
            titleItemTable: getTitleTable(listCols, ['action'], []),
            modelItem: ModelFields,
        }),
        [paramsFilters, setPramsFilters] = useState({}),
        [form] = Form.useForm(),
        componentTable = {
            action: {
                width: 100,
                align: 'center',
                key: 'action',
                fixed: 'right',
                render: (text, record) => {
                    return (
                        <>
                            {
                                record?.is_delete && (
                                    <Popconfirm
                                        title="Are you sure to delete this item?"
                                        onConfirm={e => onDelete(record?.id)}
                                        onCancel={e => {
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Icon type="delete" className="cursorPointer"/>
                                    </Popconfirm>
                                )
                            }
                        </>
                    )
                },
            }
        };
    const [isFetching, setIsFetching] = useState(false),
        [isCreate, setIsCreate] = useState(false),
        [isOpenCreate, setIsOpenCreate] = useState(false),
        [resultList, setResultList] = useState([]),
        [pagination, setPagination] = useState({}),
        // ============== ACTION ==============
        getListSubscriptionCustomers = ActionGetListSubscriptionCustomer(),
        actionDelete = ActionDelete(),
        getMasterData = ActionMasterData(),
        actionCreate = ActionCreate(),
        // ============== SELECT DATA ==============
        itemMasterState = useSelector(selectMasterState()),
        itemListSubscriptionCustomers = useSelector(selectListSubscriptionCustomer()),
        itemErrors = useSelector(selectErrors()),
        itemMasterData = useSelector(selectMasterData()),
        itemIsAction = useSelector(selectIsAction()),
        itemIsFetching = useSelector(selectFetching());

    useEffect(() => {
        if (Object.keys(queryString.parse(props.location.search)).length > 0) {
            let paramsFilters = queryString.parse(props.location.search, {arrayFormat: 'comma'});
            if (paramsFilters.valid_time_from) {
                paramsFilters.valid_time_from = moment(paramsFilters.valid_time_from)
            }
            if (paramsFilters.valid_time_to) {
                paramsFilters.valid_time_to = moment(paramsFilters.valid_time_to)
            }
            // paramsFilters = responseQueryParams(paramsFilters)
            setPramsFilters(paramsFilters)
            form.setFieldsValue(paramsFilters)
            getListSubscriptionCustomers(removeObjectNullFull(paramsFilters));
        } else {
            getListSubscriptionCustomers()
        }
        if (Object.keys(itemMasterData).length < 1) {
            getMasterData()
        }
    }, []);

    useEffect(() => {
        if ((itemErrors || []).length === 0) {
            setIsOpenCreate(itemIsAction)
        }
    }, [itemIsAction]);

    useEffect(() => {
        setIsFetching(itemIsFetching)
    }, [itemIsFetching]);

    useEffect(() => {
        setResultList(itemListSubscriptionCustomers?.result || [])
        setPagination({
            total: itemListSubscriptionCustomers?.total || 0,
            totalPage: itemListSubscriptionCustomers?.total_page || 0,
            defaultCurrent: itemListSubscriptionCustomers?.page_index || 1,
            pageSize: itemListSubscriptionCustomers?.page_size || 10
        })
    }, [itemListSubscriptionCustomers]);

    const onTableChange = (pagination, filters, sorter) => {
        // let currentFilters = getCurrentFilters();
        filters = omit({...paramsFilters, ...filters}, ['page_index', 'page_size']);
        let newFilters = genaratePaginateFilterSort(pagination, filters, sorter);
        setPramsFilters(newFilters)
        setInitData({
            ...initData,
            filters: newFilters
        });
        props.history.replace(setParamsUrlFilter(PERMISSION.SUBSCRIPTION.CUSTOMER_VIEW.route, filtersParams(newFilters)))
        getListSubscriptionCustomers(newFilters);
    };

    const onCreate = (e, data) => {
        if (e === TAG_DEFINE.ACTION_OPTIONS.UPDATE) {
            props.history.push((PERMISSION.CUSTOMER.UPDATE.route).replace(":id", data.id))
        } else {
            // props.history.push(PERMISSION.CUSTOMER.CREATE.route, {group: PERMISSION.CUSTOMER.CREATE.group})
            // props.history.push(PERMISSION.CUSTOMER.CREATE.route)
        }
    };

    const onSearch = (val) => {
        let getFilters = removeObjectNullFull({...initData.filters, ...paramsFilters, ...val, page_index: 1});
        setPramsFilters(getFilters)
        setInitData({...initData, filters: paramsFilters})
        props.history.replace(setParamsUrlFilter(PERMISSION.SUBSCRIPTION.CUSTOMER_VIEW.route, filtersParams(getFilters)))
        getListSubscriptionCustomers(removeObjectNullFull(getFilters));
    };

    const onDelete = id => {
        actionDelete({
            query: {id},
            filters: paramsFilters
        })
    }

    const onExport = () => {
        getListSubscriptionCustomers({is_export: 1});
    }

    return <>
        <HeaderAction title="Subscription Customer List" disabledFormatUpperCaseTitle isCreate
                      onClick={() => setIsOpenCreate(true)}/>
        <AddSubscription itemIsAction={itemIsAction}
                         itemErrors={itemErrors}
                         actionCreate={actionCreate}
                         masterData={itemMasterData}
                         isCreate={isCreate}
                         isOpenCreate={isOpenCreate}
                         setIsOpenCreate={(e) => setIsOpenCreate(e)}/>
        <div className="container-fluid px-4">
            <Form
                form={form}
                layout="vertical"
                onFinish={onSearch}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className="row bg-white py-3 align-items-center">
                    <div className="col-3">
                        <Form.Item
                            label="Search"
                            name="search"
                        >
                            <Input allowClear/>
                        </Form.Item>
                    </div>
                    <div className="col-3">
                        <Form.Item
                            label="Valid Time From"
                            name="valid_time_from"
                        >
                            <DatePicker format={DATE_TIME_FORMAT.SHORT_DATE_EXPORT} className="w-100"/>
                        </Form.Item>
                    </div>
                    <div className="col-3">
                        <Form.Item
                            label="Valid Time To"
                            name="valid_time_to"
                        >
                            <DatePicker format={DATE_TIME_FORMAT.SHORT_DATE_EXPORT} className="w-100"/>
                        </Form.Item>
                    </div>
                    <div className="col-1">
                        <Form.Item className="text-end" label=" ">
                            <Button type="primary" htmlType="submit">
                                Search {isFetching && <Icon type="sync-outlined" spin/>}
                            </Button>
                        </Form.Item>
                    </div>
                    <div className="col-12 text-end">
                        <Button type="primary" disabled={isFetching} htmlType="button" onClick={e => onExport()}>
                            Export {isFetching && <Icon type="sync-outlined" spin/>}
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
        <ListComponent
            scroll={{x: 2000}}
            classParent="m-0 p-0 col-md-12 mt-4"
            marginTop={'0'} marginBottom={'0'}
            loading={{spinning: isFetching, size: 'large'}}
            rowKey="id"
            dataSource={getDataSource(resultList || [], initData.modelItem)}
            columns={columnsTable({
                titleTable: initData.titleItemTable,
                component: componentTable,
                itemName: namePage
            })}
            pagination={{
                total: pagination.total,
                totalPage: pagination.totalPage,
                current: pagination.defaultCurrent,
                pageSize: pagination.pageSize
            }}
            onChange={onTableChange}
            componentTableList={{}}
        />
    </>
}

export default Index
