import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/subscriptionCustomPackageAction'

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        () => dispatch(HookAction.subscriptionCustomPackageInit()),
        [dispatch]
    )
}

export const ActionGetListSubscriptionCustomPackage = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.subscriptionCustomPackageListAction(filters)),
        [dispatch]
    )
}

export const ActionMasterData = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.subscriptionCustomPackageMasterDataAction(params)),
        [dispatch]
    )
}

export const ActionCreateCustomPackage = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.subscriptionCustomPackageCreateAction(params)),
        [dispatch]
    )
}

export const ActionUpdateCustomPackage = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.subscriptionCustomPackageUpdateAction(params)),
        [dispatch]
    )
}
