import * as Types from '../types/Dashboard';

export const dashboardUserActivityAction = (params) => ({type: Types.DASHBOARD_USER_ACTIVITY_ACTION, params});
export const dashboardUserActivitySuccess = (userActivity) => ({type: Types.DASHBOARD_USER_ACTIVITY_SUCCESS, userActivity});

export const dashboardCityFilterAction = (params) => ({type: Types.DASHBOARD_CITY_FILTER_ACTION, params});
export const dashboardCityFilterSuccess = (cityFilter) => ({type: Types.DASHBOARD_CITY_FILTER_SUCCESS, cityFilter});

export const dashboardAllAction = (params) => ({type: Types.DASHBOARD_ALL_ACTION, params});
export const dashboardAllSuccess = (all) => ({type: Types.DASHBOARD_ALL_SUCCESS, all});

export const dashboardInit = (params) => ({type: Types.DASHBOARD_INIT, params});
export const dashboardFail = (params) => ({type: Types.DASHBOARD_FAILED, params});
