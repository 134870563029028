import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";

export default class UserResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.email = data?.email || "";
        this.first_name = data?.first_name || "";
        this.last_name = data?.last_name || "";
        this.roles = data?.roles || [];
        this.status = data?.status+"" || "0";
        this.status_explain = data?.status_explain || "";
        this.created_at = data?.created_at ? moment(data?.created_at).format(DATE_TIME_FORMAT.SHORT_DATE_EXPORT) : "";
        this.updated_at = data?.updated_at || "";
    }

    exportList() {
        return {
            id: this.id,
            email: this.email,
            first_name: this.first_name,
            last_name: this.last_name,
            status: this.status,
            status_explain: this.status_explain,
            roles: this.roles,
            created_at: this.created_at,
        }
    }

    exportItem() {
        return {
            id: this.id,
            email: this.email,
            first_name: this.first_name,
            last_name: this.last_name,
            status: this.status,
            status_explain: this.status_explain,
            roles: this.roles,
        }
    }
}

export class MasterUserResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.ROLES = data?.ROLES || {};
        this.STATUS = data?.STATUS || {};
    }

    exportRoles(){
        return {
            roles: this.ROLES,
            status: this.STATUS
        }
    }
}

export const listCols = ['email', "first_name", "last_name", "roles", "created_at", "status"];
