import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {formatInt, removeObjectNullFull} from "../../../utils/functions";

export default class CustomerRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.first_name = data?.first_name || "";
        this.last_name = data?.last_name || "";
        this.email = data?.email || "";
        this.roles = data?.roles || [];
        this.password = data?.password || "";
        this.phone_number = data?.phone_number || "";
        this.account_manager = data?.account_manager || "";
        this.referral_id = data?.referral_id || "";
        this.status = formatInt(data?.status) || 0;
        this.is_bypass_multiple_login = data?.is_bypass_multiple_login ? 1 : 0;
    }

    exportCreate() {
        return {
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            // roles: this.roles,
            password: this.password,
            phone_number: this.phone_number,
            account_manager: this.account_manager,
            referral_id: this.referral_id,
        }
    }

    exportUpdate() {
        return {
            id: this.id,
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            roles: this.roles,
            password: this.password,
            status: this.status,
            phone_number: this.phone_number,
            account_manager: this.account_manager,
            referral_id: this.referral_id,
            is_bypass_multiple_login: this.is_bypass_multiple_login,
        }
    }

}

export const common = {
    ACTION_FIELD: {
        first_name: "first_name",
        last_name: "last_name",
        email: "email",
        roles: "roles",
        status: "status",
        is_bypass_multiple_login: "is_bypass_multiple_login",
        password: "password",
        phone_number: "phone_number",
        account_manager: "account_manager",
        referral: "referral_id",
        point: "point",
        cash: "cash",
        referral_code: "referral_code",
        membership_code: "membership_code",
    },
}

export const customerTeamPointFiltersParams = (filters) => {
    let newDateAdded = (filters?.date || []).length > 0 ? [moment(filters?.date[0]).format("YYYY-MM-DD"), moment(filters?.date[1]).format("YYYY-MM-DD")] : ["", ""]
    return removeObjectNullFull({
        from_date: newDateAdded[0],
        to_date: newDateAdded[1],
        search: filters?.search || "",
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
    })
}

