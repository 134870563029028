export const SUBSCRIPTION_GEO_DATA_LIST_ACTION = "SUBSCRIPTION_GEO_DATA_LIST_ACTION";
export const SUBSCRIPTION_GEO_DATA_LIST_SUCCESS = "SUBSCRIPTION_GEO_DATA_LIST_SUCCESS";

export const SUBSCRIPTION_GEO_DATA_MASTER_ACTION = "SUBSCRIPTION_GEO_DATA_MASTER_ACTION";
export const SUBSCRIPTION_GEO_DATA_MASTER_SUCCESS = "SUBSCRIPTION_GEO_DATA_MASTER_SUCCESS";

export const SUBSCRIPTION_GEO_DATA_GET_TEM_ACTION = "SUBSCRIPTION_GEO_DATA_GET_TEM_ACTION";
export const SUBSCRIPTION_GEO_DATA_GET_TEM_SUCCESS = "SUBSCRIPTION_GEO_DATA_GET_TEM_SUCCESS";
export const SUBSCRIPTION_GEO_DATA_UPDATE_ACTION = "SUBSCRIPTION_GEO_DATA_UPDATE_ACTION";
export const SUBSCRIPTION_GEO_DATA_UPDATE_SUCCESS = "SUBSCRIPTION_GEO_DATA_UPDATE_SUCCESS";

export const SUBSCRIPTION_GEO_DATA_INIT = "SUBSCRIPTION_GEO_DATA_INIT";
export const SUBSCRIPTION_GEO_DATA_FAILED = "SUBSCRIPTION_GEO_DATA_FAILED";
