import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import SubscriptionCustomPackageResponse, {
    MasterCustomPackageResponse
} from "../mapping/Response/SubscriptionCustomPackageResponse";

export function getListSubscriptionCustomPackage(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.SUBSCRIPTION_CUSTOM_PACKAGE_LIST,
        params: params,
        parser: dataParser,
    }).get();
}

export function masterData() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.SUBSCRIPTION_CUSTOM_PACKAGE_MASTER_DATA,
        // params: removeObjectNull({ city_id }),
        parser: data => new MasterCustomPackageResponse(data.data.result).exportListMaster(),
    }).get();
}

export function create(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.SUBSCRIPTION_CUSTOM_PACKAGE_CREATE,
    }).post(data);
}

export function update(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.SUBSCRIPTION_CUSTOM_PACKAGE_UPDATE,
        endpointParams: {id: encodeURIComponent(data?.id)},
    }).put(data);
}

// export function getItem(id) {
//     return new ApiService({
//         baseURL: process.env.REACT_APP_API_URL,
//         endpoint: Urls.SUBSCRIPTION_PACKAGE_DETAIL,
//         endpointParams: {id: encodeURIComponent(id)},
//         parser: data => new SubscriptionCustomPackageResponse(data.data.result).exportItem(),
//     }).get();
// }

function dataParser(data = {}) {
    return {
        ...data.data,
        result: (data.data.result || []).map(item => new SubscriptionCustomPackageResponse(item).exportList()),
        total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
    }
}
