export const DATA_IMPORT_ACTION = "DATA_IMPORT_ACTION";
export const DATA_IMPORT_SUCCESS = "DATA_IMPORT_SUCCESS";

export const DATA_TRUNCATE_ACTION = "DATA_TRUNCATE_ACTION";
export const DATA_TRUNCATE_SUCCESS = "DATA_TRUNCATE_SUCCESS";

export const DATA_MASTER_LEAD_TYPE_ACTION = "DATA_MASTER_LEAD_TYPE_ACTION";
export const DATA_MASTER_LEAD_TYPE_SUCCESS = "DATA_MASTER_LEAD_TYPE_SUCCESS";

export const DATA_INIT = "DATA_INIT";
export const DATA_FAILED = "DATA_FAILED";
