import * as Types from '../types/Dashboard';
import {PAGINATION} from "../../constants/define";

const initialState = {
    isTruncate: false,
    isFetching: false,
    errors: [],
    userActivity: {
        data: [],
        options: {},
    },
    cityFilter: {
        data: [],
        options: {},
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case Types.DASHBOARD_USER_ACTIVITY_ACTION:
            return {...state, errors: [], isFetching: true};
        case Types.DASHBOARD_USER_ACTIVITY_SUCCESS:
            return {...state, errors: [], isFetching: false, userActivity: action.userActivity};
        case Types.DASHBOARD_CITY_FILTER_ACTION:
            return {...state, errors: [], isFetching: true};
        case Types.DASHBOARD_CITY_FILTER_SUCCESS:
            return {...state, errors: [], isFetching: false, cityFilter: action.cityFilter};
        case Types.DASHBOARD_ALL_ACTION:
            return {...state, errors: [], isFetching: true};
        case Types.DASHBOARD_ALL_SUCCESS:
            return {
                ...state,
                errors: [],
                isFetching: false,
                cityFilter: action.all.cityFilter,
                userActivity: action.all.userActivity
            };
        case Types.DASHBOARD_INIT:
            return initialState;
        case Types.DASHBOARD_FAILED:
            return {...state, isFetching: false, errors: action.errors};
        default:
            return state;
    }
}
