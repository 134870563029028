export const EXCLUSIVE_CONTENT_POST_LIST_ACTION = "EXCLUSIVE_CONTENT_POST_LIST_ACTION";
export const EXCLUSIVE_CONTENT_POST_LIST_SUCCESS = "EXCLUSIVE_CONTENT_POST_LIST_SUCCESS";
export const EXCLUSIVE_CONTENT_POST_CREATE_ACTION = "EXCLUSIVE_CONTENT_POST_CREATE_ACTION";
export const EXCLUSIVE_CONTENT_POST_CREATE_SUCCESS = "EXCLUSIVE_CONTENT_POST_CREATE_SUCCESS";
export const EXCLUSIVE_CONTENT_POST_UPDATE_ACTION = "EXCLUSIVE_CONTENT_POST_UPDATE_ACTION";
export const EXCLUSIVE_CONTENT_POST_UPDATE_SUCCESS = "EXCLUSIVE_CONTENT_POST_UPDATE_SUCCESS";
export const EXCLUSIVE_CONTENT_POST_GET_TEM_ACTION = "EXCLUSIVE_CONTENT_POST_GET_TEM_ACTION";
export const EXCLUSIVE_CONTENT_POST_GET_TEM_SUCCESS = "EXCLUSIVE_CONTENT_POST_GET_TEM_SUCCESS";

export const EXCLUSIVE_CONTENT_LAYOUT_LIST_ACTION = "EXCLUSIVE_CONTENT_LAYOUT_LIST_ACTION";
export const EXCLUSIVE_CONTENT_LAYOUT_LIST_SUCCESS = "EXCLUSIVE_CONTENT_LAYOUT_LIST_SUCCESS";
export const EXCLUSIVE_CONTENT_LAYOUT_UPDATE_ACTION = "EXCLUSIVE_CONTENT_LAYOUT_UPDATE_ACTION";
export const EXCLUSIVE_CONTENT_LAYOUT_UPDATE_SUCCESS = "EXCLUSIVE_CONTENT_LAYOUT_UPDATE_SUCCESS";

export const EXCLUSIVE_CONTENT_INIT = "EXCLUSIVE_CONTENT_INIT";
export const EXCLUSIVE_CONTENT_FAILED = "EXCLUSIVE_CONTENT_FAILED";
