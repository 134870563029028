import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/ticketAction'

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        () => dispatch(HookAction.ticketInit()),
        [dispatch]
    )
}

export const ActionGetListTicket = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.ticketListAction(filters)),
        [dispatch]
    )
}

export const ActionGetItem = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.ticketGetItemAction(params)),
        [dispatch]
    )
}

export const ActionMasterData = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.ticketMasterDataAction(params)),
        [dispatch]
    )
}

export const ActionCommentTicket = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.ticketCommentAction(params)),
        [dispatch]
    )
}

export const ActionUpdateTicket = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.ticketUpdateAction(params)),
        [dispatch]
    )
}
