import * as Types from '../types/Configuration';

export const configurationCmaAction = (params) => ({type: Types.CONFIGURATION_CMA_ACTION, params});
export const configurationCmaSuccess = (configurationCma) => ({type: Types.CONFIGURATION_CMA_SUCCESS, configurationCma});
export const configurationUpdateCmaAction = (params) => ({type: Types.CONFIGURATION_UPDATE_CMA_ACTION, params});
export const configurationUpdateCmaSuccess = (updateCma) => ({type: Types.CONFIGURATION_UPDATE_CMA_SUCCESS, updateCma});
export const configurationUpdateAction = (params) => ({type: Types.CONFIGURATION_UPDATE_ACTION, params});
export const configurationUpdateSuccess = (updateCompany) => ({type: Types.CONFIGURATION_UPDATE_SUCCESS, updateCompany});
export const configurationMasterDataAction = (params) => ({type: Types.CONFIGURATION_MASTER_DATA_ACTION, params});
export const configurationMasterDataSuccess = (masterData) => ({type: Types.CONFIGURATION_MASTER_DATA_SUCCESS, masterData});
export const configurationGetItemAction = (params) => ({type: Types.CONFIGURATION_GET_TEM_ACTION, params});
export const configurationGetItemSuccess = (item) => ({type: Types.CONFIGURATION_GET_TEM_SUCCESS, item});
export const configurationInit = (params) => ({type: Types.CONFIGURATION_INIT, params});
export const configurationFail = (params) => ({type: Types.CONFIGURATION_FAILED, params});
