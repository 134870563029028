import React from 'react';
import {Select as AntSelect} from 'antd';
import './styles.less';
import {omit} from "lodash";
const Select = (props) => {

    return (
        <AntSelect
            {...omit(props, ["customOptions", "isCustomOption"])}
            className={`${props.className || ""}`}
        >
            {
                props.isCustomOption ? props.children : (props.customOptions && props.customOptions.length > 0) && props.customOptions.map((i, k) => {
                    return (
                        <AntSelect.Option value={i.value} key={k} params={i}>{i.name}</AntSelect.Option>
                    )
                })
            }
        </AntSelect>
    );
    // return (
    //     <AntSelect {...props} className={`${props.className || ""}`}>{props.children}</AntSelect>
    // )
};

export default Select;
export const SelectOption = AntSelect.Option;
