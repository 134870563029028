import React, {useState, useEffect, useRef} from 'react';
import {useSelector} from "react-redux";
import {
    capitalizeFirstLetter,
    columnsTable, formatInt,
    genaratePaginateFilterSort,
    getDataSource,
    getTitleTable, removeElementObject,
    removeObjectNull, removeObjectNullFull
} from "../../../../utils/functions";
import {filter} from "lodash";
import TAG_DEFINE from "../../../../constants/common";
import {PATHS} from "../../../../constants/define";
import PERMISSION from "../../../../constants/permission";

// ============== LAYOUT ==============
import HeaderAction from "../../../common/layout/HeaderAction";
import {Icon} from "../../../common";
import {ListComponent} from "../../../common/ListComponent";
import {ListSearchFreeTextComponent} from "../../../../utils/commonUtils";
import {
    CaretRightOutlined, CheckCircleOutlined,
    CheckOutlined,
    CloseOutlined,
    EditOutlined,
    MinusCircleOutlined,
    PlusOutlined,
    CloseCircleOutlined
} from "@ant-design/icons";

// ============== ACTION ==============
import {ActionGetListPost, ActionUpdateLayout, ActionGetListLayout} from '../../../../data/hooks/exclusiveContent';
import {Tooltip, Button, Tag, Form, Input, Select, Collapse, List, Space, Divider, Popconfirm, Spin} from 'antd'

// ============== SELECT DATA ==============
import {
    selectFetching,
    selectErrors,
    selectListPost,
    selectListLayout
} from '../../../../data/reselects/exclusiveContentSelector';
import ExclusiveContentResponse, {
    postListCols,
    CONSTANT
} from "../../../../data/mapping/Response/ExclusiveContentResponse";

const {Panel} = Collapse;
const ModelFields = Object.getOwnPropertyNames(new ExclusiveContentResponse());
const Index = props => {
    const namePage = "exclusive_content_post",
        [initData, setInitData] = useState({
            titleItemTable: getTitleTable(postListCols, ['action'], []),
            modelItem: ModelFields,
        }),
        [paramsFilters, setPramsFilters] = useState({}),
        [positionCollapse, setPositionCollapse] = useState([]),
        [positionTitle, setPositionTitle] = useState([]),
        [form] = Form.useForm(),
        [isFetching, setIsFetching] = useState(false),
        [resultList, setResultList] = useState([]),
        [countItemNumber, setCountItemNumber] = useState(0),
        [resultGroups, setResultGroups] = useState([]),
        inputRef = useRef(null),
        // ============== ACTION ==============
        actionGetListPost = ActionGetListPost(),
        actionGetListLayout = ActionGetListLayout(),
        actionUpdateLayout = ActionUpdateLayout(),
        // ============== SELECT DATA ==============
        itemListPost = useSelector(selectListPost()),
        itemListLayout = useSelector(selectListLayout()),
        itemErrors = useSelector(selectErrors()),
        itemIsFetching = useSelector(selectFetching());

    useEffect(() => {
        if ((itemListPost?.result || []).length < 1) {
            actionGetListPost({page_size: 1000});
        }
        actionGetListLayout();
    }, []);

    // useEffect(() => {
    //     if (positionTitle.length > 0) {
    //         inputRef.current.focus();
    //     }
    // }, [positionTitle]);
    useEffect(() => {
        if (Object.keys(itemListLayout || {}).length > 0) {
            form.resetFields()
            form.setFieldsValue(itemListLayout.layout.items)
            setResultList(itemListLayout?.layout?.resultList || [])
            setResultGroups(itemListLayout?.layout.resultGroup || [])
        }
    }, [itemListLayout]);

    useEffect(() => {
        setResultList(resultList)
    }, [resultList]);

    useEffect(() => {
        setIsFetching(itemIsFetching)
    }, [itemIsFetching]);

    const onCreate = (e, data) => {
        let getCount = (resultList[resultList.length - 1] || "").split("_"),
            setNumber = getCount ? formatInt(getCount[1]) + 1 : 0
        setResultList([...resultList, `item_${(Object.keys(itemListLayout.layout.items).length > 0) ? setNumber : resultList.length}`])
    };

    const onFinish = (val) => {
        // console.log("=====SUBMIT=======", val)
        actionUpdateLayout(val)
        setPositionTitle([]);
    }

    const onFormChange = val => {
        // console.log("========= CHANGE VALUE =========", val)
        // actionUpdateLayout(val)
    }

    const onCollapseTitle = key => {
        let oldListPosition = positionCollapse;
        if (oldListPosition.includes(key)) {
            oldListPosition = oldListPosition.filter(position => position !== key)
        } else {
            oldListPosition.push(key)
            oldListPosition = [...new Set(oldListPosition)];
        }
        setPositionCollapse(oldListPosition)
    }

    const onClickTitle = key => {
        setPositionTitle([key])
    }

    const onCreateSubLayout = key => {
        let name = `item_${key}__groups__g${resultGroups > 0 ? resultGroups.length + 1 : 0}`,
            oldValue = form.getFieldValue(name);
        if (resultGroups.includes(name)) {
            oldValue.push(undefined)
            form.setFieldsValue({
                [name]: oldValue
            })
        } else {
            form.setFieldsValue({
                [name]: [undefined]
            })
            setResultGroups([...new Set([...resultGroups, name])])
        }
    }

    const removeLayout = (e, k) => {
        e.preventDefault()
        e.stopPropagation();
        let keyItems = k,
            oldItems = resultList;
        oldItems = oldItems.filter(i => i !== keyItems)
        setResultList(oldItems)
        // let newItemsForm = removeObjectNullFull({
        //     ...form.getFieldsValue(),
        //     [keyItems]: undefined,
        //     [`${keyItems}__groups__g0`]: []
        // }, keyItems);
        // console.log(newItemsForm);
        // form.setFieldsValue(newItemsForm)
        // actionUpdateLayout({
        //     ...form.getFieldsValue(),
        //     [keyItems]: undefined,
        //     [`${keyItems}__groups__g0`]: []
        // })
        form.resetFields();
        form.setFieldsValue(itemListLayout.layout.items)
        setPositionTitle([]);
        // console.log(oldItems, keyItems)
    };

    return <>
        <HeaderAction title="Exclusive Content Layout" isCreate onClick={e => onCreate(e)}/>
        <div className="px-4 mt-5 exclusive-content-layout">
            <Spin tip="Loading..." spinning={isFetching}>
                <Form
                    className="bg-white"
                    form={form}
                    onFinish={onFinish}
                    onValuesChange={onFormChange}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <div className="exclusive-content-layout--form">
                        {
                            (resultList.length > 0) && resultList.map((i, k) => {
                                return (
                                    <List
                                        className={`exclusive-content-layout--item-parent ${positionCollapse.includes(k) ? "exclusive-content-layout--item-parent-open" : ""}`}
                                        key={k}
                                        header={
                                            <div className="row align-items-center overflow-hidden"
                                                 onClick={e => onCollapseTitle(k)}>
                                                <div className="col-1 mx-0 width-25px">
                                                    <CaretRightOutlined className="float-start cursorPointer"
                                                                        rotate={positionCollapse.includes(k) ? 90 : 0}/>
                                                </div>
                                                <div className="col-9">
                                                    {
                                                        (!positionTitle.includes(k)) && (
                                                            <span onClick={(e) => {
                                                                onClickTitle(k)
                                                                e.preventDefault()
                                                                e.stopPropagation();
                                                            }}
                                                                  className="float-start cursorPointer fw-bold fs--20px">
                                                        {form.getFieldValue(`${i}`) ? form
                                                            .getFieldValue(`${i}`) : "New Title Layout"}
                                                                <EditOutlined className="ms-2 cursorPointer hover--primary"/>
                                                            <Popconfirm
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    e.stopPropagation();
                                                                }}
                                                                title="Are you sure to delete this line?"
                                                                onConfirm={e => removeLayout(e, i)}
                                                                onCancel={(e) => {
                                                                    e.preventDefault()
                                                                    e.stopPropagation();
                                                                }}
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                            <CloseCircleOutlined
                                                                className="ms-1 cursorPointer hover--primary"/>
                                                            </Popconfirm>
                                                    </span>
                                                        )
                                                    }
                                                    <Form.Item
                                                        className={`float-start mb-0 cursorPointer w-50 ${!positionTitle.includes(k) ? "d-none" : ""}`}
                                                        label=""
                                                        name={`${i}`}
                                                    >
                                                        <Input
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                e.stopPropagation();
                                                            }}
                                                            ref={inputRef}
                                                            onPressEnter={() => form.submit()}
                                                            onBlur={() => form.submit()}
                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div className="col-1 mx-0 width-70px"/>
                                                <div className="col-2 mx-0 text-end">
                                                    <button type="button" className="btn btn-success btn-rounded"
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                e.stopPropagation();
                                                                onCreateSubLayout(k)
                                                            }}>
                                                        <i className="fa fa-plus-circle m-r-5"/>
                                                        {TAG_DEFINE.ACTION.create} Sub Category
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                        bordered
                                    >
                                        <List.Item className="w-100">
                                            <div className="w-50">
                                                <Form.Item
                                                    className="mb-0 w-100"
                                                    label="Select Post"
                                                    name={`${i}__items`}
                                                >
                                                    <Select
                                                        mode="multiple"
                                                        className="w-100"
                                                        allowClear
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {
                                                            (itemListPost?.result || []).length > 0 && (itemListPost?.result || []).map((i, k) => {
                                                                return (
                                                                    <Select.Option value={i?.id}
                                                                                   key={k}>{i?.title}</Select.Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </List.Item>
                                        {
                                            (resultGroups.length > 0) &&
                                            <p className="px-4 pt-4 fs--15px">List Sub Category</p>
                                        }
                                        {
                                            (resultGroups.length > 0) && resultGroups.map((item, itemKey) => {
                                                if (form.getFieldValue(`${i}__groups__g${itemKey}`)) {
                                                    return (
                                                        <List.Item key={itemKey} className="w-100">
                                                            <div className="w-100">
                                                                <Form.List name={`${i}__groups__g${itemKey}`}>
                                                                    {(fields, {add, remove}) => (
                                                                        <>
                                                                            {fields.map(({key, name, ...restField}) => (
                                                                                <div className="container mx-0" key={key}>
                                                                                    <div className="row">
                                                                                        <div className="col-4">
                                                                                            <Form.Item
                                                                                                {...restField}
                                                                                                className="float-start mb-0 w-100"
                                                                                                label="Title"
                                                                                                name={[name, "title"]}
                                                                                            >
                                                                                                <Input
                                                                                                    onClick={(e) => {
                                                                                                        e.preventDefault()
                                                                                                        e.stopPropagation();
                                                                                                    }}
                                                                                                />
                                                                                            </Form.Item>
                                                                                        </div>
                                                                                        <div className="col-7">
                                                                                            <Form.Item
                                                                                                className="float-start mb-0 w-100"
                                                                                                label="Select Post"
                                                                                                name={[name, "items"]}
                                                                                            >
                                                                                                <Select
                                                                                                    mode="multiple"
                                                                                                    className="w-100"
                                                                                                    allowClear
                                                                                                    showSearch
                                                                                                    filterOption={(input, option) =>
                                                                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        (itemListPost?.result || []).length > 0 && (itemListPost?.result || []).map((i, k) => {
                                                                                                            return (
                                                                                                                <Select.Option
                                                                                                                    value={i?.id}
                                                                                                                    key={k}>{i?.title}</Select.Option>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </Select>
                                                                                            </Form.Item>
                                                                                        </div>
                                                                                        <div className="col-1">
                                                                                            <Popconfirm
                                                                                                onClick={(e) => {
                                                                                                    e.preventDefault()
                                                                                                    e.stopPropagation();
                                                                                                }}
                                                                                                title="Are you sure to delete this line?"
                                                                                                onConfirm={(e) => {
                                                                                                    e.preventDefault()
                                                                                                    e.stopPropagation();
                                                                                                    remove(name)
                                                                                                }}
                                                                                                onCancel={(e) => {
                                                                                                    e.preventDefault()
                                                                                                    e.stopPropagation();
                                                                                                }}
                                                                                                okText="Yes"
                                                                                                cancelText="No"
                                                                                            >
                                                                                                <MinusCircleOutlined/>
                                                                                            </Popconfirm>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </>
                                                                    )}
                                                                </Form.List>
                                                            </div>
                                                        </List.Item>
                                                    )
                                                }
                                            })
                                        }
                                    </List>
                                )
                            })
                        }
                        <Divider className="my-0"/>
                    </div>
                    {
                        (resultList.length > 0) && (
                            <div className="text-end p-3">
                                <Button type="primary" htmlType="submit">
                                    Save {isFetching && <Icon type="sync-outlined" spin/>}
                                </Button>
                            </div>
                        )
                    }
                </Form>
            </Spin>
        </div>
    </>
}

export default Index
