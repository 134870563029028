import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/SubscriptionTransaction';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/subscriptionTransactionService';
import * as FunctionAction from '../actions/subscriptionTransactionAction';
import {message} from "antd";
// import TransactionRequest from "../mapping/Request/TransactionRequest";
import TAG_DEFINE from "../../constants/common";
import PERMISSION from "../../constants/permission";
import SubscriptionTransactionRequest, {filtersParams} from "../mapping/Request/SubscriptionTransactionRequest";

function* actionGetListTransactions() {
    yield takeLatest(Types.SUBSCRIPTION_TRANSACTION_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = parseListRequestParams(filtersParams(action.filters || {}));
        const result = yield call(FunctionService.getListSubscriptionTransactions, params);
        yield put(FunctionAction.subscriptionTransactionListSuccess(result));
    }, errorHandle(Types.SUBSCRIPTION_TRANSACTION_FAILED)))
}

function* actionGetMasterTransactions() {
    yield takeLatest(Types.SUBSCRIPTION_TRANSACTION_MASTER_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.masterData, action.params);
        yield put(FunctionAction.subscriptionTransactionMasterDataSuccess(result));
    }, errorHandle(Types.SUBSCRIPTION_TRANSACTION_FAILED)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* subscriptionTransactionSaga() {
    yield all([
        fork(actionGetListTransactions),
        fork(actionGetMasterTransactions),
    ])
}
