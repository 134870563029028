import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/User';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import {getListUsers, create, masterData, getItem, update} from '../services/userService';
import {
    userListSuccess,
    userMasterDataSuccess,
    userGetItemSuccess,
    userGetItemAction
} from '../actions/userAction';
import {message} from "antd";
import UserRequest from "../mapping/Request/UserRequest";
import TAG_DEFINE from "../../constants/common";
import PERMISSION from "../../constants/permission";

function* actionGetListUsers() {
    yield takeLatest(Types.USER_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = parseListRequestParams(action.filters || {});
        const result = yield call(getListUsers, params);
        yield put(userListSuccess(result));
    }, errorHandle(Types.USER_FAILED)))
}

function* actionGetItem() {
    yield takeLatest(Types.USER_GET_TEM_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(getItem, action.params.id);
        yield put(userGetItemSuccess(result));
    }, errorHandle(Types.USER_FAILED)))
}

function* actionGetMasterUsers() {
    yield takeLatest(Types.USER_MASTER_DATA_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(masterData, action.params);
        yield put(userMasterDataSuccess(result));
    }, errorHandle(Types.USER_FAILED)))
}

function* createAction() {
    yield takeLatest(Types.USER_CREATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new UserRequest(action.params).exportCreate();
        const result = yield call(create, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["201"]);
        action.params.props.history.push(PERMISSION.USER.VIEW.route, {group: PERMISSION.USER.VIEW.group});
        // yield put({ type: Types.USER_LIST_ACTION });
    }, errorHandle(Types.USER_FAILED)))
}

function* updateAction() {
    yield takeLatest(Types.USER_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new UserRequest(action.params).exportUpdate();
        yield call(update, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
        // yield put({ type: Types.HOTEL_GET_ITEM_ACTION });
        // yield put({ type: Types.VOUCHER_FAIL });
        yield put(userGetItemAction({id: action.params.id}));
        // yield put(VoucherActions.listVoucherAction(action.params.filters || {}));
    }, errorHandle(Types.USER_FAILED)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* userSaga() {
    yield all([
        fork(actionGetListUsers),
        fork(createAction),
        fork(actionGetMasterUsers),
        fork(actionGetItem),
        fork(updateAction),
    ])
}
