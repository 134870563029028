import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import CustomerResponse, {MasterCustomerResponse, TeamPointResponse} from "../mapping/Response/CustomerResponse";
import moment from "moment";

export function getListCustomers(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CUSTOMER_LIST,
        params: params,
        parser: dataParser,
    }).get();
}

export function getTeamPointListCustomers(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CUSTOMER_TEAM_POINT_LIST,
        params: {
            ...params,
            timezone: (moment().utcOffset()) / 60
        },
        parser: dataParserTeamPoint,
    }).get();
}

export function masterData() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CUSTOMER_MASTER_DATA,
        // params: removeObjectNull({ city_id }),
        parser: data => new MasterCustomerResponse(data.data.result).exportRoles(),
    }).get();
}

export function killSession(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CUSTOMER_KILL_SESSION,
        endpointParams: {id},
        parser: data => data.data.result,
    }).post({});
}

export function create(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CUSTOMER_CREATE,
    }).post(data);
}

export function update(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CUSTOMER_UPDATE,
        endpointParams: {id: data?.id},
    }).put(data);
}

export function getItem(id) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CUSTOMER_GET_ITEM,
        endpointParams: {id},
        parser: data => new CustomerResponse(data.data.result).exportItem(),
    }).get();
}

function dataParser(data = {}) {
    return {
        ...data.data,
        result: (data.data.result || []).map(item => new CustomerResponse(item).exportList()),
        total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
    }
}

function dataParserTeamPoint(data = {}) {
    return {
        ...data.data,
        result: (data.data.result || []).map(item => new TeamPointResponse(item).exportList()),
        total_page: Math.ceil(parseInt(data.data.total) / parseInt(data.data.page_size))
    }
}
