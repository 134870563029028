export const HOTEL_LIST_ACTION = "HOTEL_LIST_ACTION";
export const HOTEL_LIST_SUCCESS = "HOTEL_LIST_SUCCESS";
export const HOTEL_ALL_ACTION = "HOTEL_ALL_ACTION";
export const HOTEL_ALL_SUCCESS = "HOTEL_ALL_SUCCESS";
export const HOTEL_GET_ITEM_ACTION = "HOTEL_GET_ITEM_ACTION";
export const HOTEL_GET_ITEM_SUCCESS = "HOTEL_GET_ITEM_SUCCESS";
export const HOTEL_CREATE_ACTION = "HOTEL_CREATE_ACTION";
export const HOTEL_CREATE_SUCCESS = "HOTEL_CREATE_SUCCESS";
export const HOTEL_UPDATE_ACTION = "HOTEL_UPDATE_ACTION";
export const HOTEL_UPDATE_SUCCESS = "HOTEL_UPDATE_SUCCESS";
export const HOTEL_DELETE_ACTION = "HOTEL_DELETE_ACTION";
export const HOTEL_DELETE_SUCCESS = "HOTEL_DELETE_SUCCESS";
export const HOTEL_STATUS_ACTION = "HOTEL_STATUS_ACTION";
export const HOTEL_STATUS_SUCCESS = "HOTEL_STATUS_SUCCESS";
export const HOTEL_MASTER_ACTION = "HOTEL_MASTER_ACTION";
export const HOTEL_MASTER_SUCCESS = "HOTEL_MASTER_SUCCESS";
export const HOTEL_FAIL = "HOTEL_FAIL";
