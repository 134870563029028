import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/ExclusiveContent';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/exclusiveContentService';
import * as FunctionAction from '../actions/exclusiveContentAction';
import {message} from "antd";
import ExclusiveContentRequest, {ExclusiveContentLayoutRequest} from "../mapping/Request/ExclusiveContentRequest";
import TAG_DEFINE from "../../constants/common";
import PERMISSION from "../../constants/permission";

function* actionGetListPost() {
    yield takeLatest(Types.EXCLUSIVE_CONTENT_POST_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = parseListRequestParams(action.filters || {});
        const result = yield call(FunctionService.getListPost, params);
        yield put(FunctionAction.exclusiveContentPostListSuccess(result));
    }, errorHandle(Types.EXCLUSIVE_CONTENT_FAILED)))
}

function* actionGetListLayout() {
    yield takeLatest(Types.EXCLUSIVE_CONTENT_LAYOUT_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = parseListRequestParams(action.filters || {});
        const result = yield call(FunctionService.getListLayout, params);
        yield put(FunctionAction.exclusiveContentLayoutListSuccess(result));
    }, errorHandle(Types.EXCLUSIVE_CONTENT_FAILED)))
}

function* actionGetItemPost() {
    yield takeLatest(Types.EXCLUSIVE_CONTENT_POST_GET_TEM_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.getItemPost, action.params.id);
        yield put(FunctionAction.exclusiveContentPostGetItemSuccess(result));
    }, errorHandle(Types.EXCLUSIVE_CONTENT_FAILED)))
}

function* createPostAction() {
    yield takeLatest(Types.EXCLUSIVE_CONTENT_POST_CREATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new ExclusiveContentRequest(action.params).exportCreate();
        const result = yield call(FunctionService.createPost, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["201"]);
        action.params.props.history.push(PERMISSION.EXCLUSIVE_CONTENT.VIEW.route, {group: PERMISSION.EXCLUSIVE_CONTENT.VIEW.group});
        yield put({type: Types.EXCLUSIVE_CONTENT_POST_LIST_ACTION});
    }, errorHandle(Types.EXCLUSIVE_CONTENT_FAILED)))
}

function* updatePostAction() {
    yield takeLatest(Types.EXCLUSIVE_CONTENT_POST_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new ExclusiveContentRequest(action.params).exportUpdate();
        yield call(FunctionService.updatePost, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
        yield put(FunctionAction.exclusiveContentPostGetItemAction({id: action.params.id}));
    }, errorHandle(Types.EXCLUSIVE_CONTENT_FAILED)))
}

function* updateLayoutAction() {
    yield takeLatest(Types.EXCLUSIVE_CONTENT_LAYOUT_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new ExclusiveContentLayoutRequest(action.params).exportUpdate();
        yield call(FunctionService.updateLayout, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
        yield put(FunctionAction.exclusiveContentLayoutListAction({id: action.params.id}));
    }, errorHandle(Types.EXCLUSIVE_CONTENT_FAILED)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* exclusiveContentSaga() {
    yield all([
        fork(actionGetListPost),
        fork(actionGetListLayout),
        fork(actionGetItemPost),
        fork(createPostAction),
        fork(updatePostAction),
        fork(updateLayoutAction),
    ])
}
