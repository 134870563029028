import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/SubscriptionPackage';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as FunctionService from '../services/subscriptionPackageService';
import * as FunctionAction from '../actions/subscriptionPackageAction';
import {message} from "antd";
import CustomerRequest from "../mapping/Request/CustomerRequest";
import TAG_DEFINE from "../../constants/common";
import PERMISSION from "../../constants/permission";
import SubscriptionPackageRequest, {filtersParams} from "../mapping/Request/SubscriptionPackageRequest";

function* actionGetListPackages() {
    yield takeLatest(Types.SUBSCRIPTION_PACKAGE_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = parseListRequestParams(filtersParams(action.filters || {}));
        const result = yield call(FunctionService.getListSubscriptionPackage, params);
        yield put(FunctionAction.subscriptionPackageListSuccess(result));
    }, errorHandle(Types.SUBSCRIPTION_PACKAGE_FAILED)))
}

function* actionGetItem() {
    yield takeLatest(Types.SUBSCRIPTION_PACKAGE_GET_TEM_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.getItem, action.params.id);
        yield put(FunctionAction.subscriptionPackageGetItemSuccess(result));
    }, errorHandle(Types.SUBSCRIPTION_PACKAGE_FAILED)))
}

function* actionGetMasterPackages() {
    yield takeLatest(Types.SUBSCRIPTION_PACKAGE_MASTER_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(FunctionService.masterData, action.params);
        yield put(FunctionAction.subscriptionPackageMasterDataSuccess(result));
    }, errorHandle(Types.SUBSCRIPTION_PACKAGE_FAILED)))
}

// function* createAction() {
//     yield takeLatest(Types.SUBSCRIPTION_PACKAGE_CREATE_ACTION, Func.sagaWrapper(function* (action) {
//         const data = new CustomerRequest(action.params).exportCreate();
//         const result = yield call(FunctionService.create, data);
//         message.success(TAG_DEFINE.VALIDATION.statusCode.code["201"]);
//         action.params.props.history.push(PERMISSION.SUBSCRIPTION_PACKAGE.VIEW.route, {group: PERMISSION.SUBSCRIPTION_PACKAGE.VIEW.group});
//         // yield put({ type: Types.SUBSCRIPTION_PACKAGE_LIST_ACTION });
//     }, errorHandle(Types.SUBSCRIPTION_PACKAGE_FAILED)))
// }

function* updateAction() {
    yield takeLatest(Types.SUBSCRIPTION_PACKAGE_UPDATE_ACTION, Func.sagaWrapper(function* (action) {
        const data = new SubscriptionPackageRequest(action.params?.query).exportUpdate();
        yield call(FunctionService.update, data);
        message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
        yield put(FunctionAction.subscriptionPackageUpdateSuccess(true));
        yield put(FunctionAction.subscriptionPackageListAction(action?.params?.filters));
    }, errorHandle(Types.SUBSCRIPTION_PACKAGE_FAILED)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* subscriptionPackageSaga() {
    yield all([
        fork(actionGetListPackages),
        fork(actionGetMasterPackages),
        // fork(createAction),
        fork(actionGetItem),
        fork(updateAction),
    ])
}
