import {all} from 'redux-saga/effects';
import {hotelSaga} from './hotelSaga';
import {authSaga} from './authSaga';
import {verifySaga} from './verifySaga';
import {dataSaga} from './dataSaga';
import {userSaga} from './userSaga';
import {customerSaga} from './customerSaga';
import {dashboardSaga} from './dashboardSaga';
import {ticketSaga} from './ticketSaga';
import {subscriptionPackageSaga} from './subscriptionPackageSaga';
import {subscriptionCustomerSaga} from './subscriptionCustomerSaga';
import {subscriptionTransactionSaga} from './subscriptionTransactionSaga';
import {subscriptionCancelationFeedbackSaga} from './subscriptionCancelationFeedbackSaga';
import {exclusiveContentSaga} from './exclusiveContentSaga';
import {uploadSaga} from './uploadSaga';
import {subscriptionGeneralPackageSaga} from './subscriptionGeneralPackageSaga';
import {subscriptionGeoDataSaga} from './subscriptionGeoDataSaga';
import {subscriptionCustomPackageSaga} from './subscriptionCustomPackageSaga';
import {subscriptionCustomerRecurringSaga} from './subscriptionCustomerRecurringSaga';
import {companySaga} from './companySaga';
import {configurationSaga} from './configurationSaga';

export default function* rootSaga() {
    yield all([
        hotelSaga(),
        authSaga(),
        verifySaga(),
        dataSaga(),
        userSaga(),
        customerSaga(),
        dashboardSaga(),
        ticketSaga(),
        subscriptionPackageSaga(),
        subscriptionCustomerSaga(),
        subscriptionTransactionSaga(),
        subscriptionCancelationFeedbackSaga(),
        exclusiveContentSaga(),
        uploadSaga(),
        subscriptionGeneralPackageSaga(),
        subscriptionGeoDataSaga(),
        subscriptionCustomPackageSaga(),
        subscriptionCustomerRecurringSaga(),
        companySaga(),
        configurationSaga(),
    ])
}
