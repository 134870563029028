import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import * as HookAction from '../actions/authAction'

export const ActionUpdateProfile = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.updateProfileAction(params)),
        [dispatch]
    )
}
