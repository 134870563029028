import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/customerAction'

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        () => dispatch(HookAction.customerInit()),
        [dispatch]
    )
}

export const ActionKillSession = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.customerKillSessionAction(params)),
        [dispatch]
    )
}

export const ActionGetListCustomer = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.customerListAction(filters)),
        [dispatch]
    )
}

export const ActionGetItem = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.customerGetItemAction(params)),
        [dispatch]
    )
}

export const ActionMasterData = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.customerMasterDataAction(params)),
        [dispatch]
    )
}

export const ActionCreateCustomer = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.customerCreateAction(params)),
        [dispatch]
    )
}
export const ActionUpdateCustomer = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.customerUpdateAction(params)),
        [dispatch]
    )
}

export const ActionCustomerTeamPointList = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.customerTeamPointListAction(params)),
        [dispatch]
    )
}
