import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {formatInt, removeObjectNullFull} from "../../../utils/functions";

export default class CompanyRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.name = data?.name || "";
        this.owner = data?.owner || "";
        this.address = data?.address || "";
        this.phone_number = data?.phone_number || "";
        this.status = data?.status || "";
        this.staffs = this.customizeStaffs(data?.staffs);
    }

    customizeStaffs(staffs) {
        let newStaffs = "";
        if (Array.isArray(staffs)) {
            newStaffs = staffs
        } else {
            newStaffs = (staffs || "").split(",")?.map(i => i?.trim());
        }
        return newStaffs;
    }

    exportCreate() {
        return {
            name: this.name,
            owner: this.owner,
            address: this.address,
            phone_number: this.phone_number,
        }
    }

    exportUpdate() {
        return {
            id: this.id,
            name: this.name,
            owner: this.owner,
            address: this.address,
            phone_number: this.phone_number,
            status: formatInt(this.status),
            staffs: this.staffs,
        }
    }

}

export const common = {
    ACTION_FIELD: {
        name: "name",
        owner: "owner",
        address: "address",
        phone_number: "phone_number",
        status: "status",
        staffs: "staffs",
    },
}

export const filtersParams = (filters) => {
    let newDateAdded = (filters?.date || []).length > 0 ? [moment(filters?.date[0]).format("YYYY-MM-DD"), moment(filters?.date[1]).format("YYYY-MM-DD")] : ["", ""]
    return removeObjectNullFull({
        from_date: newDateAdded[0],
        to_date: newDateAdded[1],
        search: filters?.search || "",
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
    })
}

