export const CUSTOMER_LIST_ACTION = "CUSTOMER_LIST_ACTION";
export const CUSTOMER_LIST_SUCCESS = "CUSTOMER_LIST_SUCCESS";
export const CUSTOMER_CREATE_ACTION = "CUSTOMER_CREATE_ACTION";
export const CUSTOMER_CREATE_SUCCESS = "CUSTOMER_CREATE_SUCCESS";
export const CUSTOMER_UPDATE_ACTION = "CUSTOMER_UPDATE_ACTION";
export const CUSTOMER_UPDATE_SUCCESS = "CUSTOMER_UPDATE_SUCCESS";
export const CUSTOMER_MASTER_DATA_ACTION = "CUSTOMER_MASTER_DATA_ACTION";
export const CUSTOMER_MASTER_DATA_SUCCESS = "CUSTOMER_MASTER_DATA_SUCCESS";
export const CUSTOMER_GET_TEM_ACTION = "CUSTOMER_GET_TEM_ACTION";
export const CUSTOMER_GET_TEM_SUCCESS = "CUSTOMER_GET_TEM_SUCCESS";

export const CUSTOMER_TEAM_POINT_LIST_ACTION = "CUSTOMER_TEAM_POINT_LIST_ACTION";
export const CUSTOMER_TEAM_POINT_LIST_SUCCESS = "CUSTOMER_TEAM_POINT_LIST_SUCCESS";

export const CUSTOMER_KILL_SESSION_ACTION = "CUSTOMER_KILL_SESSION_ACTION";
export const CUSTOMER_KILL_SESSION_SUCCESS = "CUSTOMER_KILL_SESSION_SUCCESS";

export const CUSTOMER_INIT = "CUSTOMER_INIT";
export const CUSTOMER_FAILED = "CUSTOMER_FAILED";
