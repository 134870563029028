import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";

export default class CustomerResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.email = data?.email || "";
        this.first_name = data?.first_name || "";
        this.last_name = data?.last_name || "";
        this.roles = data?.roles || [];
        this.status = data?.status + "" || "0";
        this.phone_number = data?.phone_number || "";
        this.account_manager = data?.account_manager ? {
            id: data?.account_manager?.id || "",
            name: data?.account_manager?.name || "",
            email: data?.account_manager?.email || ""
        } : null;
        this.status_explain = data?.status_explain || "";
        this.created_at = data?.created_at ? moment(data?.created_at).format(DATE_TIME_FORMAT.SHORT_DATE_EXPORT) : "";
        this.updated_at = data?.updated_at || "";
        this.point = data?.point || "";
        this.cash = data?.cash || "";
        this.referral_code = data?.referral_code || "";
        this.membership_code = data?.membership_code || "";
        this.referral = {
            id: data?.referral?.id || "",
            email: data?.referral?.email || "",
            name: data?.referral?.name || ""
        };
        this.referral_label = data?.referral?.name || "";
        this.referral_id = data?.referral?.id || "";
        this.w4_file = data?.w4_file || "";
        this.is_bypass_multiple_login = (data?.is_bypass_multiple_login === 1) ? true : false;
    }

    exportList() {
        return {
            id: this.id,
            email: this.email,
            first_name: this.first_name,
            last_name: this.last_name,
            status: this.status,
            status_explain: this.status_explain,
            roles: this.roles,
            phone_number: this.phone_number,
            account_manager: this.account_manager,
            referral: this.referral,
            referral_label: this.referral_label,
            created_at: this.created_at,
        }
    }

    exportItem() {
        return {
            id: this.id,
            email: this.email,
            first_name: this.first_name,
            last_name: this.last_name,
            status: this.status,
            status_explain: this.status_explain,
            roles: this.roles,
            phone_number: this.phone_number,
            is_bypass_multiple_login: this.is_bypass_multiple_login,
            account_manager: this.account_manager?.id,
            point: this.point,
            cash: this.cash,
            referral_code: this.referral_code,
            membership_code: this.membership_code,
            referral_id: this.referral_id,
            w4_file: this.w4_file,
        }
    }
}

export class MasterCustomerResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.ROLES = data?.ROLES || {};
        this.STATUS = data?.STATUS || {};
        this.CUSTOMER = data?.CUSTOMER || {};
        this.ADMIN = this.generateAdmin(data?.ADMIN || {});
    }

    generateAdmin(items = {}) {
        let newItems = {};
        if ((Object.keys(items || {})).length > 0) {
            (Object.keys(items || {})).map((i, k) => {
                newItems = {
                    ...newItems,
                    [items[i]]: i
                }
            })
        }
        return newItems
    }


    exportRoles() {
        return {
            roles: this.ROLES,
            status: this.STATUS,
            admin: this.ADMIN,
            customer: this.CUSTOMER,
        }
    }
}

export const listCols = ['email', "phone_number", "referral_label", "first_name", "last_name", "created_at", "status"];

export class TeamPointResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.team_point = data?.team_point || 0;
        this.team_point_level_1 = data?.team_point_level_1 || 0;
        this.team_point_level_2 = data?.team_point_level_2 || 0;
        this.team_point_level_3 = data?.team_point_level_3 || 0;
        this.team_point_level_4 = data?.team_point_level_4 || 0;
        this.team_trans = data?.team_trans || 0;
        this.team_trans_level_1 = data?.team_trans_level_1 || 0;
        this.team_trans_level_2 = data?.team_trans_level_2 || 0;
        this.team_trans_level_3 = data?.team_trans_level_3 || 0;
        this.team_trans_level_4 = data?.team_trans_level_4 || 0;
        this.total_commission = data?.total_commission || 0;
        this.team_commission = data?.team_commission || 0;
        this.team_commission_level_1 = data?.team_commission_level_1 || 0;
        this.team_commission_level_2 = data?.team_commission_level_2 || 0;
        this.team_commission_level_3 = data?.team_commission_level_3 || 0;
        this.team_commission_level_4 = data?.team_commission_level_4 || 0;
        this.email = data?.email || "";
        this.total_point = data?.total_point || 0;
        this.total_trans = data?.total_trans || 0;
    }

    exportList() {
        return {
            id: this.id,
            team_point: this.team_point,
            team_point_level_1: this.team_point_level_1,
            team_point_level_2: this.team_point_level_2,
            team_point_level_3: this.team_point_level_3,
            team_point_level_4: this.team_point_level_4,
            team_trans: this.team_trans,
            team_trans_level_1: this.team_trans_level_1,
            team_trans_level_2: this.team_trans_level_2,
            team_trans_level_3: this.team_trans_level_3,
            team_trans_level_4: this.team_trans_level_4,
            email: this.email,
            total_point: this.total_point,
            total_trans: this.total_trans,
            total_commission: this.total_commission,
            team_commission: this.team_commission,
            team_commission_level_1: this.team_commission_level_1,
            team_commission_level_2: this.team_commission_level_2,
            team_commission_level_3: this.team_commission_level_3,
            team_commission_level_4: this.team_commission_level_4
        }
    }
}

//export const temPointListCols = ["email", "total_point", "total_commission", "total_trans", "team_point", "team_commission", "team_trans", "team_point_level_1", "team_commission_level_1", "team_trans_level_1", "team_point_level_2", "team_commission_level_2", "team_trans_level_2", "team_point_level_3", "team_commission_level_3", "team_trans_level_3", "team_point_level_4", "team_commission_level_4", "team_trans_level_4"];
export const temPointListCols = ["email", "total_point", "total_commission", "total_trans"];
