export const SUBSCRIPTION_CUSTOMER_LIST_ACTION = "SUBSCRIPTION_CUSTOMER_LIST_ACTION";
export const SUBSCRIPTION_CUSTOMER_LIST_SUCCESS = "SUBSCRIPTION_CUSTOMER_LIST_SUCCESS";

export const SUBSCRIPTION_CUSTOMER_MASTER_ACTION = "SUBSCRIPTION_CUSTOMER_MASTER_ACTION";
export const SUBSCRIPTION_CUSTOMER_MASTER_SUCCESS = "SUBSCRIPTION_CUSTOMER_MASTER_SUCCESS";

export const SUBSCRIPTION_CUSTOMER_GET_TEM_ACTION = "SUBSCRIPTION_CUSTOMER_GET_TEM_ACTION";
export const SUBSCRIPTION_CUSTOMER_GET_TEM_SUCCESS = "SUBSCRIPTION_CUSTOMER_GET_TEM_SUCCESS";

export const SUBSCRIPTION_CUSTOMER_UPDATE_ACTION = "SUBSCRIPTION_CUSTOMER_UPDATE_ACTION";
export const SUBSCRIPTION_CUSTOMER_UPDATE_SUCCESS = "SUBSCRIPTION_CUSTOMER_UPDATE_SUCCESS";

export const SUBSCRIPTION_CUSTOMER_CREATE_ACTION = "SUBSCRIPTION_CUSTOMER_CREATE_ACTION";
export const SUBSCRIPTION_CUSTOMER_CREATE_SUCCESS = "SUBSCRIPTION_CUSTOMER_CREATE_SUCCESS";

export const SUBSCRIPTION_CUSTOMER_MASTER_STATE_ACTION = "SUBSCRIPTION_CUSTOMER_MASTER_STATE_ACTION";
export const SUBSCRIPTION_CUSTOMER_MASTER_STATE_SUCCESS = "SUBSCRIPTION_CUSTOMER_MASTER_STATE_SUCCESS";

export const SUBSCRIPTION_CUSTOMER_DELETE_ACTION = "SUBSCRIPTION_CUSTOMER_DELETE_ACTION";
export const SUBSCRIPTION_CUSTOMER_DELETE_SUCCESS = "SUBSCRIPTION_CUSTOMER_DELETE_SUCCESS";

export const SUBSCRIPTION_CUSTOMER_INIT = "SUBSCRIPTION_CUSTOMER_INIT";
export const SUBSCRIPTION_CUSTOMER_FAILED = "SUBSCRIPTION_CUSTOMER_FAILED";
