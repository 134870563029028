import {createSelector} from 'reselect'

const stateReducer = state => state.exclusiveContentReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        exclusiveContentReducer => exclusiveContentReducer.isFetching
    )

export const selectErrors = (initItems) =>
    createSelector(
        stateReducer,
        exclusiveContentReducer => exclusiveContentReducer.errors
    )

export const selectListPost = (initItems) =>
    createSelector(
        stateReducer,
        exclusiveContentReducer => exclusiveContentReducer.data
    )

export const selectListLayout = (initItems) =>
    createSelector(
        stateReducer,
        exclusiveContentReducer => exclusiveContentReducer.layout
    )

export const selectPostItem = (initItems) =>
    createSelector(
        stateReducer,
        exclusiveContentReducer => exclusiveContentReducer.item
    )
