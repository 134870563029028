export const SUBSCRIPTION_GENERAL_PACKAGE_LIST_ACTION = "SUBSCRIPTION_GENERAL_PACKAGE_LIST_ACTION";
export const SUBSCRIPTION_GENERAL_PACKAGE_LIST_SUCCESS = "SUBSCRIPTION_GENERAL_PACKAGE_LIST_SUCCESS";

export const SUBSCRIPTION_GENERAL_PACKAGE_MASTER_ACTION = "SUBSCRIPTION_GENERAL_PACKAGE_MASTER_ACTION";
export const SUBSCRIPTION_GENERAL_PACKAGE_MASTER_SUCCESS = "SUBSCRIPTION_GENERAL_PACKAGE_MASTER_SUCCESS";

export const SUBSCRIPTION_GENERAL_PACKAGE_GET_TEM_ACTION = "SUBSCRIPTION_GENERAL_PACKAGE_GET_TEM_ACTION";
export const SUBSCRIPTION_GENERAL_PACKAGE_GET_TEM_SUCCESS = "SUBSCRIPTION_GENERAL_PACKAGE_GET_TEM_SUCCESS";
export const SUBSCRIPTION_GENERAL_PACKAGE_UPDATE_ACTION = "SUBSCRIPTION_GENERAL_PACKAGE_UPDATE_ACTION";
export const SUBSCRIPTION_GENERAL_PACKAGE_UPDATE_SUCCESS = "SUBSCRIPTION_GENERAL_PACKAGE_UPDATE_SUCCESS";

export const SUBSCRIPTION_GENERAL_PACKAGE_INIT = "SUBSCRIPTION_GENERAL_PACKAGE_INIT";
export const SUBSCRIPTION_GENERAL_PACKAGE_FAILED = "SUBSCRIPTION_GENERAL_PACKAGE_FAILED";
