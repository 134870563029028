import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {formatInt} from "../../../utils/functions";

export default class CompanyResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.name = data?.name || "";
        this.owner = data?.owner || "";
        this.address = data?.address || "";
        this.phone_number = data?.phone_number || "";
        this.staffs = data?.staffs || [];
        this.status = formatInt(data?.status);
    }

    exportList() {
        return {
            id: this.id,
            name: this.name,
            owner: this.owner,
            status: this.status,
        }
    }

    exportItem() {
        return {
            id: this.id,
            name: this.name,
            owner: this.owner,
            address: this.address,
            phone_number: this.phone_number,
            status: this.status.toString(),
            staffs: (this.staffs || "").join(", "),
        }
    }
}

export class MasterCompanyResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.STATUS = data?.STATUS || {};
    }

    exportMaster() {
        return {
            status: this.STATUS,
        }
    }
}

export const listCols = ["name", "owner", "status"];
