import * as Types from '../types/ExclusiveContent';
import {PAGINATION} from '../../constants/define';
// import HotelRequest from "../mapping/Request/HotelRequest";

const initialState = {
    isFetching: false,
    all: [],
    errors: [],
    masterData: {},
    // item: new HotelRequest().update(),
    item: {},
    data: {
        result: [],
        page_size: PAGINATION.page_size,
        page_index: PAGINATION.page_index,
        total: PAGINATION.total,
        total_page: PAGINATION.total_page,
    },
    layout: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case Types.EXCLUSIVE_CONTENT_POST_LIST_ACTION:
            return {...state, isFetching: true};
        case Types.EXCLUSIVE_CONTENT_POST_LIST_SUCCESS:
            return {...state, isFetching: false, data: action.exclusiveContentPosts};
        case Types.EXCLUSIVE_CONTENT_POST_CREATE_ACTION:
            return {...state, isFetching: true};
        case Types.EXCLUSIVE_CONTENT_POST_CREATE_SUCCESS:
            return {...state, isFetching: false};
        case Types.EXCLUSIVE_CONTENT_POST_UPDATE_ACTION:
            return {...state, isFetching: true};
        case Types.EXCLUSIVE_CONTENT_POST_UPDATE_SUCCESS:
            return {...state, isFetching: false};
        case Types.EXCLUSIVE_CONTENT_POST_GET_TEM_ACTION:
            return {...state, isFetching: true};
        case Types.EXCLUSIVE_CONTENT_POST_GET_TEM_SUCCESS:
            return {...state, isFetching: false, item: action.item};


        case Types.EXCLUSIVE_CONTENT_LAYOUT_LIST_ACTION:
            return {...state, isFetching: true};
        case Types.EXCLUSIVE_CONTENT_LAYOUT_LIST_SUCCESS:
            return {...state, isFetching: false, layout: action.exclusiveContentLayouts};
        case Types.EXCLUSIVE_CONTENT_LAYOUT_UPDATE_ACTION:
            return {...state, isFetching: true};
        case Types.EXCLUSIVE_CONTENT_LAYOUT_UPDATE_SUCCESS:
            return {...state, isFetching: false};

        case Types.EXCLUSIVE_CONTENT_FAILED:
            return {...state, isFetching: false, errors: action.errors};
        case Types.EXCLUSIVE_CONTENT_INIT:
            return initialState;
        default:
            return state;
    }
}
