import * as Types from '../types/SubscriptionGeoData';
import {PAGINATION} from '../../constants/define';
// import HotelRequest from "../mapping/Request/HotelRequest";

const initialState = {
    isFetching: false,
    all: [],
    errors: [],
    masterData: {},
    // item: new HotelRequest().update(),
    item: {},
    data: {
        result: [],
        page_size: PAGINATION.page_size,
        page_index: PAGINATION.page_index,
        total: PAGINATION.total,
        total_page: PAGINATION.total_page,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case Types.SUBSCRIPTION_GEO_DATA_LIST_ACTION:
            return {...state, isFetching: true};
        case Types.SUBSCRIPTION_GEO_DATA_LIST_SUCCESS:
            return {...state, isFetching: false, data: action.subscriptionGeoData};
        case Types.SUBSCRIPTION_GEO_DATA_MASTER_ACTION:
            return {...state, isFetching: true};
        case Types.SUBSCRIPTION_GEO_DATA_MASTER_SUCCESS:
            return {...state, isFetching: false, masterData: action.masterData};
        case Types.SUBSCRIPTION_GEO_DATA_GET_TEM_ACTION:
            return {...state, isFetching: true};
        case Types.SUBSCRIPTION_GEO_DATA_GET_TEM_SUCCESS:
            return {...state, isFetching: false, item: action.item};
        case Types.SUBSCRIPTION_GEO_DATA_UPDATE_ACTION:
            return {...state, isFetching: true};
        case Types.SUBSCRIPTION_GEO_DATA_UPDATE_SUCCESS:
            return {...state, isFetching: false};
        case Types.SUBSCRIPTION_GEO_DATA_FAILED:
            return {...state, isFetching: false, errors: action.errors};
        case Types.SUBSCRIPTION_GEO_DATA_INIT:
            return initialState;
        default:
            return state;
    }
}
