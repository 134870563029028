import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {
    columnsTable,
    genaratePaginateFilterSort,
    getDataSource,
    getTitleTable,
    removeObjectNull, removeObjectNullFull
} from "../../../utils/functions";
import {omit} from "lodash";
import TAG_DEFINE from "../../../constants/common";
import {PATHS} from "../../../constants/define";
import PERMISSION from "../../../constants/permission";

// ============== LAYOUT ==============
import HeaderAction from "../../common/layout/HeaderAction";
import {Icon} from "../../common";
import {ListComponent} from "../../common/ListComponent";
import {ListSearchFreeTextComponent} from "../../../utils/commonUtils";

// ============== ACTION ==============
import {ActionGetListCustomer, ActionMasterData} from '../../../data/hooks/customer';
import {Tooltip, Button, Tag, Form, Input, Select} from 'antd'

// ============== SELECT DATA ==============
import {
    selectFetching,
    selectErrors,
    selectListCustomer,
    selectMasterData
} from '../../../data/reselects/customerSelector';
import CustomerResponse, {listCols} from "../../../data/mapping/Response/CustomerResponse";
import {common} from "../../../data/mapping/Request/UserRequest";


const ModelFields = Object.getOwnPropertyNames(new CustomerResponse());
const Index = props => {
    const namePage = "customers",
        [initData, setInitData] = useState({
            titleItemTable: getTitleTable(listCols, ['action'], []),
            modelItem: ModelFields,
        }),
        [paramsFilters, setPramsFilters] = useState({}),
        [form] = Form.useForm(),
        componentTable = {
            email: {
                width: 230,
            },
            first_name: {
                width: 150,
            },
            last_name: {
                width: 150,
            },
            status: {
                width: 150,
                render: (val, record) => Object.keys(itemMasterData).length > 0 && (itemMasterData?.status[val]),
            },
            phone_number: {
                width: 200,
            },
            account_manager: {
                width: 250,
                render: (val, record) => val?.email,
            },
            referral_label: {
                width: 250,
            },
            roles: {
                width: 150,
                render: (val, record) => Object.keys(itemMasterData).length > 0 && (val || []).length > 0 && (val || []).map((i, k) =>
                    <Tag color="default" key={k}>{itemMasterData?.roles[i]}</Tag>)
            },
            action: {
                width: 100,
                align: 'center',
                key: 'action',
                fixed: 'right',
                render: (text, record) => <Icon type="edit-outlined" className="cursorPointer"
                                                onClick={() => props.history.push((PERMISSION.CUSTOMER.UPDATE.route).replace(":id", record.id), {
                                                    group: PERMISSION.CUSTOMER.UPDATE.group,
                                                    withParams: true
                                                })}/>,
            }
        };
    const [isFetching, setIsFetching] = useState(false),
        [resultList, setResultList] = useState([]),
        [pagination, setPagination] = useState({}),
        // ============== ACTION ==============
        getListCustomers = ActionGetListCustomer(),
        getMasterData = ActionMasterData(),
        // ============== SELECT DATA ==============
        itemListCustomers = useSelector(selectListCustomer()),
        itemErrors = useSelector(selectErrors()),
        itemMasterData = useSelector(selectMasterData()),
        itemIsFetching = useSelector(selectFetching());

    useEffect(() => {
        if ((itemListCustomers?.result || []).length < 1) {
            getListCustomers();
        }
        if (Object.keys(itemMasterData).length < 1) {
            getMasterData()
        }
    }, []);

    useEffect(() => {
        setIsFetching(itemIsFetching)
    }, [itemIsFetching]);

    useEffect(() => {
        setResultList(itemListCustomers?.result || [])
        setPagination({
            total: itemListCustomers?.total || 0,
            totalPage: itemListCustomers?.total_page || 0,
            defaultCurrent: itemListCustomers?.page_index || 1,
            pageSize: itemListCustomers?.page_size || 10
        })
    }, [itemListCustomers]);

    const onTableChange = (pagination, filters, sorter) => {
        // let currentFilters = getCurrentFilters();
        filters = omit({...paramsFilters, ...filters}, ['page_index', 'page_size']);
        let newFilters = genaratePaginateFilterSort(pagination, filters, sorter);
        setPramsFilters(newFilters)
        // setInitData({
        //     ...initData,
        //     filters: newFilters
        // });
        getListCustomers(newFilters);
    };

    const onCreate = (e, data) => {
        if (e === TAG_DEFINE.ACTION_OPTIONS.UPDATE) {
            props.history.push((PERMISSION.CUSTOMER.UPDATE.route).replace(":id", data.id))
        } else {
            props.history.push(PERMISSION.CUSTOMER.CREATE.route, {group: PERMISSION.CUSTOMER.CREATE.group})
            // props.history.push(PERMISSION.CUSTOMER.CREATE.route)
        }
    };

    const onSearch = (val) => {
        let getFilters = {...paramsFilters, ...val, page_index: 1};
        setPramsFilters(getFilters)
        getListCustomers(removeObjectNullFull(getFilters));
    };

    return <>
        <HeaderAction title="Customers List" isCreate onClick={e => onCreate(e)}/>
        <div className="container-fluid px-4">
            <Form
                form={form}
                layout="vertical"
                onFinish={onSearch}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className="row bg-white py-3 align-items-center">
                    <div className="col-4">
                        <Form.Item
                            label="Search"
                            name="search"
                        >
                            <Input allowClear/>
                        </Form.Item>
                    </div>
                    {/*<div className="col-4">*/}
                    {/*    <Form.Item*/}
                    {/*        label="Account Management"*/}
                    {/*        name="account_manager"*/}
                    {/*    >*/}
                    {/*        <Select*/}
                    {/*            mode="multiple"*/}
                    {/*            className="w-100"*/}
                    {/*            allowClear*/}
                    {/*            showSearch*/}
                    {/*            filterOption={(input, option) =>*/}
                    {/*                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0*/}
                    {/*            }*/}
                    {/*        >*/}
                    {/*            {*/}
                    {/*                (Object.keys(itemMasterData?.admin || {})).length > 0 && (Object.keys(itemMasterData?.admin || {})).map((i, k) => {*/}
                    {/*                    return (*/}
                    {/*                        <Select.Option value={i}*/}
                    {/*                                       key={k}>{itemMasterData?.admin[i]}</Select.Option>*/}
                    {/*                    )*/}
                    {/*                })*/}
                    {/*            }*/}
                    {/*        </Select>*/}
                    {/*    </Form.Item>*/}
                    {/*</div>*/}
                    <div className="col-2">
                        <Form.Item
                            label="Status"
                            name="status"
                        >
                            <Select
                                className="w-100"
                                allowClear
                            >
                                {
                                    (Object.keys(itemMasterData?.status || {})).length > 0 && (Object.keys(itemMasterData?.status || {})).map((i, k) => {
                                        return (
                                            <Select.Option value={i}
                                                           key={k}>{itemMasterData?.status[i]}</Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-1">
                        <Form.Item className="text-end" label=" ">
                            <Button type="primary" htmlType="submit">
                                Search {isFetching && <Icon type="sync-outlined" spin/>}
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </div>
        <ListComponent
            scroll={{x: 1000}}
            classParent="m-0 p-0 col-md-12 mt-4"
            marginTop={'0'} marginBottom={'0'}
            loading={{spinning: isFetching, size: 'large'}}
            rowKey="id"
            dataSource={getDataSource(resultList || [], initData.modelItem)}
            columns={columnsTable({
                titleTable: initData.titleItemTable,
                component: componentTable,
                itemName: namePage
            })}
            pagination={{
                total: pagination.total,
                totalPage: pagination.totalPage,
                current: pagination.defaultCurrent,
                pageSize: pagination.pageSize
            }}
            onChange={onTableChange}
            componentTableList={{}}
        />
    </>
}

export default Index
