import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/subscriptionGeoDataAction'

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        () => dispatch(HookAction.subscriptionGeoDataInit()),
        [dispatch]
    )
}

export const ActionGetListSubscriptionGeoData = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.subscriptionGeoDataListAction(filters)),
        [dispatch]
    )
}

// export const ActionGetItem = () => {
//     const dispatch = useDispatch()
//     return useCallback(
//         (params) => dispatch(HookAction.subscriptionGeoDataGetItemAction(params)),
//         [dispatch]
//     )
// }

// export const ActionMasterData = () => {
//     const dispatch = useDispatch()
//     return useCallback(
//         (params) => dispatch(HookAction.subscriptionPackageMasterDataAction(params)),
//         [dispatch]
//     )
// }

// export const ActionCreatePackage = () => {
//     const dispatch = useDispatch()
//     return useCallback(
//         (params) => dispatch(HookAction.subscriptionPackageCreateAction(params)),
//         [dispatch]
//     )
// }

export const ActionUpdateGeoData = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.subscriptionGeoDataUpdateAction(params)),
        [dispatch]
    )
}
