import {createSelector} from 'reselect'

const stateReducer = state => state.dataReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        dataReducer => dataReducer.isFetching
    )

export const selectMasterDataLeadType = (initItems) =>
    createSelector(
        stateReducer,
        dataReducer => dataReducer.masterLeadType
    )
