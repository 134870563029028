import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {
    columnsTable,
    genaratePaginateFilterSort,
    getDataSource,
    getTitleTable,
    removeObjectNull, removeObjectNullFull
} from "../../../../utils/functions";
import {omit} from "lodash";
import TAG_DEFINE from "../../../../constants/common";
import {PATHS} from "../../../../constants/define";
import PERMISSION from "../../../../constants/permission";

// ============== LAYOUT ==============
import HeaderAction from "../../../common/layout/HeaderAction";
import {Icon} from "../../../common";
import {ListComponent} from "../../../common/ListComponent";
import {ListSearchFreeTextComponent} from "../../../../utils/commonUtils";

// ============== ACTION ==============
import {
    ActionGetListSubscriptionCustomPackage,
    ActionUpdateCustomPackage,
    ActionCreateCustomPackage,
    ActionMasterData
} from '../../../../data/hooks/subscriptionCustomPackage';
import {Tooltip, Button, Tag, Form, Input, Select, DatePicker, InputNumber, Typography, Popconfirm} from 'antd'

// ============== SELECT DATA ==============
import {
    selectFetching,
    selectStatusAction,
    selectErrors,
    selectMasterData,
    selectListSubscriptionCustomPackage
} from '../../../../data/reselects/subscriptionCustomPackageSelector';
import SubscriptionCustomPackageResponse, {
    listCols
} from "../../../../data/mapping/Response/SubscriptionCustomPackageResponse";
import {constants} from "../../../../data/mapping/Response/SubscriptionCustomerResponse";
import AddSubscription from "./actions/AddSubscription";
import {InfoCircleOutlined} from "@ant-design/icons";

let STATUS_RQ = [1, 0]
const ModelFields = Object.getOwnPropertyNames(new SubscriptionCustomPackageResponse());
const Index = props => {
    const namePage = "subscription_custom_package",
        [initData, setInitData] = useState({
            titleItemTable: getTitleTable(listCols, ["action"], []),
            modelItem: ModelFields,
        }),
        [isOpenCreate, setIsOpenCreate] = useState(false),
        [paramsFilters, setPramsFilters] = useState({}),
        [form] = Form.useForm(),
        [editingKey, setEditingKey] = useState(''),
        [selectedStatus, setSelectedStatus] = useState(''),
        isEditing = (record) => record.id === editingKey,
        componentTable = {
            price: {
                width: 250,
                editable: true,
                // onCell: (record) => ({
                //     record,
                //     inputType: "number",
                //     editing: isEditing(record),
                // }),
            },
            status: {
                title: <Tooltip title="Click to change status">
                    <div className="clearfix">
                        <p className="float-start mb-0" style={{justifyContent: "center"}}>Status</p>
                        <InfoCircleOutlined className="float-start mt-1 ms-2 cursorPointer"
                                            style={{justifyContent: "center"}}/>
                    </div>
                </Tooltip>,
                width: 150,
                render: (val, record) => (
                    <Popconfirm placement="top" title="Are you sure?" onConfirm={() => actionUpdateCustomPackage({
                        id: record.id,
                        price: record.price,
                        status: STATUS_RQ[record.status]
                    })} okText="Yes" cancelText="No" className="">
                        <Tag className="cursorPointer" color={val ? "#2db7f5" : "red"}>{constants.status[val]}</Tag>
                    </Popconfirm>
                ),
            },
            action: {
                width: 150,
                render: (_, record) => {
                    const editable = isEditing(record);
                    return editable ? (
                        <span>
                            <Typography.Link
                                onClick={() => save(record.key, record)}
                                style={{
                                    marginRight: 8,
                                }}
                            >
                              Save
                            </Typography.Link>
                            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                              <a>Cancel</a>
                            </Popconfirm>
                          </span>
                    ) : (
                        <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                            Edit
                        </Typography.Link>
                    );
                },
            },
        };
    const [isFetching, setIsFetching] = useState(false),
        [resultList, setResultList] = useState([]),
        [pagination, setPagination] = useState({}),
        // ============== ACTION ==============
        actionGetListSubscriptionCustomPackage = ActionGetListSubscriptionCustomPackage(),
        actionUpdateCustomPackage = ActionUpdateCustomPackage(),
        actionMasterData = ActionMasterData(),
        actionCreate = ActionCreateCustomPackage(),
        // ============== SELECT DATA ==============
        itemListSubscriptionCustomPackages = useSelector(selectListSubscriptionCustomPackage()),
        itemErrors = useSelector(selectErrors()),
        itemMasterData = useSelector(selectMasterData()),
        itemStatusAction = useSelector(selectStatusAction()),
        itemIsFetching = useSelector(selectFetching());

    useEffect(() => {
        if ((itemListSubscriptionCustomPackages?.result || []).length < 1) {
            actionGetListSubscriptionCustomPackage();
        }
        actionMasterData();
        // if (Object.keys(itemMasterData).length < 1) {
        // getMasterData()
        // }
    }, []);

    useEffect(() => {
        setIsFetching(itemIsFetching)
    }, [itemIsFetching]);

    useEffect(() => {
        setIsOpenCreate(itemStatusAction.isCreate)
    }, [itemStatusAction]);

    useEffect(() => {
        setResultList(itemListSubscriptionCustomPackages?.result || [])
        setPagination({
            total: itemListSubscriptionCustomPackages?.total || 0,
            totalPage: itemListSubscriptionCustomPackages?.total_page || 0,
            defaultCurrent: itemListSubscriptionCustomPackages?.page_index || 1,
            pageSize: itemListSubscriptionCustomPackages?.page_size || 10
        })
    }, [itemListSubscriptionCustomPackages]);

    const onTableChange = (pagination, filters, sorter) => {
        // let currentFilters = getCurrentFilters();
        filters = omit({...paramsFilters, ...filters}, ['page_index', 'page_size']);
        let newFilters = genaratePaginateFilterSort(pagination, filters, sorter);
        setPramsFilters(newFilters)
        // setInitData({
        //     ...initData,
        //     filters: newFilters
        // });
        actionGetListSubscriptionCustomPackage(newFilters);
    };

    const onCreate = (e, data) => {
        if (e === TAG_DEFINE.ACTION_OPTIONS.UPDATE) {
            props.history.push((PERMISSION.PACKAGE.UPDATE.route).replace(":id", data.id))
        } else {
            // props.history.push(PERMISSION.PACKAGE.CREATE.route, {group: PERMISSION.PACKAGE.CREATE.group})
            // props.history.push(PERMISSION.PACKAGE.CREATE.route)
        }
    };

    const onSearch = (val) => {
        actionUpdateCustomPackage({...val, id: editingKey, status: selectedStatus})
        setSelectedStatus('');
        setEditingKey('');
        // let getFilters = {...paramsFilters, ...val, page_index: 1};
        // setPramsFilters(getFilters)
        // actionGetListSubscriptionCustomPackage(removeObjectNullFull(getFilters));
    };

    const edit = (record) => {
        form.setFieldsValue({
            price: record.price,
            ...record,
        });
        setEditingKey(record.id);
    };

    const cancel = () => {
        setSelectedStatus('');
        setEditingKey('');
    };

    const save = (key, record) => {
        setSelectedStatus(record.status)
        form.submit();
    };

    const mergedColumns = columnsTable({
        titleTable: initData.titleItemTable,
        component: componentTable,
        itemName: namePage
    }).map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'price' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return <>
        <HeaderAction title="Subscription Custom Package List" disabledFormatUpperCaseTitle isCreate
                      onClick={() => setIsOpenCreate(true)}/>
        <AddSubscription
            itemIsAction={itemStatusAction?.isCreate}
            masterData={itemMasterData}
            actionCreate={actionCreate}
            isOpenCreate={isOpenCreate}
            setIsOpenCreate={(e) => setIsOpenCreate(e)}/>
        <Form form={form} component={false} onFinish={onSearch}>
            <ListComponent
                scroll={{x: 1000}}
                classParent="m-0 p-0 col-md-12 mt-4"
                marginTop={'0'} marginBottom={'0'}
                loading={{spinning: isFetching, size: 'large'}}
                rowKey="id"
                dataSource={getDataSource(resultList || [], initData.modelItem)}
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                columns={mergedColumns}
                pagination={false}
                onChange={onTableChange}
                componentTableList={{}}
            />
        </Form>
    </>
}

export default Index

const EditableCell = ({
                          editing,
                          dataIndex,
                          title,
                          inputType,
                          record,
                          index,
                          children,
                          ...restProps
                      }) => {
    const inputNode = inputType === 'number' ? <InputNumber/> : <Input/>;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};
