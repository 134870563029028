import * as Types from '../types/Auth';

export const loginAction = (params) => ({type: Types.LOGIN_ACTION, params});
export const loginSuccess = (data) => ({type: Types.LOGIN_SUCCESS, data});

export const checkUserAction = (token) => ({type: Types.CHECK_USER_ACTION, token});
export const checkUserSuccess = (user) => ({type: Types.CHECK_USER_SUCCESS, user});

export const logoutAction = (params) => ({type: Types.LOGOUT_ACTION, params});
export const logoutSuccess = (data) => ({type: Types.LOGOUT_SUCCESS, data});

export const updateProfileAction = (params) => ({type: Types.UPDATE_PROFILE_ACTION, params});
export const updateProfileSuccess = (updateProfile) => ({type: Types.UPDATE_PROFILE_SUCCESS, updateProfile});

export const refreshTokenAction = (refreshToken) => ({type: Types.REFRESH_TOKEN_ACTION, refreshToken});
