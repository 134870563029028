import {createSelector} from 'reselect'

const stateReducer = state => state.subscriptionCustomerReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        customerReducer => customerReducer.isFetching
    )

export const selectErrors = (initItems) =>
    createSelector(
        stateReducer,
        customerReducer => customerReducer.errors
    )

export const selectListSubscriptionCustomer = (initItems) =>
    createSelector(
        stateReducer,
        customerReducer => customerReducer.data
    )

export const selectMasterData = (initItems) =>
    createSelector(
        stateReducer,
        customerReducer => customerReducer.masterData
    )

export const selectIsAction = (initItems) =>
    createSelector(
        stateReducer,
        customerReducer => customerReducer.isAction
    )

export const selectMasterState = (initItems) =>
    createSelector(
        stateReducer,
        customerReducer => customerReducer.masterState
    )
export const selectSubscriptionCustomerItem = (initItems) =>
    createSelector(
        stateReducer,
        customerReducer => customerReducer.item
    )
