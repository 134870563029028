export const SUBSCRIPTION_CUSTOMER_RECURRING_LIST_ACTION = "SUBSCRIPTION_CUSTOMER_RECURRING_LIST_ACTION";
export const SUBSCRIPTION_CUSTOMER_RECURRING_LIST_SUCCESS = "SUBSCRIPTION_CUSTOMER_RECURRING_LIST_SUCCESS";

export const SUBSCRIPTION_CUSTOMER_RECURRING_MASTER_ACTION = "SUBSCRIPTION_CUSTOMER_RECURRING_MASTER_ACTION";
export const SUBSCRIPTION_CUSTOMER_RECURRING_MASTER_SUCCESS = "SUBSCRIPTION_CUSTOMER_RECURRING_MASTER_SUCCESS";

export const SUBSCRIPTION_CUSTOMER_RECURRING_GET_TEM_ACTION = "SUBSCRIPTION_CUSTOMER_RECURRING_GET_TEM_ACTION";
export const SUBSCRIPTION_CUSTOMER_RECURRING_GET_TEM_SUCCESS = "SUBSCRIPTION_CUSTOMER_RECURRING_GET_TEM_SUCCESS";

export const SUBSCRIPTION_CUSTOMER_RECURRING_UPDATE_ACTION = "SUBSCRIPTION_CUSTOMER_RECURRING_UPDATE_ACTION";
export const SUBSCRIPTION_CUSTOMER_RECURRING_UPDATE_SUCCESS = "SUBSCRIPTION_CUSTOMER_RECURRING_UPDATE_SUCCESS";

export const SUBSCRIPTION_CUSTOMER_RECURRING_CREATE_ACTION = "SUBSCRIPTION_CUSTOMER_RECURRING_CREATE_ACTION";
export const SUBSCRIPTION_CUSTOMER_RECURRING_CREATE_SUCCESS = "SUBSCRIPTION_CUSTOMER_RECURRING_CREATE_SUCCESS";

export const SUBSCRIPTION_CUSTOMER_RECURRING_MASTER_STATE_ACTION = "SUBSCRIPTION_CUSTOMER_RECURRING_MASTER_STATE_ACTION";
export const SUBSCRIPTION_CUSTOMER_RECURRING_MASTER_STATE_SUCCESS = "SUBSCRIPTION_CUSTOMER_RECURRING_MASTER_STATE_SUCCESS";

export const SUBSCRIPTION_CUSTOMER_RECURRING_INIT = "SUBSCRIPTION_CUSTOMER_RECURRING_INIT";
export const SUBSCRIPTION_CUSTOMER_RECURRING_FAILED = "SUBSCRIPTION_CUSTOMER_RECURRING_FAILED";
