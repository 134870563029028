import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/subscriptionPackageAction'

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        () => dispatch(HookAction.subscriptionPackageInit()),
        [dispatch]
    )
}

export const ActionGetListSubscriptionPackage = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.subscriptionPackageListAction(filters)),
        [dispatch]
    )
}

export const ActionGetItem = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.subscriptionPackageGetItemAction(params)),
        [dispatch]
    )
}

export const ActionMasterData = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.subscriptionPackageMasterDataAction(params)),
        [dispatch]
    )
}

// export const ActionCreatePackage = () => {
//     const dispatch = useDispatch()
//     return useCallback(
//         (params) => dispatch(HookAction.subscriptionPackageCreateAction(params)),
//         [dispatch]
//     )
// }

export const ActionUpdatePackage = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.subscriptionPackageUpdateAction(params)),
        [dispatch]
    )
}
