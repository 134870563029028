import * as Types from '../types/SubscriptionCancelationFeedback';

export const subscriptionCancelationFeedbackListAction = (filters) => ({
    type: Types.SUBSCRIPTION_CANCELATION_FEEDBACK_LIST_ACTION,
    filters
});
export const subscriptionCancelationFeedbackListSuccess = (listFeedback) => ({
    type: Types.SUBSCRIPTION_CANCELATION_FEEDBACK_LIST_SUCCESS,
    listFeedback
});

export const subscriptionCancelationFeedbackInit = (refreshToken) => ({
    type: Types.SUBSCRIPTION_CANCELATION_FEEDBACK_INIT,
    refreshToken
});
export const subscriptionCancelationFeedbackFail = (refreshToken) => ({
    type: Types.SUBSCRIPTION_CANCELATION_FEEDBACK_FAILED,
    refreshToken
});
