import {useCallback} from 'react'
import {useDispatch} from 'react-redux'

import * as HookAction from '../actions/subscriptionCancelationFeedbackAction'

export const ActionInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        () => dispatch(HookAction.subscriptionCancelationFeedbackInit()),
        [dispatch]
    )
}

export const ActionGetListSubscriptionCancelationFeedback = () => {
    const dispatch = useDispatch()
    return useCallback(
        (filters) => dispatch(HookAction.subscriptionCancelationFeedbackListAction(filters)),
        [dispatch]
    )
}
