const Define = {
    GENERAL: {
        ROLE_ID: {
            ADMIN: "00000000-0000-0000-0000-000000000001",
            STAFF: "00000000-0000-0000-0000-000000000002",
            CUSTOMER: "00000000-0000-0000-0000-000000000003",
            CUSTOMER_SERVICE: "00000000-0000-0000-0000-000000000004",
        },
        CDN_IMG_DEFAULT: "http://",
        // CDN_IMG_DEFAULT: 'http://s3-ap-southeast-1.amazonaws.com/vin-evoucher-static-development/images/',
        ROLE_NAME: {
            "00000000-0000-0000-0000-000000000001": "Quản trị viên",
            "00000000-0000-0000-0000-000000000002": "Nhân viên",
            "00000000-0000-0000-0000-000000000003": "Khách hàng",
            "00000000-0000-0000-0000-000000000004": "Chăm sóc khách hàng",
        },
        VOUCHER_TYPE: {
            PERCENT: "percent",
            GIFT: "gift",
            MONEY: "money"
        },
        PRODUCT_TYPE: {
            VINPEARL_LAND: "00000000-0000-0000-0000-000000000001"
        },
        LOG_VOUCHER_ACTION: {
            1: "Checked",
            2: "Sold",
            3: "Used",
            4: "Locked",
            5: "Unlocked"
        },
        ORDER_TYPE_DETAIL: {
            VOUCHER: 1,
            TOUR: 2
        },
        MENU_STAFF: ["/vouchers/check", "/403"],
        PERMISSION: {
            VIEW: "view",
            CREATE: "create",
            UPDATE: "update",
            DELETE: "delete",
            APPROVE: "approve",
            RESEND_EMAIL: "resendEmail",
            CHECK_VOUCHER: "checkVoucher",
            SALE_ORDER: "saleOrder",
            USE_VOUCHER: "useVoucher"
        },
        ROUTE_NAME: {
            HOME: 'home',
            USER: 'users',
            ROLE: 'roles',
            TOUR: 'tours',
            TOUR_MANAGEMENT: 'tour-management',
            SUPPLIER: 'suppliers',
            SUPPLIER_PRODUCT: 'supplierproducts',
            TICKET_MANAGEMENT: 'ticket-management',
            TICKET: 'ticket',
            MY_TICKET: 'myticket',
            TICKET_ASSIGN_CONFIG: 'ticketAssignConfig',
            BANNER: 'banners',
            ORDER: 'orders',
            VOUCHER_MANAGEMENT: 'voucher-management',
            PRODUCT: 'products',
            PROPERTY: 'properties',
            GROUP: 'groups',
            GROUP_CATEGORY: 'voucherCategories',
            VOUCHER: 'vouchers',
            CHECK_VOUCHER: 'checkVoucher',
            HOTEL_MANAGEMENT: 'hotel-management',
            HOTEL: 'hotels',
            ROOM_TYPE_MANAGEMENT: "room-type-management",
            ROOM_TYPE: "room-types",
            LOCATION: "locations",
            CUSTOM_PRICE: 'prices',
            CUSTOM_PRICE_MANAGIMENT: 'price-management',
            POPULAR_PLACE: "popular-places",
            CONTENT_MANAGEMENT: 'content-management',
            CONTENT: 'contents',
            CATEGORY: 'categories',
            CATEGORY_MANAGEMENT: 'category-management',
            PAGE: 'pages',
            PAGE_MANAGEMENT: 'page-management',
            POST: 'posts',
            POST_MANAGEMENT: 'post-management',
            RATING: 'ratings',
            RATING_MANAGEMENT: 'rating-management',
            PRICE_MAKEUP: 'priceMakeup',
            PRICE_MAKEUP_MANAGEMENT: 'priceMakeup-management',
            PRICE_MAKEUP_FLIGHT: 'priceMakeupFlight',
            PRICE_MAKEUP_FLIGHT_MANAGEMENT: 'priceMakeupFlight-management',
            REPORT_MANAGEMENT: 'report-management',
            REPORT: 'reports',
            SALE_REPORT: 'saleReports',
            VOUCHER_REDEEMTION_REPORT: 'redemptionReports',
        }
    },
};
export default Define;

export const FILES = {
    CDN_IMG_DEFAULT: "",
    SIZE_THUMB: "200x"
};

export const UPLOAD_DIRECTORY = {
    GENERAL: 'general',
    GROUP_THUMB: 'group_thumb',
    IMAGES: 'images',
    TOUR_THUMB: 'tours',
    HOTEL_THUMBS: 'hotel_thumbs',
    HOTEL: 'hotels',
    POPULAR_PLACE: 'popularPlaces',
    PAGE: "page_thumb",
    POST: "post_thumb",
    ROOM_TYPES: 'room_types',
    LOCATION: 'locations',
    SUPPLIER: 'suppliers',
};

export const IMAGE_DIRECTORY_TYPES = {
    VOUCHER_TYPE_PRICE: {},
    THUMB: {id: 0, INCLUDE_DIRECTORIES: []},
    SLIDE: {
        id: 1, INCLUDE_DIRECTORIES: [
            UPLOAD_DIRECTORY.TOUR_THUMB,
            UPLOAD_DIRECTORY.IMAGES,
            UPLOAD_DIRECTORY.ROOM_TYPES,
        ]
    },
};

export const UPLOAD = {
    MAX_FILE_SIZE: 10 * 1024 * 1024, // 5MB
}

export const PATHS = {
    ROOT: {path: '/'},
    // AUTHENTICATION_NOT_FOUND: {path: `${Config.API_BASE_URL}/auth/login`},
    LOGIN: {path: '/login'},
    LOGOUT: {path: '/logout'},
    PROFILE: {path: '/profile'},
    REFRESH: {path: '/refresh'},
    PAGE_403: {path: '/403'},
};

export const CONFIG_LOCAL_STORAGE = {
    PREFIX: 'hn',
    COMMON_ACCESS_TOKEN: 'hn_utk',
    ACCESS_TOKEN: 'utk',
    REFRESH_TOKEN: 'rft',
    EXPIRES: 'expires',
    PROFILE: 'pf',
    MENU_COLLAPSED: 'menu_collapsed',
    CONTENT_LANGUAGE: 'content_language',
    TOKEN_V1: "connect.sid"
};

export const DATE_TIME_FORMAT = {
    MINIMUM_YEAR: 1970,
    SHORT_DATE: 'DD/MM/YYYY',
    SHORT_DATE_EXPORT: 'YYYY-MM-DD',
    DATE_TIME: 'DD/MM/YYYY HH:mm',
    EN_DATE_TIME: 'YYYY-MM-DD HH:mm:ss',
    DAY_WEEK_DATETIME: 'dddd, DD/MM/YYYY HH:mm',
    TIME_AND_DATE: 'HH:mm DD/MM/YYYY',
    SHORT_TIME: 'HH:mm',
    FULL_TIME: 'HH:mm:ss',
    SHORT_MONTH: 'MM/YYYY',
}

export const PAGINATION = {
    page_index: 1,
    page_size: 10,
    total: 0,
    total_page: 1,
}

export const SAGA_DELAY_TIMEOUT = 300; // delay 300ms on every saga

export const COUNTRY_CODE = {
    VN: 'VN',
    US: 'US',
}

export const REFRESH_TOKEN_DELAY_TIMEOUT = (5 * 60 * 1000); // 5 minutes before the access token was expired

export const DETAIL_ACTIONS = {
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    TRANSLATE: 'TRANSLATE',
}
