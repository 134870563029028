export const USER_LIST_ACTION = "USER_LIST_ACTION";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_CREATE_ACTION = "USER_CREATE_ACTION";
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";
export const USER_UPDATE_ACTION = "USER_UPDATE_ACTION";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_MASTER_DATA_ACTION = "USER_MASTER_DATA_ACTION";
export const USER_MASTER_DATA_SUCCESS = "USER_MASTER_DATA_SUCCESS";
export const USER_GET_TEM_ACTION = "USER_GET_TEM_ACTION";
export const USER_GET_TEM_SUCCESS = "USER_GET_TEM_SUCCESS";
export const USER_INIT = "USER_INIT";
export const USER_FAILED = "USER_FAILED";
