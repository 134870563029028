import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";

export default class DashboardResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.data = data?.data || [];
        this.options = data?.options || {}
    }

    exportUserActivity() {
        let items = [];
        if (this.data.length < 2) {
            items = [];
        } else if (this.data.length < 3) {
            items = this.data;
            items.push([" ", ""])
        } else {
            items = this.data;
        }
        return {
            data: items,
            options: this.options
        }
    }

    exportCityFilter() {
        let items = [];
        if (this.data.length < 2) {
            items = [];
        } else if (this.data.length < 3) {
            items = this.data;
            items.push([" ", ""])
        } else {
            items = this.data;
        }
        return {
            data: items,
            options: this.options
        }
    }

}
