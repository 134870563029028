import * as Types from '../types/SubscriptionCustomPackage';

export const subscriptionCustomPackageListAction = (filters) => ({
    type: Types.SUBSCRIPTION_CUSTOM_PACKAGE_LIST_ACTION,
    filters
});
export const subscriptionCustomPackageListSuccess = (subscriptionCustomPackages) => ({
    type: Types.SUBSCRIPTION_CUSTOM_PACKAGE_LIST_SUCCESS,
    subscriptionCustomPackages
});

export const subscriptionCustomPackageCreateAction = (params) => ({
    type: Types.SUBSCRIPTION_CUSTOM_PACKAGE_CREATE_ACTION,
    params
});
export const subscriptionCustomPackageCreateSuccess = (createCustomPackage) => ({
    type: Types.SUBSCRIPTION_CUSTOM_PACKAGE_CREATE_SUCCESS,
    createCustomPackage
});

export const subscriptionCustomPackageUpdateAction = (params) => ({
    type: Types.SUBSCRIPTION_CUSTOM_PACKAGE_UPDATE_ACTION,
    params
});
export const subscriptionCustomPackageUpdateSuccess = (updateCustomPackage) => ({
    type: Types.SUBSCRIPTION_CUSTOM_PACKAGE_UPDATE_SUCCESS,
    updateCustomPackage
});

export const subscriptionCustomPackageMasterDataAction = (params) => ({
    type: Types.SUBSCRIPTION_CUSTOM_PACKAGE_MASTER_ACTION,
    params
});
export const subscriptionCustomPackageMasterDataSuccess = (masterData) => ({
    type: Types.SUBSCRIPTION_CUSTOM_PACKAGE_MASTER_SUCCESS,
    masterData
});

export const subscriptionCustomPackageGetItemAction = (params) => ({
    type: Types.SUBSCRIPTION_CUSTOM_PACKAGE_GET_TEM_ACTION,
    params
});
export const subscriptionCustomPackageGetItemSuccess = (item) => ({
    type: Types.SUBSCRIPTION_CUSTOM_PACKAGE_GET_TEM_SUCCESS,
    item
});

export const subscriptionCustomPackageInit = (refreshToken) => ({
    type: Types.SUBSCRIPTION_CUSTOM_PACKAGE_INIT,
    refreshToken
});
export const subscriptionCustomPackageFail = (refreshToken) => ({
    type: Types.SUBSCRIPTION_CUSTOM_PACKAGE_FAILED,
    refreshToken
});
