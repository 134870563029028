import {Button, DatePicker, Form, Input, message, Modal, Select, Spin, Tag} from "antd";
import React, {useEffect, useState} from "react";
import {Icon} from "../../../../common";
import moment from "moment"
import {common} from "../../../../../data/mapping/Request/UserRequest";
import {isEmail} from "../../../../../utils/validation";

const AddSubscription = props => {
    const [form] = Form.useForm();
    const [listMonths, setListMonths] = useState([]);

    useEffect(() => {
        if (props.isOpenCreate) {
            form.resetFields();
            setListMonths([])
        }
    }, [props.isOpenCreate]);


    const onCreate = val => {
        props.actionCreate(val)
    }

    const onValuesChange = e => {
        // if (e?.month) {
        //     let newDate = moment(e.month).format("YYYYMM")
        //     if (!listMonth.includes(newDate)) {
        //         listMonth.push(newDate)
        //         console.log(321, listMonth)
        //         form.setFieldsValue({months: listMonth})
        //     }
        // }
    }


    return (
        <Modal
            // width={1000}
            title="Add Subscription"
            destroyOnClose
            visible={props.isOpenCreate}
            onOk={() => form.submit()}
            onCancel={() => props.setIsOpenCreate(false)}
            maskClosable={false}
        >
            <Spin spinning={props.itemIsAction}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onCreate}
                    onValuesChange={onValuesChange}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <div className="row bg-white align-items-center">
                        <div className="col-12">
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {required: true, message: "Please input your Email!"},
                                    {
                                        type: 'email',
                                        message: "Invalid email",
                                    }
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </div>
                        <div className="col-12">
                            <Form.Item
                                label="Package"
                                name="package_id"
                                rules={[{required: true, message: "Package is required"}]}
                            >
                                <Select
                                    className="w-100"
                                    allowClear
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        (Object.keys(props.masterData?.packages || {})).length > 0 && (Object.keys(props.masterData?.packages || {})).map((i, k) => {
                                            return (
                                                <Select.Option value={i}
                                                               key={k}>{props.masterData?.packages[i]}</Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-12">
                            <Form.Item
                                label="Valid Time"
                                name="valid_time"
                            >
                                <DatePicker
                                    // picker="month"
                                    className="w-100"
                                />
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </Spin>
        </Modal>
    )
}

export default AddSubscription
