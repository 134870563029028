import * as Types from '../types/Hotel';
import {PAGINATION} from '../../constants/define';
// import HotelRequest from "../mapping/Request/HotelRequest";

const initialState = {
    isSubmit: false,
    isModal: false,
    isFail: false,
    isGetItem: false,
    isUpdate: false,
    isMasterFetching: false,
    all: [],
    // item: new HotelRequest().update(),
    data: {
        result: [],
        page_size: PAGINATION.page_size,
        page_index: PAGINATION.page_index,
        total: PAGINATION.total,
        total_page: PAGINATION.total_page,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case Types.HOTEL_LIST_ACTION:
            return {...state, isFetching: true, isUpdate: false, item: initialState.item};
        case Types.HOTEL_GET_ITEM_ACTION:
            return {...state, isFetching: true, isUpdate: true, item: initialState.item};
        case Types.HOTEL_ALL_ACTION:
            return {...state, isFetching: true};
        case Types.HOTEL_CREATE_ACTION:
            return {...state, isFetching: true, isSubmit: true};
        case Types.HOTEL_UPDATE_ACTION:
            return {...state, isFetching: true, isSubmit: true};
        case Types.HOTEL_STATUS_ACTION:
            return {...state, isFetching: true, isSubmit: true};
        case Types.HOTEL_DELETE_ACTION:
            return {...state, isFetching: true};
        case Types.HOTEL_ALL_SUCCESS:
            return {...state, isFetching: false, all: action.all, isSubmit: false};
        case Types.HOTEL_GET_ITEM_SUCCESS:
            return {...state, isFetching: false, item: action.item, isSubmit: false, isUpdate: true};
        case Types.HOTEL_LIST_SUCCESS:
            return {...state, isFetching: false, data: action.data, isSubmit: false};
        case Types.HOTEL_FAIL:
            return initialState;
        case Types.HOTEL_STATUS_SUCCESS:
            return {...state, isFetching: false, isSubmit: false, isFail: true};
        case Types.HOTEL_MASTER_ACTION:
            return {...state, isMasterFetching: true};
        case Types.HOTEL_MASTER_SUCCESS:
            return {...state, isMasterFetching: false, all: action.all};
        default:
            return state;
    }
}
