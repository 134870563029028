import HeaderAction from "../../common/layout/HeaderAction";
import React, {useEffect, useState} from "react";
import {Button, Form, Input, InputNumber, Spin} from "antd";
import {Icon} from "../../common";
import {ActionConfigurationCma, ActionUpdateCmaConfiguration} from "../../../data/hooks/configuration";
import {selectConfigurationUpdateCma, selectFetching} from "../../../data/reselects/configurationSelector";
import {useSelector} from "react-redux";

const Index = props => {
    const [form] = Form.useForm(),
        [isFetching, setIsFetching] = useState(false),
        actionConfigurationCma = ActionConfigurationCma(),
        actionUpdateCmaConfiguration = ActionUpdateCmaConfiguration(),
        itemFetching = useSelector(selectFetching()),
        itemConfigurationCma = useSelector(selectConfigurationUpdateCma());

    useEffect(() => {
        actionConfigurationCma()
    }, []);

    useEffect(() => {
        form.setFieldsValue(itemConfigurationCma)
    }, [itemConfigurationCma]);

    console.log(87888, itemConfigurationCma)
    const onSearch = val => {
        actionUpdateCmaConfiguration({query: val})
    }

    return (
        <>
            <HeaderAction title="Configuration"/>
            <div className="container-fluid px-4">
                <Spin spinning={itemFetching}>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onSearch}
                        // onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <div className="row bg-white py-3 align-items-center">
                            <div className="col-6">
                                <Form.Item
                                    label="Distance (m)"
                                    name="distance"
                                >
                                    <InputNumber className="w-100" allowClear/>
                                </Form.Item>
                            </div>
                            <div className="col-6">
                                <Form.Item
                                    label="Recency (days)"
                                    name="recency"
                                >
                                    <InputNumber className="w-100" allowClear/>
                                </Form.Item>
                            </div>
                            <div className="col-12 text-end">
                                <Form.Item className="text-end my-0" label="">
                                    <Button type="primary" htmlType="submit">
                                        Apply {itemFetching && <Icon type="sync-outlined" spin/>}
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </Spin>
            </div>
        </>
    )
}

export default Index
