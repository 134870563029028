import * as Types from '../types/Customer';

export const customerKillSessionAction = (params) => ({type: Types.CUSTOMER_KILL_SESSION_ACTION, params});
export const customerKillSessionSuccess = (killSession) => ({type: Types.CUSTOMER_KILL_SESSION_SUCCESS, killSession});

export const customerListAction = (filters) => ({type: Types.CUSTOMER_LIST_ACTION, filters});
export const customerListSuccess = (customers) => ({type: Types.CUSTOMER_LIST_SUCCESS, customers});

export const customerCreateAction = (params) => ({type: Types.CUSTOMER_CREATE_ACTION, params});
export const customerCreateSuccess = (createCustomer) => ({type: Types.CUSTOMER_CREATE_SUCCESS, createCustomer});

export const customerUpdateAction = (params) => ({type: Types.CUSTOMER_UPDATE_ACTION, params});
export const customerUpdateSuccess = (updateCustomer) => ({type: Types.CUSTOMER_UPDATE_SUCCESS, updateCustomer});

export const customerMasterDataAction = (params) => ({type: Types.CUSTOMER_MASTER_DATA_ACTION, params});
export const customerMasterDataSuccess = (masterData) => ({type: Types.CUSTOMER_MASTER_DATA_SUCCESS, masterData});

export const customerGetItemAction = (params) => ({type: Types.CUSTOMER_GET_TEM_ACTION, params});
export const customerGetItemSuccess = (item) => ({type: Types.CUSTOMER_GET_TEM_SUCCESS, item});

export const customerTeamPointListAction = (filters) => ({type: Types.CUSTOMER_TEAM_POINT_LIST_ACTION, filters});
export const customerTeamPointListSuccess = (teamPoints) => ({
    type: Types.CUSTOMER_TEAM_POINT_LIST_SUCCESS,
    teamPoints
});

export const customerInit = (refreshToken) => ({type: Types.CUSTOMER_INIT, refreshToken});
export const customerFail = (refreshToken) => ({type: Types.CUSTOMER_FAILED, refreshToken});
