export const LOGIN_ACTION = "LOGIN_ACTION";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const LOGOUT_ACTION = "LOGOUT_ACTION";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REFRESH_TOKEN_ACTION = "REFRESH_TOKEN_ACTION";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";

export const CHECK_USER_ACTION = "CHECK_USER_ACTION";
export const CHECK_USER_SUCCESS = "CHECK_USER_SUCCESS";

export const UPDATE_PROFILE_ACTION = "UPDATE_PROFILE_ACTION";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
