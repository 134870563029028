import * as Types from '../types/SubscriptionCustomerRecurring';

export const subscriptionCustomerRecurringListAction = (filters) => ({
    type: Types.SUBSCRIPTION_CUSTOMER_RECURRING_LIST_ACTION,
    filters
});
export const subscriptionCustomerRecurringListSuccess = (subscriptionCustomerRecurring) => ({
    type: Types.SUBSCRIPTION_CUSTOMER_RECURRING_LIST_SUCCESS,
    subscriptionCustomerRecurring
});

export const subscriptionCustomerRecurringCreateAction = (params) => ({
    type: Types.SUBSCRIPTION_CUSTOMER_RECURRING_CREATE_ACTION,
    params
});
export const subscriptionCustomerRecurringCreateSuccess = (createCustomerRecurring) => ({
    type: Types.SUBSCRIPTION_CUSTOMER_RECURRING_CREATE_SUCCESS,
    createCustomerRecurring
});

export const subscriptionCustomerRecurringMasterStateAction = (params) => ({
    type: Types.SUBSCRIPTION_CUSTOMER_RECURRING_MASTER_STATE_ACTION,
    params
});
export const subscriptionCustomerRecurringMasterStateSuccess = (masterState) => ({
    type: Types.SUBSCRIPTION_CUSTOMER_RECURRING_MASTER_STATE_SUCCESS,
    masterState
});

export const subscriptionCustomerRecurringUpdateAction = (params) => ({
    type: Types.SUBSCRIPTION_CUSTOMER_RECURRING_UPDATE_ACTION,
    params
});
export const subscriptionCustomerRecurringUpdateSuccess = (updateCustomerRecurring) => ({
    type: Types.SUBSCRIPTION_CUSTOMER_RECURRING_UPDATE_SUCCESS,
    updateCustomerRecurring
});

export const subscriptionCustomerRecurringMasterAction = (params) => ({
    type: Types.SUBSCRIPTION_CUSTOMER_RECURRING_MASTER_ACTION,
    params
});
export const subscriptionCustomerRecurringMasterSuccess = (masterData) => ({
    type: Types.SUBSCRIPTION_CUSTOMER_RECURRING_MASTER_SUCCESS,
    masterData
});

export const subscriptionCustomerRecurringGetItemAction = (params) => ({
    type: Types.SUBSCRIPTION_CUSTOMER_RECURRING_GET_TEM_ACTION,
    params
});
export const subscriptionCustomerRecurringGetItemSuccess = (item) => ({
    type: Types.SUBSCRIPTION_CUSTOMER_RECURRING_GET_TEM_SUCCESS,
    item
});

export const subscriptionCustomerRecurringInit = (refreshToken) => ({
    type: Types.SUBSCRIPTION_CUSTOMER_RECURRING_INIT,
    refreshToken
});
export const subscriptionCustomerRecurringFail = (refreshToken) => ({
    type: Types.SUBSCRIPTION_CUSTOMER_RECURRING_FAILED,
    refreshToken
});
