import * as Types from '../types/SubscriptionGeneralPackage';

export const subscriptionGeneralPackageListAction = (filters) => ({
    type: Types.SUBSCRIPTION_GENERAL_PACKAGE_LIST_ACTION,
    filters
});
export const subscriptionGeneralPackageListSuccess = (subscriptionGeneralPackages) => ({
    type: Types.SUBSCRIPTION_GENERAL_PACKAGE_LIST_SUCCESS,
    subscriptionGeneralPackages
});

// export const subscriptionGeneralPackageCreateAction = (params) => ({type: Types.SUBSCRIPTION_GENERAL_PACKAGE_CREATE_ACTION, params});
// export const subscriptionGeneralPackageCreateSuccess = (createGeneralPackage) => ({
//     type: Types.SUBSCRIPTION_GENERAL_PACKAGE_CREATE_SUCCESS,
//     createGeneralPackage
// });

export const subscriptionGeneralPackageUpdateAction = (params) => ({
    type: Types.SUBSCRIPTION_GENERAL_PACKAGE_UPDATE_ACTION,
    params
});
export const subscriptionGeneralPackageUpdateSuccess = (updateGeneralPackage) => ({
    type: Types.SUBSCRIPTION_GENERAL_PACKAGE_UPDATE_SUCCESS,
    updateGeneralPackage
});

export const subscriptionGeneralPackageMasterDataAction = (params) => ({
    type: Types.SUBSCRIPTION_GENERAL_PACKAGE_MASTER_ACTION,
    params
});
export const subscriptionGeneralPackageMasterDataSuccess = (masterData) => ({
    type: Types.SUBSCRIPTION_GENERAL_PACKAGE_MASTER_SUCCESS,
    masterData
});

export const subscriptionGeneralPackageGetItemAction = (params) => ({
    type: Types.SUBSCRIPTION_GENERAL_PACKAGE_GET_TEM_ACTION,
    params
});
export const subscriptionGeneralPackageGetItemSuccess = (item) => ({
    type: Types.SUBSCRIPTION_GENERAL_PACKAGE_GET_TEM_SUCCESS,
    item
});

export const subscriptionGeneralPackageInit = (refreshToken) => ({
    type: Types.SUBSCRIPTION_GENERAL_PACKAGE_INIT,
    refreshToken
});
export const subscriptionGeneralPackageFail = (refreshToken) => ({
    type: Types.SUBSCRIPTION_GENERAL_PACKAGE_FAILED,
    refreshToken
});
