import { call, put, takeLatest, fork, all, delay } from 'redux-saga/effects';
import * as Types from '../types/Hotel';
import * as Func from "../../utils/functions";
import { parseListRequestParams } from '../../utils/commonUtils';
import {HotelService} from '../services';
import * as HotelActions from '../actions/hotelAction';
import {message} from "antd";

function* getHotels() {
    yield takeLatest(Types.HOTEL_LIST_ACTION, Func.sagaWrapper(function* (action) {
        const params = parseListRequestParams(action.filters || {});
        const result = yield call(HotelService.getHotels, params);
        yield put(HotelActions.listHotelSuccess(result));
    }, errorHandle(Types.HOTEL_FAIL)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e){
        yield put({ type: errorActionType })
    });
}

export function* hotelSaga() {
    yield all([
        fork(getHotels),
    ])
}
