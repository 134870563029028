import ApiService from '../../utils/ApiService';
import Urls from '../../utils/Urls';
import * as Func from '../../utils/functions';
import {removeObjectNull} from "../../utils/functions";
import UploadResponse from "../mapping/Response/UploadResponse";

export function upload(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.UPLOAD_FILE,
        parser: data => new UploadResponse(data.data.result).exportUpload(),
    }).post(data);
}
