import * as Types from '../types/User';

export const userListAction = (filters) => ({type: Types.USER_LIST_ACTION, filters});
export const userListSuccess = (users) => ({type: Types.USER_LIST_SUCCESS, users});

export const userCreateAction = (params) => ({type: Types.USER_CREATE_ACTION, params});
export const userCreateSuccess = (createUser) => ({type: Types.USER_CREATE_SUCCESS, createUser});

export const userUpdateAction = (params) => ({type: Types.USER_UPDATE_ACTION, params});
export const userUpdateSuccess = (updateUser) => ({type: Types.USER_UPDATE_SUCCESS, updateUser});

export const userMasterDataAction = (params) => ({type: Types.USER_MASTER_DATA_ACTION, params});
export const userMasterDataSuccess = (masterData) => ({type: Types.USER_MASTER_DATA_SUCCESS, masterData});

export const userGetItemAction = (params) => ({type: Types.USER_GET_TEM_ACTION, params});
export const userGetItemSuccess = (item) => ({type: Types.USER_GET_TEM_SUCCESS, item});

export const userInit = (refreshToken) => ({type: Types.USER_INIT, refreshToken});
export const userFail = (refreshToken) => ({type: Types.USER_FAILED, refreshToken});
