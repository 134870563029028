export const TICKET_LIST_ACTION = "TICKET_LIST_ACTION";
export const TICKET_LIST_SUCCESS = "TICKET_LIST_SUCCESS";
export const TICKET_COMMENT_ACTION = "TICKET_COMMENT_ACTION";
export const TICKET_COMMENT_SUCCESS = "TICKET_COMMENT_SUCCESS";
export const TICKET_UPDATE_ACTION = "TICKET_UPDATE_ACTION";
export const TICKET_UPDATE_SUCCESS = "TICKET_UPDATE_SUCCESS";
export const TICKET_MASTER_DATA_ACTION = "TICKET_MASTER_DATA_ACTION";
export const TICKET_MASTER_DATA_SUCCESS = "TICKET_MASTER_DATA_SUCCESS";
export const TICKET_GET_TEM_ACTION = "TICKET_GET_TEM_ACTION";
export const TICKET_GET_TEM_SUCCESS = "TICKET_GET_TEM_SUCCESS";
export const TICKET_INIT = "TICKET_INIT";
export const TICKET_FAILED = "TICKET_FAILED";
