import {createSelector} from 'reselect'

const stateReducer = state => state.subscriptionCustomPackageReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        subscriptionCustomPackageReducer => subscriptionCustomPackageReducer.isFetching
    )

export const selectStatusAction = (initData) =>
    createSelector(
        stateReducer,
        subscriptionCustomPackageReducer => subscriptionCustomPackageReducer.statusAction
    )

export const selectErrors = (initItems) =>
    createSelector(
        stateReducer,
        subscriptionCustomPackageReducer => subscriptionCustomPackageReducer.errors
    )

export const selectListSubscriptionCustomPackage = (initItems) =>
    createSelector(
        stateReducer,
        subscriptionCustomPackageReducer => subscriptionCustomPackageReducer.data
    )

export const selectMasterData = (initItems) =>
    createSelector(
        stateReducer,
        subscriptionCustomPackageReducer => subscriptionCustomPackageReducer.masterData
    )
export const selectSubscriptionCustomPackageItem = (initItems) =>
    createSelector(
        stateReducer,
        subscriptionCustomPackageReducer => subscriptionCustomPackageReducer.item
    )
