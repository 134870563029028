import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";

export default class SubscriptionCustomerResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.customer = data?.customer || "";
        this.package_type = data?.package_type || "";
        this.package_subscription_type = data?.package_subscription_type || "";
        this.package_period_type = data?.package_period_type || "";
        this.valid_time = data?.valid_time ? moment(data?.valid_time).format(DATE_TIME_FORMAT.EN_DATE_TIME) : "";
        this.order = data?.order || "";
        this.is_delete = data?.is_delete;
        this.product_type = data?.product_type;
        this.created_date = data?.created_date ? moment(data?.created_date).format(DATE_TIME_FORMAT.EN_DATE_TIME) : "";
    }

    exportList() {
        return {
            id: this.id,
            customer: this.customer,
            package_type: this.package_type,
            package_subscription_type: this.package_subscription_type,
            package_period_type: this.package_period_type,
            valid_time: this.valid_time,
            order: this.order,
            created_date: this.created_date,
            product_type: this.product_type,
            is_delete: this.is_delete,
        }
    }

    exportItem() {
        return {
            id: this.id,
            customer: this.customer,
            package_type: this.package_type,
            package_subscription_type: this.package_subscription_type,
            package_period_type: this.package_period_type,
            valid_time: this.valid_time,
            order: this.order,
            created_date: this.created_date,
        }
    }
}

export class MasterCustomerResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.packages = data?.packages || {};
    }

    exportListMaster() {
        return {
            packages: this.packages,
        }
    }

    exportListMasterState() {
        return {
            packages: this.packages,
        }
    }
}

export const listCols = ["customer", "product_type", "package_type", "package_subscription_type", "package_period_type", "valid_time", "order", "created_date"];
export const constants = {
    status: ["Inactive", "Active"]
};
