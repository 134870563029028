import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import * as HookAction from '../actions/dataAction'

export const ActionDataInit = () => {
    const dispatch = useDispatch()
    return useCallback(
        () => dispatch(HookAction.dataInit()),
        [dispatch]
    )
}

export const ActionMasterDataLeadType = () => {
    const dispatch = useDispatch()
    return useCallback(
        (params) => dispatch(HookAction.dataMasterLeadTypeAction(params)),
        [dispatch]
    )
}
