import * as Types from '../types/SubscriptionPackage';

export const subscriptionPackageListAction = (filters) => ({type: Types.SUBSCRIPTION_PACKAGE_LIST_ACTION, filters});
export const subscriptionPackageListSuccess = (subscriptionPackages) => ({
    type: Types.SUBSCRIPTION_PACKAGE_LIST_SUCCESS,
    subscriptionPackages
});

// export const subscriptionPackageCreateAction = (params) => ({type: Types.SUBSCRIPTION_PACKAGE_CREATE_ACTION, params});
// export const subscriptionPackageCreateSuccess = (createPackage) => ({
//     type: Types.SUBSCRIPTION_PACKAGE_CREATE_SUCCESS,
//     createPackage
// });

export const subscriptionPackageUpdateAction = (params) => ({type: Types.SUBSCRIPTION_PACKAGE_UPDATE_ACTION, params});
export const subscriptionPackageUpdateSuccess = (updatePackage) => ({
    type: Types.SUBSCRIPTION_PACKAGE_UPDATE_SUCCESS,
    updatePackage
});

export const subscriptionPackageMasterDataAction = (params) => ({
    type: Types.SUBSCRIPTION_PACKAGE_MASTER_ACTION,
    params
});
export const subscriptionPackageMasterDataSuccess = (masterData) => ({
    type: Types.SUBSCRIPTION_PACKAGE_MASTER_SUCCESS,
    masterData
});

export const subscriptionPackageGetItemAction = (params) => ({
    type: Types.SUBSCRIPTION_PACKAGE_GET_TEM_ACTION,
    params
});
export const subscriptionPackageGetItemSuccess = (item) => ({type: Types.SUBSCRIPTION_PACKAGE_GET_TEM_SUCCESS, item});

export const subscriptionPackageInit = (refreshToken) => ({type: Types.SUBSCRIPTION_PACKAGE_INIT, refreshToken});
export const subscriptionPackageFail = (refreshToken) => ({type: Types.SUBSCRIPTION_PACKAGE_FAILED, refreshToken});
