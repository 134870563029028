import {pick} from "lodash";
import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define"
import {formatInt, removeObjectNullFull} from "../../../utils/functions";

export default class SubscriptionCustomerRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.email = data?.email || "";
        this.package_id = data?.package_id || "";
        this.valid_time = data?.valid_time || "";
    }

    exportUpdate() {
        return {
            id: this.id,
            // status: this.status,
        }
    }

    exportCreate() {
        return {
            email: this.email,
            package_id: this.package_id,
            valid_time: this.valid_time ? moment(this.valid_time).format(DATE_TIME_FORMAT.SHORT_DATE_EXPORT) : "",
        }
    }

}

export const common = {
    ACTION_FIELD: {
        email: "email",
        package_id: "package_id",
        valid_time: "valid_time",
    },
}

export const filtersParams = (filters) => {
    return Object.keys(filters).length > 0 ? removeObjectNullFull({
        valid_time_from: filters?.valid_time_from ? moment(filters?.valid_time_from).format(DATE_TIME_FORMAT.SHORT_DATE_EXPORT) : undefined,
        valid_time_to: filters?.valid_time_to ? moment(filters?.valid_time_to).format(DATE_TIME_FORMAT.SHORT_DATE_EXPORT) : undefined,
        search: filters?.search || "",
        is_export: filters?.is_export || "",
        page_size: filters?.page_size || 10,
        page_index: filters?.page_index || 1,
        order_field: filters?.order_field || "",
        order_value: filters?.order_value || "",
    }) : {}
}

