import {createSelector} from 'reselect'

const stateReducer = state => state.subscriptionCancelationFeedbackReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        subscriptionCancelationFeedbackReducer => subscriptionCancelationFeedbackReducer.isFetching
    )

export const selectErrors = (initItems) =>
    createSelector(
        stateReducer,
        subscriptionCancelationFeedbackReducer => subscriptionCancelationFeedbackReducer.errors
    )

export const selectListSubscriptionCancelationFeedback = (initItems) =>
    createSelector(
        stateReducer,
        subscriptionCancelationFeedbackReducer => subscriptionCancelationFeedbackReducer.data
    )
