import React, {useState, useEffect} from "react";
import {useSelector} from 'react-redux'

// ============== LAYOUT ==============
import {Form, Input, Button, Select, Spin, Tooltip, message} from 'antd';
import HeaderAction from "../../../../common/layout/HeaderAction";

// ============== ACTION ==============
import {
    ActionMasterData,
    ActionInit,
    ActionUpdateSubscriptionCustomer,
    ActionGetItem
} from "../../../../../data/hooks/subscriptionCustomer";

// ============== SELECT DATA ==============
// import {selectMasterData, selectUserItem} from "../../../../../data/reselects/userSelector";
// import {selectFetching, selectCustomerItem} from "../../../../../data/reselects/customerSelector";
import {
    selectFetching,
    selectSubscriptionCustomerItem,
    selectMasterData
} from "../../../../../data/reselects/subscriptionCustomerSelector";
import {common} from "../../../../../data/mapping/Request/SubscriptionCustomerRequest";
import {Icon} from "../../../../common";
import {isEmail} from "../../../../../utils/validation";
import PERMISSION from "../../../../../constants/permission";
import {CopyOutlined} from "@ant-design/icons";

const Index = (props) => {
    const [form] = Form.useForm(),
        [isFetching, setIsFetching] = useState(false),
        [masterRoles, setMasterRoles] = useState({}),
        [masterStatus, setMasterStatus] = useState({}),
        [isUpdate, setIsUpdate] = useState(false),
        // ============== ACTION ==============
        getMasterData = ActionMasterData(),
        // getCreateUser = ActionCreateUser(),
        getUpdateSubscriptionCustomer = ActionUpdateSubscriptionCustomer(),
        getInitAction = ActionInit(),
        getItem = ActionGetItem(),
        // ============== SELECT DATA ==============
        itemFetching = useSelector(selectFetching()),
        itemDetail = useSelector(selectSubscriptionCustomerItem()),
        itemMasterData = useSelector(selectMasterData());

    useEffect(() => {
        const {match} = props;
        if (Object.keys(match.params).length > 0) {
            setIsUpdate(true);
            if (Object.keys(itemDetail).length < 1) {
                getItem({id: match.params?.id, props: props});
            }
        } else {
            setIsUpdate(false)
        }
    }, [props.match]);

    useEffect(() => {
        if (Object.keys(itemMasterData).length < 1) {
            getMasterData()
        }

        return () => {
            getInitAction();
        }
    }, []);

    useEffect(() => {
        setIsFetching(itemFetching)
    }, [itemFetching]);

    useEffect(() => {
        if (Object.keys(itemDetail).length > 0) {
            form.setFieldsValue(itemDetail)
        }
    }, [itemDetail]);

    useEffect(() => {
        if (Object.keys(itemMasterData?.roles || {}).length > 0) {
            setMasterRoles(itemMasterData?.roles)
        }
        if (Object.keys(itemMasterData?.status || {}).length > 0) {
            setMasterStatus(itemMasterData?.status)
        }
    }, [itemMasterData]);

    const onFinish = (val) => {
        if (isUpdate) {
            getUpdateSubscriptionCustomer({...val, props, id: itemDetail?.id})
        } else {
            // getCreateUser({...val, props})
        }
    }

    return (
        <>
            <HeaderAction title="Subscription Customer Update" disabledFormatUpperCaseTitle/>
            <Spin tip="Loading..." spinning={isFetching}>
                <div className="container bg-white mt-4 p-4">
                    <div className="row">
                        <div className="col-12">
                            <Form
                                form={form}
                                layout="vertical"
                                onFinish={onFinish}
                                // onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <div className="row">
                                    <div className="col-4">
                                        <Form.Item
                                            label="Email"
                                            name={common.ACTION_FIELD.email}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your Email!'
                                                }, ({getFieldValue}) => ({
                                                    validator(rule, value) {
                                                        if ((itemDetail[common.ACTION_FIELD.email] === value)) {
                                                            return Promise.resolve();
                                                        }
                                                        if (value) {
                                                            if (!isEmail(value) && ((value || "").length >= 10)) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject("Invalid email");
                                                        }
                                                        return Promise.reject("Invalid email")
                                                    },
                                                })
                                            ]}
                                        >
                                            <Input disabled/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Valid Time"
                                            name={common.ACTION_FIELD.valid_time}
                                            rules={[{required: true}]}
                                        >
                                            <Input disabled/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Subscription Type"
                                            name={common.ACTION_FIELD.subscription_type}
                                            rules={[{required: true}]}
                                        >
                                            <Input disabled/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Lead Type"
                                            name={common.ACTION_FIELD.lead_type}
                                            rules={[{required: true}]}
                                        >
                                            <Input disabled/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="State"
                                            name={common.ACTION_FIELD.state}
                                            rules={[{required: true}]}
                                        >
                                            <Input disabled/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="County"
                                            name={common.ACTION_FIELD.county}
                                            rules={[{required: true}]}
                                        >
                                            <Input disabled/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Order"
                                            name={common.ACTION_FIELD.order_id}
                                            rules={[{required: true}]}
                                        >
                                            <Input
                                                readOnly
                                                suffix={
                                                    <Tooltip title="Coppy">
                                                        <CopyOutlined onClick={() => {
                                                            navigator.clipboard.writeText(form.getFieldValue(common.ACTION_FIELD.order_id));
                                                            message.success("Copied");
                                                        }} className="cursorPointer" style={{ color: 'rgba(0,0,0,.45)' }} />
                                                    </Tooltip>
                                                }
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Subscribe Date"
                                            name={common.ACTION_FIELD.subscribe_date}
                                            rules={[{required: true}]}
                                        >
                                            <Input disabled/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Status"
                                            name={common.ACTION_FIELD.status}
                                            rules={[{required: true, message: 'Please select your status!'}]}
                                        >
                                            <Select
                                                className="w-100"
                                                style={{width: 120}}>
                                                {
                                                    (Object.keys(masterStatus)).length > 0 && (Object.keys(masterStatus)).map((i, k) => {
                                                        return (
                                                            <Select.Option value={masterStatus[i]}
                                                                           key={k}>{i}</Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                                <Form.Item className="text-end">
                                    <Button type="default" htmlType="button" className="me-3"
                                            onClick={() => props.history.push(PERMISSION.SUBSCRIPTION.CUSTOMER_VIEW.route, {group: PERMISSION.SUBSCRIPTION.CUSTOMER_VIEW.group})}>
                                        Back
                                    </Button>
                                    <Button type="primary" htmlType="submit">
                                        Save {isFetching && <Icon type="sync-outlined" spin/>}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            </Spin>
        </>
    )
}

export default Index
