import * as Types from '../types/ExclusiveContent';

export const exclusiveContentPostListAction = (filters) => ({type: Types.EXCLUSIVE_CONTENT_POST_LIST_ACTION, filters});
export const exclusiveContentPostListSuccess = (exclusiveContentPosts) => ({
    type: Types.EXCLUSIVE_CONTENT_POST_LIST_SUCCESS,
    exclusiveContentPosts
});

export const exclusiveContentPostCreateAction = (params) => ({
    type: Types.EXCLUSIVE_CONTENT_POST_CREATE_ACTION,
    params
});
export const exclusiveContentPostCreateSuccess = (createExclusiveContentPost) => ({
    type: Types.EXCLUSIVE_CONTENT_POST_CREATE_SUCCESS,
    createExclusiveContentPost
});

export const exclusiveContentPostUpdateAction = (params) => ({
    type: Types.EXCLUSIVE_CONTENT_POST_UPDATE_ACTION,
    params
});
export const exclusiveContentPostUpdateSuccess = (updateExclusiveContentPost) => ({
    type: Types.EXCLUSIVE_CONTENT_POST_UPDATE_SUCCESS,
    updateExclusiveContentPost
});

export const exclusiveContentPostGetItemAction = (params) => ({
    type: Types.EXCLUSIVE_CONTENT_POST_GET_TEM_ACTION,
    params
});
export const exclusiveContentPostGetItemSuccess = (item) => ({
    type: Types.EXCLUSIVE_CONTENT_POST_GET_TEM_SUCCESS,
    item
});

export const exclusiveContentLayoutListAction = (filters) => ({
    type: Types.EXCLUSIVE_CONTENT_LAYOUT_LIST_ACTION,
    filters
});
export const exclusiveContentLayoutListSuccess = (exclusiveContentLayouts) => ({
    type: Types.EXCLUSIVE_CONTENT_LAYOUT_LIST_SUCCESS,
    exclusiveContentLayouts
});

export const exclusiveContentLayoutUpdateAction = (params) => ({
    type: Types.EXCLUSIVE_CONTENT_LAYOUT_UPDATE_ACTION,
    params
});
export const exclusiveContentLayoutUpdateSuccess = (updateExclusiveContentLayout) => ({
    type: Types.EXCLUSIVE_CONTENT_LAYOUT_UPDATE_SUCCESS,
    updateExclusiveContentLayout
});

export const exclusiveContentInit = (refreshToken) => ({type: Types.EXCLUSIVE_CONTENT_INIT, refreshToken});
export const exclusiveContentFail = (refreshToken) => ({type: Types.EXCLUSIVE_CONTENT_FAILED, refreshToken});
