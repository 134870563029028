import {createSelector} from 'reselect'

const stateReducer = state => state.configurationReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        resultReducer => resultReducer.isFetching
    )

export const selectErrors = (initItems) =>
    createSelector(
        stateReducer,
        resultReducer => resultReducer.errors
    )

export const selectConfigurationUpdateCma = (initItems) =>
    createSelector(
        stateReducer,
        resultReducer => resultReducer.configurationCma
    )

export const selectMasterData = (initItems) =>
    createSelector(
        stateReducer,
        resultReducer => resultReducer.masterData
    )
export const selectCompanyItem = (initItems) =>
    createSelector(
        stateReducer,
        resultReducer => resultReducer.item
    )
