import {call, put, takeLatest, fork, all, delay} from 'redux-saga/effects';
import * as Types from '../types/Verify';
import * as Func from "../../utils/functions";
import {parseListRequestParams} from '../../utils/commonUtils';
import * as VerifyService from '../services/verifyService';
import * as VerifyActions from '../actions/verifyAction';
import MessageCode from '../../constants/messageCode';
import {message} from "antd";
import history from "../../utils/history";
import {formatInt} from "../../utils/functions";

function* verifyRoute() {
    yield takeLatest(Types.VERIFY_ROUTE_ACTION, Func.sagaWrapper(function* (action) {
        const result = yield call(VerifyService.verifyRoute, action.params);
        yield put(VerifyActions.verifyRouteSuccess(result));
    }, errorHandle(Types.VERIFY_FAILED)))
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        // if ((e?.data?.errors || []).length > 0) {
        //     if (formatInt(e?.data?.errors[0]?.code) === MessageCode.VERIFY_ROUTE.ACCESS_DENIED) {
        //         console.log(111111111111)
        //         history.push("/403");
        //     }
        // }
        yield put({type: errorActionType, errors: e?.errors})
    });
}

export function* verifySaga() {
    yield all([
        fork(verifyRoute),
    ])
}
