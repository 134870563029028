import {createSelector} from 'reselect'

const stateReducer = state => state.subscriptionGeneralPackageReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        subscriptionGeneralPackageReducer => subscriptionGeneralPackageReducer.isFetching
    )

export const selectErrors = (initItems) =>
    createSelector(
        stateReducer,
        subscriptionGeneralPackageReducer => subscriptionGeneralPackageReducer.errors
    )

export const selectListSubscriptionGeneralPackage = (initItems) =>
    createSelector(
        stateReducer,
        subscriptionGeneralPackageReducer => subscriptionGeneralPackageReducer.data
    )

export const selectMasterData = (initItems) =>
    createSelector(
        stateReducer,
        subscriptionGeneralPackageReducer => subscriptionGeneralPackageReducer.masterData
    )
export const selectSubscriptionPackageItem = (initItems) =>
    createSelector(
        stateReducer,
        subscriptionGeneralPackageReducer => subscriptionGeneralPackageReducer.item
    )
