import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";
import {v4 as uuidv4} from 'uuid';

export default class SubscriptionCustomerRecurringResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.email = data?.email || "";
        this.recurring_date = data?.recurring_date || "";
        this.price = data?.price || "";
        this.item = this.generateItems(data?.item || []);
        this.status = data?.status || 0
        this.next_payment_date = data?.next_payment_date || "";
        this.history = this.generateHistory(data?.history || []);
    }

    generateItems(items = []) {
        let newItems = [];
        if (items.length > 0) {
            items.map((i, k) => {
                newItems.push({
                    key: k,
                    price: i?.price || "",
                    package: i?.package || "",
                    id: i?.id || "",
                    item_id: uuidv4(),
                })
            })
        }
        return newItems
    }

    generateHistory(items = []) {
        let newItems = [];
        if (items.length > 0) {
            items.map((i, k) => {
                newItems.push({
                    key: k,
                    actor: i?.actor || "",
                    time: i?.time ? moment(i?.time).format(DATE_TIME_FORMAT.EN_DATE_TIME) : "",
                    action: i?.action || [],
                })
            })
        }
        return newItems
    }

    exportList() {
        return {
            id: this.id,
            email: this.email,
            recurring_date: this.recurring_date,
            price: this.price,
            item: this.item,
            status: this.status,
        }
    }

    exportItem() {
        return {
            id: this.id,
            email: this.email,
            item: this.item,
            next_payment_date: moment(this.next_payment_date),
            status: this.status,
            history: this.history,
        }
    }
}

export class MasterSubscriptionCustomerRecurringResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.packages = data?.packages || {};
        this.customer = data?.customer || {};
        this.customer_dropdown = this.generateCustomerSelect(data?.customer || {});
        this.states = this.generateStates(data?.states || []);
        this.states_dropdown = this.generateStatesDropdown(data?.states || []);
    }

    generateCustomerSelect(items = {}) {
        let newItems = [];
        if (Object?.keys(items).length > 0) {
            Object?.keys(items).map(i => newItems.push({
                value: items[i],
                label: i,
            }))
        }
        return newItems
    }

    generatePackages(items = []) {
        let newItems = [];
        if (items.length > 0) {
            items.map(i => newItems.push({
                key: i?.key || "",
                value: i?.value || "",
                type: i?.type || "",
            }))
        }
        return newItems
    }

    generateStates(items = []) {
        let newItems = [];
        if (items.length > 0) {
            items.map(i => newItems.push({
                code: i?.code || "",
                state_name: i?.state_name || "",
                state: i?.state || [],
                city: i?.city || [],
                county: i?.county || [],
                metro: i?.metro || []
            }))
        }
        return newItems
    }

    generateStatesDropdown(items = []) {
        let newItems = [];
        if (items.length > 0) {
            items.map(i => newItems.push({
                value: i?.code || "",
                label: i?.state_name || "",
            }))
        }
        return newItems
    }

    exportMaster() {
        return {
            packages: this.packages,
            states: this.states,
            states_dropdown: this.states_dropdown,
            customer: this.customer,
            customer_dropdown: this.customer_dropdown,
        }
    }
}

export const listCols = ["email", "recurring_date", "price", "status"];
export const packagesCols = ["package", "price"];
export const historyCols = ["actor", "time", "action"];
export const constants = {
    status: ["Inactive", "Active"]
};
