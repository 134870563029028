import {useState, useEffect} from "react";
import {PAGINATION, DATE_TIME_FORMAT, IMAGE_DIRECTORY_TYPES} from '../constants/define';
import {omit, findIndex, find, merge, every} from 'lodash';
import {Input, Button} from 'antd';
import moment from 'moment';
import {Icon} from "../layouts/common";
import {checkUndefined} from "./functions";
// // import i18n from './../constants/initLng';
// import xssFilters from 'xss-filters';
// import * as Sentry from '@sentry/browser';
//
// const humanizeDurationFunction = require('./HumanizeDuration');
//
// export const getDummyData = (pageSize = 10, pageIndex = 1, getDataItem, totalCount = 100) => {
//     let data = [];
//     const max = pageSize * pageIndex;
//     const min = max - pageSize;
//     for (let i = min; i < max; i++) {
//         data.push(getDataItem(i))
//     }
//     return { data, pageIndex, pageSize, totalCount };
// }
//
// export const loadGoogleAPIKey = (key) => {
//     return new Promise(resolve => {
//         const existingScript = document.getElementById('googleAPI');
//         if (!existingScript) {
//             const script = document.createElement('script');
//             script.src = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places`;
//             script.id = 'googleAPI';
//             document.body.appendChild(script);
//             script.onload = () => {
//                 resolve();
//             };
//         } else {
//             resolve();
//         }
//     });
// };

export function parseListRequestParams(params = {}) {
    const page_index = params.page_index && Number(params.page_index) > PAGINATION.page_index ? params.page_index : undefined;
    const page_size = params.page_size && Number(params.page_size) >= PAGINATION.page_size ? params.page_size : undefined;
    const order_by = params.order_by || undefined;
    const filter = params.filter || params.filter || undefined;
    const order_by_desc = params.order_by_desc !== undefined ? params.order_by_desc : params.order_by_desc !== undefined ? params.order_by_desc : undefined;
    const start_date = params.start_date ? moment(params.start_date).toISOString() : undefined;
    const end_date = params.end_date ? moment(params.end_date).toISOString() : undefined;
    return {
        ...omit(params, ['page_index', 'page_size', 'order_by', 'order_by_desc', 'result', 'filter']),
        page_index, page_size, order_by, order_by_desc, start_date, end_date, filter
    }
}

// export function parseTourListRequestParams(params = {}) {
//     return parseListRequestParams(params)
// }
//
// export function parseTourLineListRequestParams(params = {}) {
//     const fromDate = params.fromDate && params.fromDate.toISOString ? params.fromDate.toISOString() : params.fromDate;
//     const toDate = params.toDate && params.toDate.toISOString ? params.toDate.toISOString() : params.toDate;
//     return { fromDate, toDate }
// }
//
// export function parseOrderVoucherItemDetailListRequestParams(params = {}) {
//     const PageIndex = params.pageIndex && params.pageIndex > PAGINATION.pageIndex ? params.pageIndex : undefined;
//     const PageSize = params.pageSize && params.pageIndex > PAGINATION.pageIndex ? params.pageSize : undefined;
//     const orderBy = params.OrderBy || params.orderBy || undefined;
//     const orderByDesc = params.OrderByDesc !== undefined ? params.OrderByDesc : params.orderByDesc !== undefined ? params.orderByDesc : undefined;
//     const groupId = params.productVariantId || params.groupId || undefined;
//     return {
//         ...omit(params, ['pageIndex', 'pageSize', 'OrderBy', 'OrderByDesc', 'productVariantId']),
//         PageIndex, PageSize, orderBy, orderByDesc,
//         groupId,
//     }
// }
//
// export function parseListRequestParamsCameo(params = {}) {
//     const orderBy = params.OrderBy || params.orderBy || undefined;
//     const orderByDesc = params.OrderByDesc !== undefined ? params.OrderByDesc : params.orderByDesc !== undefined ? params.orderByDesc : undefined;
//     const startDate = params.startDate ? moment(params.startDate).toISOString() : undefined;
//     const endDate = params.endDate ? moment(params.endDate).toISOString() : undefined;
//     return {
//         ...omit(params, ['OrderBy', 'OrderByDesc', 'totalCount', 'totalPage']),
//         startDate, endDate, orderBy, orderByDesc,
//         pageIndex: params.pageIndex && Number(params.pageIndex),
//         pageSize: params.pageSize && Number(params.pageSize),
//     }
// }
//
// export function parseReportListRequestParams(params = {}) {
//     return parseListRequestParamsCameo(params)
// }
//
// export function parseRatingListRequestParams(params = {}) {
//     return parseReportListRequestParams(params)
// }
//
//
//
// export function parseTourListReponseParams(data) {
//     return {
//         pageIndex: data.pageIndex,
//         pageSize: data.pageSize,
//         totalCount: data.totalCount,
//         orderBy: data.orderBy,
//         orderByDesc: data.orderByDesc,
//         searchTerm: data.searchTerm,
//         departure: data.departure,
//         destination: data.destination,
//         departureDate: data.departureDate,
//         fromDate: data.fromDate,
//         toDate: data.toDate,
//         priceFrom: data.priceFrom,
//         priceTo: data.priceTo,
//         lengthOfTour: data.lengthOfTour,
//     }
// }
//
// export function updateObjectInArray(object = {}, array = [], conpareField = 'id') {
//     let index = findIndex(array || [], item => object[conpareField] === item[conpareField]);
//     if (index >= 0) {
//         array[index] = object;
//     } else {
//         array.push(object);
//         index = array.length - 1;
//     }
//     return array;
// }
//
// export function getMomentDate(dateString, format = undefined, convertUTC = true) {
//     const utcDate = convertUTC ? moment.utc(dateString, format).toDate() : moment(dateString, format);
//     const date = dateString ? new moment(utcDate) : undefined;
//     return date && date.isAfter(`${DATE_TIME_FORMAT.MINIMUM_YEAR}`, 'year') ? date : undefined;
// }
//
// export function updateMomentLocale(locale = Config.DEFAULT_LANG) {
//     require(`moment/locale/${locale}.js`);
//     let options;
//     if (locale === 'vi') {
//         options = {
//             months: 'Tháng 1_Tháng 2_Tháng 3_Tháng 4_Tháng 5_Tháng 6_Tháng 7_Tháng 8_Tháng 9_Tháng 10_Tháng 11_Tháng 12'.split('_'),
//             weekdays: 'Chủ nhật_Thứ hai_Thứ ba_Thứ tư_Thứ năm_Thứ sáu_Thứ bảy'.split('_'),
//         }
//     }
//     moment.locale(locale, options);
// }
//
// export function humanizeDuration(duration, options = {}) {
//     // options.language = i18n.t('lang');
//     // options.delimiter = ' ';
//     // return humanizeDurationFunction(duration, options);
// }
//
// export function xssFilter(htmlString) {
//     const result = htmlString ? xssFilters.uriComponentInHTMLData(htmlString) : undefined;
//     return decodeURIComponent(result)
// }
//
// export function isLocalHost() {
//     return /localhost|127\.0\.0\.1|::1|\.local|^$/i.test(window.location.hostname)
// }
//
// export function initSentry(key, project) {
//     key = key || Config.SENTRY_KEY;
//     project = project || Config.SENTRY_PROJECT;
//     const isLocal = isLocalHost();
//     if (key && project && !isLocal) {
//         return Sentry.init({ dsn: `https://${key}@sentry.io/${project}` });
//     }
// }
//
// export function getLocaleFullName(firstname, lastname) {
//     // const language = i18n.t('lang');
//     // const getFullName = (first, last) => {
//     //     return `${first || ''}${last ? (' ' + last) : ''}`
//     // }
//     // switch (language) {
//     //     case 'vi':
//     //         return getFullName(lastname, firstname);
//     //     case 'en':
//     //         return getFullName(firstname, firstname);
//     //     default:
//     //         return getFullName(firstname, firstname);
//     // }
// }

// export function getImageTypeByDirectory(directory) {
//     if (IMAGE_DIRECTORY_TYPES.SLIDE.INCLUDE_DIRECTORIES.includes(directory)) {
//         return IMAGE_DIRECTORY_TYPES.SLIDE.id
//     }
//     return IMAGE_DIRECTORY_TYPES.THUMB.id
// }

export function parseFlatArrayToTreeData(data = [], parentIdField = 'parentId', childField = 'children', idField = 'id', currentId = undefined) {
    if ((data || []).length && every(data || [], item => !!item[childField])) {
        return data;
    }
    return (data || [])
        .filter(item => item[childField] === currentId)
        .map(item => {
            console.log("FLAT", item)
            const newItem = merge(item, {[childField]: parseFlatArrayToTreeData(data || [], parentIdField, childField, idField, item[idField])});
            if (newItem[childField] && !newItem[childField].length) newItem[childField] = undefined;
            return newItem;
        });
}

// export function getModelDefaultLanguage(model) {
//     return find(model.languages || [], item => !!item.isDefault);
// }
//
// export function readableFileSize(bytes, toFixed = 1, si = false) {
//     var thresh = si ? 1000 : 1024;
//     if (Math.abs(bytes) < thresh) {
//         return bytes + ' B';
//     }
//     var units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
//     var u = -1;
//     do {
//         bytes /= thresh;
//         ++u;
//     } while (Math.abs(bytes) >= thresh && u < units.length - 1);
//     return bytes.toFixed(toFixed) + ' ' + units[u];
// }
//
// export function getTranslateObject(translations = [], languageCode = undefined, getDefaultOnEmpty = false) {
//     return find(translations, item => (languageCode && item.languageCode === languageCode) || (getDefaultOnEmpty && item.isDefault))
// }

export function flatDatatoTreeData(data, childField = 'children', currentId = undefined) {
    if (currentId) {
        data.map(i => {
            i.title = i.name;
            i.value = i.id;
            i.key = i.id;
            if (i[childField] && i[childField].length > 0) {
                i[childField] = flatDatatoTreeData(i[childField], 'children', i.id)
            }
        });
        return data
    }
    data.map(i => {
        i.title = i.name;
        i.value = i.id;
        i.key = i.id;
        if (i[childField].length > 0) {
            i[childField] = flatDatatoTreeData(i[childField], 'children', i.id)
        }
    });
    return data
}

export function useKeyPress(targetKey) {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = useState(false);

    // If pressed key is our target key then set to true
    function downHandler({key}) {
        if (key === targetKey) {
            setKeyPressed(true);
        }
    }

    // If released key is our target key then set to false
    const upHandler = ({key}) => {
        if (key === targetKey) {
            setKeyPressed(false);
        }
    };
    // Add event listeners
    useEffect(() => {
        window.addEventListener("keydown", downHandler);
        window.addEventListener("keyup", upHandler);
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener("keydown", downHandler);
            window.removeEventListener("keyup", upHandler);
        };
    }, []); // Empty array ensures that effect is only run on mount and unmount
    return keyPressed;
}

export const ListSearchFreeTextComponent = props => {
    const handleSearch = () => {
        props.confirm();
    };

    const handleReset = () => {
        props.clearFilters();
    };

    return (
        <Input
            className="tableFilter"
            value={props.selectedKeys[0]}
            onChange={e => props.setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch()}
            suffix={
                <div className="row align-items-center">
                    {checkUndefined(props.selectedKeys[0]) &&
                    <Icon type="close-circle" className="ant-input-clear-icon mr-1" onClick={() => handleReset()}
                          size="small">
                    </Icon>}
                    <Button
                        className="mr-2"
                        type="primary"
                        onClick={() => handleSearch()}
                        icon={<Icon type="search"/>}
                        size="small"
                    >
                    </Button>
                </div>
            }
        />
    )
};
