import {createSelector} from 'reselect'

const stateReducer = state => state.subscriptionTransactionReducer;

export const selectFetching = (initData) =>
    createSelector(
        stateReducer,
        subscriptionTransactionReducer => subscriptionTransactionReducer.isFetching
    )

export const selectErrors = (initItems) =>
    createSelector(
        stateReducer,
        subscriptionTransactionReducer => subscriptionTransactionReducer.errors
    )

export const selectListSubscriptionTransaction = (initItems) =>
    createSelector(
        stateReducer,
        subscriptionTransactionReducer => subscriptionTransactionReducer.data
    )

export const selectMasterData = (initItems) =>
    createSelector(
        stateReducer,
        subscriptionTransactionReducer => subscriptionTransactionReducer.masterData
    )
export const selectSubscriptionTransactionItem = (initItems) =>
    createSelector(
        stateReducer,
        subscriptionTransactionReducer => subscriptionTransactionReducer.item
    )
