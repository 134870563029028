export const COMPANY_LIST_ACTION = "COMPANY_LIST_ACTION";
export const COMPANY_LIST_SUCCESS = "COMPANY_LIST_SUCCESS";
export const COMPANY_CREATE_ACTION = "COMPANY_CREATE_ACTION";
export const COMPANY_CREATE_SUCCESS = "COMPANY_CREATE_SUCCESS";
export const COMPANY_UPDATE_ACTION = "COMPANY_UPDATE_ACTION";
export const COMPANY_UPDATE_SUCCESS = "COMPANY_UPDATE_SUCCESS";
export const COMPANY_MASTER_DATA_ACTION = "COMPANY_MASTER_DATA_ACTION";
export const COMPANY_MASTER_DATA_SUCCESS = "COMPANY_MASTER_DATA_SUCCESS";
export const COMPANY_GET_TEM_ACTION = "COMPANY_GET_TEM_ACTION";
export const COMPANY_GET_TEM_SUCCESS = "COMPANY_GET_TEM_SUCCESS";

export const COMPANY_INIT = "COMPANY_INIT";
export const COMPANY_FAILED = "COMPANY_FAILED";
