import React, {useState, useEffect} from "react";
import {useSelector} from 'react-redux'

// ============== LAYOUT ==============
import {Form, Input, Button, Select, Spin, Checkbox} from 'antd';
import HeaderAction from "../../../common/layout/HeaderAction";

// ============== ACTION ==============
import {
    ActionMasterData,
    ActionCreateCompany,
    ActionInit,
    ActionGetItem,
    ActionUpdateCompany
} from "../../../../data/hooks/company";

// ============== SELECT DATA ==============
import {selectFetching, selectCompanyItem, selectMasterData} from "../../../../data/reselects/companySelector";
import {common} from "../../../../data/mapping/Request/CompanyRequest";
import {Icon} from "../../../common";
import PERMISSION from "../../../../constants/permission";
import TAG_DEFINE from "../../../../constants/common";

const Index = (props) => {
    const [form] = Form.useForm(),
        [isFetching, setIsFetching] = useState(false),
        [typePage, setTypePage] = useState(TAG_DEFINE.ACTION.create),
        [masterStatus, setMasterStatus] = useState({}),
        [isUpdate, setIsUpdate] = useState(false),
        // ============== ACTION ==============
        getMasterData = ActionMasterData(),
        actionCreateCompany = ActionCreateCompany(),
        getUpdateCompany = ActionUpdateCompany(),
        getInitAction = ActionInit(),
        getItem = ActionGetItem(),
        // ============== SELECT DATA ==============
        itemFetching = useSelector(selectFetching()),
        itemDetail = useSelector(selectCompanyItem()),
        itemMasterData = useSelector(selectMasterData());

    useEffect(() => {
        const {match} = props;
        if (Object.keys(match.params).length > 0) {
            setIsUpdate(true);
            if (Object.keys(itemDetail).length < 1) {
                setTypePage(TAG_DEFINE.ACTION.update)
                getItem({id: match.params?.id, props: props});
            }
        } else {
            setIsUpdate(false)
        }
    }, [props.match]);

    useEffect(() => {
        if (Object.keys(itemMasterData).length < 1) {
            getMasterData()
        }

        return () => {
            getInitAction();
        }
    }, []);

    useEffect(() => {
        setIsFetching(itemFetching)
    }, [itemFetching]);

    useEffect(() => {
        if (Object.keys(itemDetail).length > 0) {
            form.setFieldsValue(itemDetail)
        }
    }, [itemDetail]);

    useEffect(() => {
        if (Object.keys(itemMasterData?.status || {}).length > 0) {
            setMasterStatus(itemMasterData?.status)
        }
    }, [itemMasterData]);

    const onFinish = (val) => {
        if (isUpdate) {
            getUpdateCompany({...val, props, id: itemDetail?.id})
        } else {
            actionCreateCompany({...val, props})
        }
    }

    return (
        <>
            <HeaderAction title={`Company ${TAG_DEFINE.ACTION[typePage.toLowerCase()]}`}/>
            <Spin tip="Loading..." spinning={isFetching}>
                <div className="container bg-white mt-4 p-4">
                    <div className="row">
                        <div className="col-12">
                            <Form
                                form={form}
                                layout="vertical"
                                onFinish={onFinish}
                                // onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <div className="row">
                                    <div className="col-4">
                                        <Form.Item
                                            label="Name"
                                            name={common.ACTION_FIELD.name}
                                            rules={[{required: true}]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Owner"
                                            name={common.ACTION_FIELD.owner}
                                            rules={[{required: true}]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Address"
                                            name={common.ACTION_FIELD.address}
                                            rules={[{required: true}]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-4">
                                        <Form.Item
                                            label="Phone number"
                                            name={common.ACTION_FIELD.phone_number}
                                            rules={[{required: true}]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </div>
                                    {
                                        isUpdate && (
                                            <>
                                                <div className="col-4">
                                                    <Form.Item
                                                        label="Status"
                                                        name={common.ACTION_FIELD.status}
                                                        rules={[{required: true, message: 'Please select your Role!'}]}
                                                    >
                                                        <Select
                                                            className="w-100"
                                                            style={{width: 120}}>
                                                            {
                                                                (Object.keys(masterStatus)).length > 0 && (Object.keys(masterStatus)).map((i, k) => {
                                                                    return (
                                                                        <Select.Option value={i}
                                                                                       key={k}>{masterStatus[i]}</Select.Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-6">
                                                    <Form.Item
                                                        label="Staffs"
                                                        name={common.ACTION_FIELD.staffs}
                                                        // rules={[{required: true}]}
                                                    >
                                                        <Input.TextArea placeholder="Insert list staff email, separated by comma ex: abc@test.com, def@test.com, ghi@test.com"/>
                                                    </Form.Item>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                                <Form.Item className="text-end">
                                    <Button type="default" htmlType="button" className="me-3"
                                            onClick={() => props.history.push(PERMISSION.COMPANY.VIEW.route, {group: PERMISSION.COMPANY.VIEW.group})}>
                                        Back
                                    </Button>
                                    <Button type="primary" htmlType="submit">
                                        Save {isFetching && <Icon type="sync-outlined" spin/>}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            </Spin>
        </>
    )
}

export default Index
