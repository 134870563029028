export const CONFIGURATION_CMA_ACTION = "CONFIGURATION_CMA_ACTION";
export const CONFIGURATION_CMA_SUCCESS = "CONFIGURATION_CMA_SUCCESS";
export const CONFIGURATION_UPDATE_CMA_ACTION = "CONFIGURATION_UPDATE_CMA_ACTION";
export const CONFIGURATION_UPDATE_CMA_SUCCESS = "CONFIGURATION_UPDATE_CMA_SUCCESS";
export const CONFIGURATION_UPDATE_ACTION = "CONFIGURATION_UPDATE_ACTION";
export const CONFIGURATION_UPDATE_SUCCESS = "CONFIGURATION_UPDATE_SUCCESS";
export const CONFIGURATION_MASTER_DATA_ACTION = "CONFIGURATION_MASTER_DATA_ACTION";
export const CONFIGURATION_MASTER_DATA_SUCCESS = "CONFIGURATION_MASTER_DATA_SUCCESS";
export const CONFIGURATION_GET_TEM_ACTION = "CONFIGURATION_GET_TEM_ACTION";
export const CONFIGURATION_GET_TEM_SUCCESS = "CONFIGURATION_GET_TEM_SUCCESS";

export const CONFIGURATION_INIT = "CONFIGURATION_INIT";
export const CONFIGURATION_FAILED = "CONFIGURATION_FAILED";
