import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";

export default class SubscriptionCustomPackageResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.state = data?.state || "";
        this.city = data?.city || "";
        this.county = data?.county || "";
        this.metro = data?.metro || "";
        this.type = data?.type || "";
        this.period_type = data?.period_type || "";
        this.package_type = data?.package_type || "";
        this.price = data?.price || 0;
        this.status = data?.status || 0;
    }

    exportList() {
        return {
            id: this.id,
            state: this.state,
            city: this.city,
            county: this.county,
            metro: this.metro,
            type: this.type,
            period_type: this.period_type,
            package_type: this.package_type,
            price: this.price,
            status: this.status,
        }
    }
}

export class MasterCustomPackageResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.state = data?.state || [];
        this.status = data?.status || {};
        this.period_type = data?.period_type || {};
        this.package_type = data?.package_type || {};
        this.data = data?.data || {};
    }

    exportListMaster() {
        return {
            state: this.state,
            status: this.status,
            period_type: this.period_type,
            package_type: this.package_type,
            data: this.data,
        }
    }
}

export const listCols = ["state", "city", "county", "metro", "type", "period_type", "package_type", "price", "status"];
export const constants = {
    status: ["Inactive", "Active"]
};
