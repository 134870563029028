import moment from "moment";
import {DATE_TIME_FORMAT} from "../../../constants/define";

export default class ExclusiveContentResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.thumbnail = data?.thumbnail || "";
        this.uploadFile = [[
            {
                uid: '-1',
                name: 'image.png',
                status: 'done',
                url: data?.uploadFile || "",
            },
        ]];
        this.video_url = data?.video_url || "";
        this.title = data?.title || "";
        this.description = data?.description || "";
        this.footer_icon = data?.footer_icon || false;
        this.footer_hot = data?.footer_hot || false;
        this.footer_description = data?.footer_description || "";
        this.status = data?.status || 0;
        this.status_explain = data?.status_explain || "";
        this.created_at = data?.created_at || "";
        this.updated_at = data?.updated_at || "";
    }

    exportList() {
        return {
            id: this.id,
            thumbnail: this.thumbnail,
            video_url: this.video_url,
            title: this.title,
            description: this.description,
            footer_icon: this.footer_icon,
            footer_hot: this.footer_hot,
            footer_description: this.footer_description,
            status: this.status,
        }
    }

    exportItem() {
        return {
            id: this.id,
            thumbnail: this.thumbnail,
            uploadFile: this.uploadFile,
            video_url: this.video_url,
            title: this.title,
            description: this.description,
            footer_icon: this.footer_icon,
            footer_hot: this.footer_hot,
            footer_description: this.footer_description,
            status: this.status,
            status_explain: this.status_explain,
            created_at: this.created_at,
            updated_at: this.updated_at,
        }
    }
}

export class ExclusiveContentLayoutResponse {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.layout = this.generateItems(data)
    }

    generateItems(items = []) {
        let newItem = {},
            resultList = [],
            positionTitle = [],
            resultGroup = [];
        // if (items.length > 0) {
        //     items.map(i => newItem.push({
        //         title: i?.title || "",
        //         items: i?.items || [],
        //         groups: i?.groups ? this.generateItems(i?.groups || []) : []
        //     }))
        // }

        if (items.length > 0) {
            items.map((i, k) => {
                let nameGroups = `item_${k}__groups__g0`;
                newItem = {
                    ...newItem,
                    [`item_${k}`]: i.title,
                    [`item_${k}__items`]: i.items,
                    [nameGroups]: i.groups || []
                }
                // if ((i.groups || []).length > 0) {
                //     (i.groups || []).map((item, key) => {
                //
                //     })
                // }
                resultGroup.push(nameGroups)
                resultList.push(`item_${k}`)
                positionTitle.push(k)
            })
        }
        return {
            items: newItem,
            resultList,
            resultGroup,
            positionTitle
        }
    }

    exportList() {
        return {
            layout: this.layout
        }
    }
}

export const postListCols = ["thumbnail", "video_url", "title", "description", "footer_icon", "footer_hot", "footer_description", "status"];
export const CONSTANT = {
    STATUS: ["INACTIVE", "ACTIVE"]
}
